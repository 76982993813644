import React from 'react';
import ControlWithHint from '@eg/elements/ControlWithHint';
import Slider from '@eg/elements/Slider';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import { NumericRange } from 'stg-common';
import formatter, { EURO_WITHOUT_CENT, getFormattedValueWithoutEuroSign } from '../../helpers/currencyFormatter';
import { emptyFunction } from '../../helpers/emptyFunction';
import { filterNumberInputField, getErrorMessage } from '../../helpers/validationHelpers';
import CurrencySliderInput from './CurrencySliderInput';

interface InsuranceFeeSliderProps {
    // tslint:disable-next-line:no-any
    form: FormikProps<any>;
    valueRange: NumericRange;
    // tslint:disable-next-line:no-any
    updateState?: any;
}

export const InsuranceFeeSlider = (props: InsuranceFeeSliderProps): JSX.Element => {
    const update = props.updateState || emptyFunction;

    if (!props.form.touched.insuranceFee) {
        props.form.setFieldTouched('insuranceFee');
    }

    return (<Field
        name="insuranceFee"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue} = props.form;
            return (<div className="esc_grid">
                <div className="esc_grid__wrapper" style={{justifyContent: 'center'}}>
                    <div className="esc_col esc_col-12 esc_col-s-8">
                        <Slider
                            id={field.name}
                            data-component-id="insurance-fee-slider"
                            min={props.valueRange.min}
                            max={props.valueRange.max}
                            step={props.valueRange.increment}
                            value={field.value}
                            subTitle={`${formatter(EURO_WITHOUT_CENT).format(props.valueRange.min)} bis
                                         ${formatter(EURO_WITHOUT_CENT).format(props.valueRange.max)}`}
                            onChange={value => {
                                setFieldValue(field.name, value);
                                update({insuranceFee: Number(value)});
                            }}
                        />
                    </div>
                    <div className="esc_col esc_col-s-3 esc_col-8">
                        <CurrencySliderInput
                            data-component-id="insurance-fee-input"
                            aria-hidden={true}
                            id={field.name}
                            name={field.name}
                            alignTextRight={true}
                            min={props.valueRange.min}
                            max={props.valueRange.max}
                            step={props.valueRange.increment}
                            value={getFormattedValueWithoutEuroSign(field.value)}
                            onKeyDown={filterNumberInputField}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let insuranceFee;
                                if (event.target.value) {
                                    insuranceFee = Number(event.target.value.replace('.', ''));
                                    insuranceFee = isNaN(insuranceFee) ? props.valueRange.min : insuranceFee;
                                }
                                setFieldValue(field.name, insuranceFee);
                                update({insuranceFee});
                            }}
                        />
                    </div>
                    <div className="esc_col esc_col-s-1 esc_col-4"
                         style={{
                             lineHeight: '49px',
                             display: 'block',
                             textAlign: 'left',
                             fontSize: '1.7em',
                             fontWeight: 'bold'
                         }}>
                        €
                    </div>
                    <ControlWithHint error={getErrorMessage(props.form, {name: 'insuranceFee'})}><></>
                    </ControlWithHint>
                </div>
            </div>);
        }}/>);
};
