import React from 'react';
import { MessageBox } from '@eg/elements/MessageBox';
import { Addresses } from 'stg-common';
import { Headline } from '../../components/Headline';
import { CONFIG_BACKEND_BASE_URL } from '../../config';
import { scrollToTop } from '../../helpers/scrolling';
import { Storage } from '../../helpers/Storage';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';

export interface RequestOfferFeedbackPageData extends StoreStateUpdater<RequestOfferFeedbackPageData> {
    anrede: Addresses | undefined;
    vorname: string;
    nachname: string;
    shouldDownloadPdf: boolean;
}

interface RequestOfferFeedbackPageProps extends PagePropsWithValues<RequestOfferFeedbackPageData> {
    businessId: string;
}

const isChrome = (): boolean => {
    // @ts-ignore
    return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
};

const getSalutation = (vorname: string, nachname: string, anrede?: Addresses) => {
    switch (anrede) {
        case Addresses.MRS:
            return `Sehr geehrte Frau ${vorname} ${nachname},`;
        case Addresses.MR:
            return `Sehr geehrter Herr ${vorname} ${nachname},`;
        default:
            return `Sehr geehrte/geehrter ${vorname} ${nachname},`;
    }
};

function RequestOfferFeedbackPage(props: RequestOfferFeedbackPageProps) {
    const downloadLinkAnchor: React.RefObject<HTMLAnchorElement> = React.createRef();
    React.useEffect(() => {
        scrollToTop();
        Storage.clear();
        clickDownloadLink(downloadLinkAnchor);
    }, [downloadLinkAnchor]);

    const input = props.storeState;
    return (<div className="feedback-page" data-component-id="FeedbackPage">
            <Headline>Feedback</Headline>
            {props.storeState.shouldDownloadPdf && <MessageBox>
                Sie interessieren sich für eine unserer Versicherungen – das freut uns. Jetzt können auch Sie sich
                freuen:
                über Ihr Angebot für eine richtig gute Absicherung. Bitte schicken Sie uns die Seiten zwei bis vier
                unterschrieben und vollständig ausgefüllt zurück,
                um schnellstmöglich Ihren Versicherungsschutz genießen zu können. <br/><br/>
                Sie können Ihr Angebot <a ref={downloadLinkAnchor}
                                          href={`${CONFIG_BACKEND_BASE_URL}/documents/${props.businessId}/vollangebot`}
                                          data-component-id="offer-download-link"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="esc_text-link"
                                          download={isChrome()}>hier herunterladen</a>
            </MessageBox>}
            {!props.storeState.shouldDownloadPdf && <MessageBox>
                <b>{getSalutation(input.vorname, input.nachname, input.anrede)}</b>
                <br/><br/>
                Sie interessieren sich für eine unserer Versicherungen – das freut uns. Jetzt können auch Sie sich
                freuen: über Ihr Angebot für eine richtig gute
                Absicherung. Darin erfahren Sie auch, wie es weitergeht.<br/>
                Haben Sie ein Angebot per Post angefordert? Dann kann es einige Tage dauern, bis es in Ihrem
                Briefkasten liegt.
            </MessageBox>}
        </div>
    );
}

const clickDownloadLink = (downloadLinkAnchor: React.RefObject<HTMLAnchorElement>) => {
    if (downloadLinkAnchor.current) {
        downloadLinkAnchor.current.click();
    }
};

export default RequestOfferFeedbackPage;
