import { IbanCountryCodes } from './types';

export const toReadableWord = (word?: string): string => {
    return word ? word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() : '';
};

export const sanitizeWhitespaces = (iban?: string): string => {
    if (iban) {
        return iban.replace(/(\W)/g, '');
    } else {
        return '';
    }
};

export const ibanFormatter = (iban?: string): string => {
    if (iban) {
        return iban.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    } else {
        return '';
    }
};

export const getCountrySpecificIbanLength = (iban?: string): number => {
    const artificialIbanLengthOnError = 50;
    // tslint:disable-next-line:no-magic-numbers
    if (iban && iban.length >= 2) {
        // tslint:disable-next-line:no-magic-numbers
        const extractedIbanCountryCode = iban.toUpperCase().substring(0, 2);
        return IbanCountryCodes[extractedIbanCountryCode] ? IbanCountryCodes[extractedIbanCountryCode] : artificialIbanLengthOnError;
    } else {
        return artificialIbanLengthOnError;
    }
};
