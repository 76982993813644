import { IconProps } from './IconTypes';

export const WaldbestattungIcon = (props: IconProps): JSX.Element => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
          d={`M9.47,16.2533299 C9.07556068,16.3557642 8.66804099,16.4274633 8.25,16.465625 L8.25,22 L6.875,22 
                                        L6.875,16.465625 C2.9820979,16.1102536 0.00158184205,12.8465885 2.48689958e-14,8.9375 
                                        L2.48689958e-14,7.5625 C2.48689958e-14,3.38584658 3.38584658,0 7.5625,0 C11.7391534,0 
                                        15.125,3.38584658 15.125,7.5625 L15.125,8.51337298 C17.6732375,8.6880453 19.7529751,10.5542971 
                                        19.9773247,12.9601487 C19.990505,13.083153 19.9971399,13.2072163 19.9971399,13.3319518 L19.9971399,21.25 
                                        C19.9971399,21.6642136 19.6613534,22 19.2471399,22 L10.22,22 C9.80578644,22 9.47,21.6642136 9.47,21.25 
                                        L9.47,16.2533299 Z M13.75,8.58546223 L13.75,7.5625 C13.75,4.14523811 10.9797619,1.375 7.5625,1.375 
                                        C4.14523811,1.375 1.375,4.14523811 1.375,7.5625 L1.375,8.9375 C1.37649525,12.0875977 3.74440733,14.7337395 
                                        6.875,15.08375 L6.875,13.344375 L4.81528296,11.2790912 C4.54785334,11.0109388 4.54814624,10.5768538 
                                        4.8159375,10.3090625 C5.08362301,10.041377 5.51762699,10.041377 5.7853125,10.3090625 L6.875,11.39875 
                                        L6.875,7.156875 L5.50090143,5.7878469 C5.50060068,5.78754726 5.5003002,5.78724734 5.5,5.78694714 
                                        C5.23091385,5.51786099 5.23091385,5.08158615 5.5,4.8125 C5.50035371,4.81214629 5.50070781,4.81179296 
                                        5.50106229,4.81144002 C5.77064501,4.5430294 6.20677477,4.54397958 6.47518539,4.81356229 L7.5625,5.905625 
                                        L8.02997733,5.42790262 C8.03114031,5.42671414 8.03230763,5.42552992 8.03347927,5.42434998 C8.29921643,5.15672816 
                                        8.73158932,5.15520034 8.99921115,5.4209375 C9.26895744,5.68878417 9.27094508,6.12444938 9.00365397,6.39474619 
                                        L8.25,7.156875 L8.25,9.343125 L9.3465625,8.2465625 C9.61424801,7.97887699 10.048252,7.97887699 10.3159375,8.2465625 
                                        C10.583623,8.51424801 10.583623,8.94825199 10.3159375,9.2159375 L8.25,11.281875 L8.25,15.08375 C8.67182109,15.036589 
                                        9.07979548,14.9477411 9.47,14.8215903 L9.47,13.3319518 C9.47,13.2050745 9.47686468,13.0788927 9.48922774,12.9664949 
                                        C9.69311255,10.7469564 11.4759371,8.98545597 13.75,8.58546223 Z M18.4971399,13.3319518 C18.4971399,13.2594116 
                                        18.4932277,13.1875007 18.4854745,13.1163871 L18.4842009,13.1037058 C18.3249108,11.3696361 16.7073433,10 14.7335699,10 
                                        C12.7597966,10 11.142229,11.3696361 10.9816653,13.1163871 C10.9739122,13.1875007 10.97,13.2594116 10.97,13.3319518 
                                        L10.97,20.5 L18.4971399,20.5 L18.4971399,13.3319518 Z`}
          transform="translate(2 1)"/>
  </svg>);
};
