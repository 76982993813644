import React from 'react';
import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { ArrowDownIcon, ArrowUpIcon, EditIcon } from '@eg/elements/components/Icons';
import MessageBox from '@eg/elements/MessageBox';
import Price from '@eg/elements/Price';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { Beitragsvariante, BurialPackage, BurialPackageIdentifier, ValueRanges, Variante } from 'stg-common';
import { PAYMENT_METHOD_SINGLE_FEE } from '../constants';
import formatter, { EURO_WITH_CENT } from '../helpers/currencyFormatter';
import { mapPaymentMethodToText } from '../helpers/paymentHelper';
import { extractSelectedVariant, mapVariant } from '../helpers/variants';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked, trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import './Basket.css';
import { VariantIcon } from './icons';

export interface BasketStoreItems {
    paymentMethod: string;
    variants: Variante[];
    burialPackage?: BurialPackageIdentifier;
    flexOption?: boolean;
    feeDynamic?: boolean;
    insuranceSum: number;
    paymentPeriod: number;
}

export interface BasketProps {
    basketStore: BasketStoreItems;
    valueRanges: ValueRanges;
    handleAction: HandleActionCallback;
    hideFlexOptionEdit?: boolean;
    hideBurialOptionEdit?: boolean;
    hideFeeDynamicEdit?: boolean;
    alwaysExpanded?: boolean;
    hideBurialPackage?: boolean;
    hideFlexOption?: boolean;
    hideFeeDynamic?: boolean;
}

const SHIELD_ICON_WIDTH = 48;
const ICON_WIDTH_EDIT = 16;

const Basket = (props: BasketProps): JSX.Element => {
    const [isDetailed, setDetailed] = React.useState(props.alwaysExpanded);

    const variant = extractSelectedVariant(props.basketStore.variants);
    if (!variant) {
        return <MessageBox type="error" data-component-id="basket-error">Es wurde keine Variante ausgewählt.</MessageBox>;
    }

    const burialPackage = props.valueRanges.burialPackages.find(
        el => el.identifier === props.basketStore.burialPackage
    );

    const paymentMethodText = mapPaymentMethodToText(props.basketStore.paymentMethod);
    const selectedVariant = mapVariant(variant, props.valueRanges);
    const priceForPaymentPeriod = getPriceForPaymentPeriod(props, variant, props.basketStore.flexOption);
    const showFlexOptionEdit = !props.hideFlexOptionEdit;
    const showFeeDynamicEdit = !props.hideFeeDynamicEdit;
    const isVariantPremium = variant.variante === Beitragsvariante.PREMIUM;
    const showBurialPackageEdit = !props.hideBurialOptionEdit && isVariantPremium;
    const isNotSinglePaymentMethod = props.basketStore.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE;
    const countChosenOptions = getCountChosenOptions(props, isVariantPremium, isNotSinglePaymentMethod, burialPackage);
    return (<>
        {variant && <>
            <div id="basket">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-12 esc_col-m-1 removeBorderBottom" style={{textAlign: 'center'}}>
                            <VariantIcon variant={variant.variante} width={SHIELD_ICON_WIDTH} height={SHIELD_ICON_WIDTH}/>
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-6 basket-headline-variant">
                            <div style={{fontSize: '1.25em', fontWeight: 'bolder'}} data-component-id="basket-variant">{selectedVariant}{' '}
                                <Button variant="text-link" data-component-id="basket-variant-edit-link"
                                        onClick={() => {
                                            trackElementClicked(TrackingElement.Icon_TarifvarianteAendern);
                                            props.handleAction(NavigationAction.DIRECT_JUMP_FEE_PAGE);
                                        }}
                                        style={{color: 'rgb(142, 0, 56)'}}>
                                    <EditIcon height={ICON_WIDTH_EDIT} width={ICON_WIDTH_EDIT}/>
                                </Button>
                            </div>
                            {countChosenOptions > 0 && <div data-component-id="basket-variant-additions">
                                inklusive {countChosenOptions} Zusatzoption{countChosenOptions > 1 && 'en'}
                            </div>}
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-5 basket-headline-price">
                            <Price data-component-id="basket-netto-price-with-payment"
                                   value={priceForPaymentPeriod}
                                   suffix={paymentMethodText}
                                   style={{fontSize: '1.25em', fontFamily: '"FS Me",Arial,Helvetica,sans-serif'}}/>
                        </div>
                    </div>
                </div>
                <br/>
                {isDetailed && <div data-component-id="basket-details">
                    {!props.hideBurialPackage && isVariantPremium && burialPackage && <>
                        <div className="esc_grid additionalOption" data-component-id="basket-burial-package-line">
                            <div className="esc_grid__wrapper">
                                <div className="esc_col esc_col-12 esc_col-m-1" style={{border: 'none'}}></div>
                                <div className="esc_col esc_col-11 esc_col-m-10 additionalOption-label">
                                    Bestattungspaket: <strong>{burialPackage.label}</strong>
                                </div>
                                <div className="esc_col esc_col-1 additionalOption-action">
                                    {showBurialPackageEdit && <Button variant="text-link"
                                                                      data-component-id="basket-burial-package-line-edit-link"
                                                                      onClick={() => {
                                                                          trackElementClicked(TrackingElement.Icon_BestattungAendern);
                                                                          props.handleAction(NavigationAction.DIRECT_JUMP_BURIAL_PACKAGE);
                                                                      }}
                                                                      style={{color: 'rgb(142, 0, 56)'}}>
                                        <EditIcon height={ICON_WIDTH_EDIT} width={ICON_WIDTH_EDIT}/>
                                    </Button>}
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>}
                    {!props.hideFlexOption && isNotSinglePaymentMethod && <>
                        <div className="esc_grid additionalOption" data-component-id="basket-flexoption-line">
                            <div className="esc_grid__wrapper">
                                <div className="esc_col esc_col-12 esc_col-m-1" style={{border: 'none'}}></div>
                                <div className="esc_col esc_col-11 esc_col-m-10 additionalOption-label">
                                    Flex Option: <strong>{!!props.basketStore.flexOption ? ' Gewählt' : 'Nicht gewünscht'}</strong>
                                </div>
                                <div className="esc_col esc_col-1 additionalOption-action">
                                    {showFlexOptionEdit && <Button variant="text-link" data-component-id="basket-flexoption-line-edit-link"
                                                                   onClick={() => {
                                                                       trackElementClicked(TrackingElement.Icon_FlexOptionAendern);
                                                                       props.handleAction(NavigationAction.DIRECT_JUMP_FLEX_OPTION);
                                                                   }}
                                                                   style={{color: 'rgb(142, 0, 56)'}}>
                                        <EditIcon height={ICON_WIDTH_EDIT} width={ICON_WIDTH_EDIT}/>
                                    </Button>}
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>}
                    {!props.hideFeeDynamic && isNotSinglePaymentMethod && <>
                        <div className="esc_grid additionalOption" data-component-id="basket-feedynamic-line">
                            <div className="esc_grid__wrapper">
                                <div className="esc_col esc_col-12 esc_col-m-1" style={{border: 'none'}}></div>
                                <div className="esc_col esc_col-11 esc_col-m-10 additionalOption-label">
                                    Beitragsdynamik: <strong>{!!props.basketStore.feeDynamic ? ' Gewählt' : 'Nicht gewünscht'}</strong>
                                </div>
                                <div className="esc_col esc_col-1 additionalOption-action">
                                    {showFeeDynamicEdit && <Button variant="text-link" data-component-id="basket-feedynamic-line-edit-link"
                                                                   onClick={() => {
                                                                       trackElementClicked(TrackingElement.Icon_DynamikAendern);
                                                                       props.handleAction(NavigationAction.DIRECT_JUMP_DYNAMIC_OPTION);
                                                                   }}
                                                                   style={{color: 'rgb(142, 0, 56)'}}>
                                        <EditIcon height={ICON_WIDTH_EDIT} width={ICON_WIDTH_EDIT}/>
                                    </Button>}
                                </div>
                            </div>
                        </div>
                        <br/>
                    </>}
                    {<div className="esc_grid">
                        <div className="esc_grid__wrapper">
                            <div className="esc_col esc_col-12 esc_col-m-1"></div>
                            <div className="esc_col esc_col-12 esc_col-m-11">
                                <Card padding="normal" className="insurance-calculation">
                                    <div className="esc_grid">
                                        <div className="esc_grid__wrapper">
                                            {variant.versicherungssumme &&
                                            <div className="esc_col esc_col-12 esc_col-m-6" style={{borderWidth: '6px'}}>
                                                <strong data-component-id="basket-insurance-sum">
                                                    {formatter(EURO_WITH_CENT).format(variant.versicherungssumme)}
                                                </strong> Versicherungssumme
                                            </div>}
                                            {variant.variablerGewinnanteil &&
                                            <div className="esc_col esc_col-12 esc_col-m-6" style={{borderWidth: '6px'}}>
                                                <small>
                                                    <span style={{float: 'left'}}>
                                                        <strong data-component-id="basket-variable-bonus-earning">
                                                            + {formatter(EURO_WITH_CENT).format(variant.variablerGewinnanteil)}
                                                        </strong> Überschussbeteiligung
                                                    </span>
                                                    <span style={{float: 'left', marginLeft: '5px', marginTop: '-12px'}}>
                                                        <TooltipIcon style={{float: 'left'}}>Die Höhe der Überschussbeteiligung kann
                                                            nicht für die gesamte Vertragsdauer garantiert werden. Sie passt sich dem Zinsniveau an und wurde
                                                            beispielhaft für eine Auszahlung im Alter von 85 Jahren kalkuliert.
                                                            Konkrete Ansprüche lassen sich daraus nicht ableiten.</TooltipIcon>
                                                    </span>
                                                    <div style={{marginTop: '-12px', clear: 'both', float: 'left'}}>(nicht garantiert)</div>
                                                </small>
                                            </div>}
                                            {isNotSinglePaymentMethod && props.basketStore.paymentPeriod &&
                                            <div className="esc_col esc_col-12  esc_col-m-12" style={{borderWidth: '6px'}}>
                                                <strong data-component-id="basket-payment-period">
                                                    {props.basketStore.paymentPeriod} Jahre
                                                </strong> Beitragszahlungsdauer
                                            </div>}
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>}
                </div>}
                {!props.alwaysExpanded && <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-12 esc_col-m-1" style={{border: 'none'}}></div>
                        <div className="esc_col esc_col-12 esc_col-m-11" style={{textAlign: 'center'}}>
                            <Button width="auto"
                                    iconLeft={isDetailed ? ArrowUpIcon : ArrowDownIcon}
                                    variant="text-link"
                                    size="large"
                                    style={{fontWeight: 'bold'}}
                                    onClick={() => {
                                        trackElementClickImmediate(isDetailed ? TrackingElement.Link_WenigerDetails : TrackingElement.Link_MehrDetails);
                                        setDetailed(!isDetailed);
                                    }}
                                    data-component-id="basket-show-details">
                                {isDetailed ? 'Weniger' : 'Mehr'} Details zeigen
                            </Button>
                        </div>
                    </div>
                </div>}
            </div>
        </>
        }
    </>);
};

const getPriceForPaymentPeriod = (props: BasketProps, variant: Variante, flexOption?: boolean) => {
    if (!props.hideFlexOption && flexOption) {
        return variant.nettobeitragMitFlexOption ? variant.nettobeitragMitFlexOption : 0;
    }
    return variant.nettobeitragNachZahlweise ? variant.nettobeitragNachZahlweise : 0;
};

const getCountChosenOptions = (props: BasketProps, isVariantPremium: boolean, isNotSinglePaymentMethod: boolean,
                               burialPackage?: BurialPackage) => {
    let countChosenOptions = 0;
    if (isVariantPremium && !props.hideBurialPackage && burialPackage && burialPackage.identifier !== BurialPackageIdentifier.KEIN) {
        countChosenOptions++;
    }
    if (isNotSinglePaymentMethod && !props.hideFlexOption && !!props.basketStore.flexOption) {
        countChosenOptions++;
    }
    if (isNotSinglePaymentMethod && !props.hideFeeDynamic && !!props.basketStore.feeDynamic) {
        countChosenOptions++;
    }
    return countChosenOptions;
};

export default Basket;
