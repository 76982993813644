import { IconProps } from './IconTypes';

export const KlassischesBestattungspaketIcon = (props: IconProps): JSX.Element => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                x="0px" y="0px" viewBox="0 0 167 69" {...props}>
        <path d={`M161.4,40.3c3.4,2.8,5.1,7.5,5.1,13.8c0,3.5-0.3,6.4-0.8,8.8c-0.8,3.6-4,6.1-7.7,6.1h-18.2
	c-0.9,0-1.8-0.2-2.6-0.4c1.1-1.2,2-2.5,2.8-4l18,0c1.6,0,2.9-1.1,3.3-2.6c0.4-1.9,0.7-4.5,0.7-7.8c0-5.1-1.2-8.5-3.4-10.3
	c-0.1-0.1-0.3-0.2-0.5-0.1l-5.4,1.6c-3.4,1-6.9,0.8-10.2-0.4l-2.8-1c-0.8-1.4-1.8-2.7-2.9-3.8c1.3-0.8,2.9-1,4.4-0.4l2.9,1.1
	c2.4,0.9,4.9,1,7.4,0.3l5.4-1.6C158.4,38.9,160.1,39.2,161.4,40.3z M148.8,14c6.8,0,12.2,5.4,12.2,12.1c0,6.6-5.3,12-11.9,12.1
	l-0.3,0c-6.8,0-12.3-5.4-12.2-12.1C136.6,19.4,142.1,14,148.8,14z M155,21.1c-0.8,0.8-1.8,1.4-3.1,1.8c-1.8,0.6-3.2,0.7-5.7,0.7
	c-1,0-1.5,0-2,0.1c-1.1,0.1-2.1,0.3-3.1,0.8c-0.1,0.6-0.2,1.1-0.2,1.6c0,4.4,3.6,7.9,8,7.9c4.4,0,8-3.6,8-7.9
	C156.8,24.2,156.1,22.5,155,21.1z M151.9,25.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
	C150.3,26,151,25.3,151.9,25.3z M146,25.3c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
	C144.4,26,145.1,25.3,146,25.3z`}/>
        <path d={`M133.3,40.3c3.4,2.8,5.1,7.5,5.1,13.8c0,3.5-0.3,6.4-0.8,8.8c-0.8,3.6-4,6.1-7.7,6.1
	h-18.2c-3.4,0-6.5-2.2-7.5-5.4c-1-3.2-1.4-6.9-1.2-10.9c0.3-5.2,1.9-9.2,4.7-11.9c1.4-1.3,3.4-1.7,5.3-1.1l2.9,1.1
	c2.4,0.9,4.9,1,7.4,0.3l5.4-1.6C130.2,38.9,132,39.2,133.3,40.3z M129.8,43.6l-5.4,1.6c-3.4,1-6.9,0.8-10.2-0.4l-2.9-1.1
	c-0.2-0.1-0.4,0-0.6,0.1c-1.9,1.8-3.1,4.8-3.3,9c-0.2,3.5,0.1,6.6,1,9.3c0.4,1.4,1.7,2.3,3.2,2.3h18.2c1.6,0,2.9-1.1,3.3-2.6
	c0.4-1.9,0.7-4.5,0.7-7.8c0-5.1-1.2-8.5-3.4-10.3C130.2,43.6,130,43.6,129.8,43.6z M119.9,13.6c6.8,0,12.2,5.4,12.2,12.1
	c0,0.1,0,0.1,0,0.2l0,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,2,0.6,3.9,1.4,5.7c0.3,0.7,0.6,1.2,0.9,1.8c0.4,0.6,0,1-0.8,1.3
	c-0.5,0.2-1.3,0.3-2.3,0.4c-1.4,0.1-3.2,0.2-4.6,0.1c-2.1,1.4-4.5,2.1-7,2.1c-2.6,0-4.9-0.7-7-2.1c-1.4,0-3.1,0-4.5-0.2
	c-1-0.1-1.8-0.2-2.3-0.4c-0.8-0.3-1.1-0.7-0.7-1.3c0.4-0.5,0.7-1.1,1-1.8c0.8-1.8,1.3-3.7,1.4-5.6c0-0.1,0-0.2,0-0.4
	c0-0.1,0-0.2,0-0.2l0-0.1C107.6,19.1,113.1,13.6,119.9,13.6z M126,19.7l-0.3,0.3c-0.7,0.8-1.8,1.5-3.1,1.9c-1.8,0.6-3,0.7-5.5,0.7
	c-1,0-1.5,0-2.1,0.1c-1.2,0.1-2.2,0.4-3.4,0.9c0,0,0,0,0,0l-0.1,0l0,0l-0.1,0l-0.2,0.2c-0.1,0.7-0.2,1.3-0.2,1.9
	c0,4.8,3.9,8.6,8.7,8.6c4.8,0,8.7-3.9,8.7-8.6c0-2.1-0.7-4-2.1-5.6l-0.2-0.2L126,19.7z M117,25c0.9,0,1.6,0.7,1.6,1.6
	c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6C115.4,25.7,116.1,25,117,25z M122.9,25c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	c-0.9,0-1.6-0.7-1.6-1.6C121.3,25.7,122,25,122.9,25z`}/>
        <path d={`M14.5,23.4c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6c-0.9,0-1.6-0.7-1.6-1.6
	C13,24.1,13.7,23.4,14.5,23.4z M18.8,25c0-0.9,0.7-1.6,1.6-1.6S22,24.1,22,25c0,0.9-0.7,1.6-1.6,1.6C19.5,26.5,18.8,25.8,18.8,25z
	 M37.5,46.8h-0.3c0-3.3-2.7-6-6-6c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6s6-2.7,6-6H37.5z M21.3,46.8H21c0-5.6,4.6-10.2,10.2-10.2
	c5.6,0,10.2,4.6,10.2,10.2c0,5.6-4.6,10.2-10.2,10.2C25.5,57,21,52.5,21,46.8H21.3z M17.4,12.1c6.7,0,12.1,5.4,12.1,12.1
	c0,0.1,0,0.1,0,0.2l0,0.1c0,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0.1,2,0.6,3.9,1.4,5.7c0.3,0.7,0.6,1.2,0.9,1.8c0.4,0.6,0,1-0.7,1.3
	c-0.5,0.2-1.3,0.3-2.3,0.4c-1.4,0.1-3.1,0.2-4.5,0.1c-2.1,1.4-4.4,2.1-6.9,2.1c-2.5,0-4.9-0.7-6.9-2.1c-1.4,0-3.1,0-4.5-0.2
	c-1-0.1-1.8-0.2-2.3-0.4c-0.7-0.3-1.1-0.7-0.7-1.3c0.4-0.5,0.7-1.1,0.9-1.8c0.8-1.8,1.3-3.7,1.4-5.6c0-0.1,0-0.2,0-0.4
	c0-0.2,0-0.2,0-0.2l0-0.1C5.2,17.5,10.7,12.1,17.4,12.1z M23.5,18.1l-0.3,0.3c-0.7,0.8-1.8,1.5-3.1,1.9c-1.7,0.6-3,0.7-5.4,0.7
	c-1,0-1.5,0-2,0.1c-1.2,0.1-2.2,0.4-3.3,0.9c0,0,0,0,0,0c0,0-0.1,0-0.1,0l0,0L9,22.3c-0.1,0.7-0.2,1.3-0.2,1.9
	c0,4.8,3.9,8.6,8.6,8.6S26,29,26,24.2c0-2.2-0.8-4.2-2.3-5.8l0,0L23.5,18.1z M43.6,53.9c2.6,3.9,3.4,9,2,12.3
	c-0.3,0.9-1.2,1.5-2.2,1.5H19.1c-1,0-1.8-0.6-2.1-1.5c-1.5-3.3-0.8-8.3,1.7-12.2c0.3-0.5,0.6-0.9,1-1.4l0.3-0.4l0.2,0.5
	c0.6,1.2,1.5,2.2,2.4,3.2l0.2,0.2l-0.2,0.2c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-1.4,2.2-2,4.7-1.8,6.6h20.8
	c0.2-2-0.4-4.5-1.9-6.7c0,0-0.1-0.1-0.1-0.2l-0.1-0.2l0.2-0.2c1-0.9,1.8-2,2.4-3.1l0.3-0.5l0.3,0.5c0,0.1,0.3,0.5,0.4,0.6
	C43.3,53.5,43.5,53.7,43.6,53.9l-0.3,0.2L43.6,53.9z M14.9,67.5v0.3h-6c-3.8,0-7.2-2.7-7.9-6.3c-0.4-1.9-0.6-3.8-0.6-5.7
	c0-7.7,1.7-12.5,5.4-16.8c0.8-0.9,1.9-1.4,3.1-1.4c0.7,0,1.3,0.1,1.8,0.4c2,1,4.3,1.5,6.6,1.5c1.1,0,2.2-0.1,3.2-0.4l0.9-0.2
	l-0.5,0.7c-0.9,1.3-1.5,2.7-1.8,4.1L19,44.2l-0.3,0c-0.7,0-1,0.1-1.4,0.1c-2.9,0-5.7-0.6-8.3-1.8c-1.3,1.5-2.2,3-2.8,4.8
	c-0.8,2.3-1.2,5.1-1.2,8.6c0,1.6,0.2,3.2,0.5,4.8c0.3,1.5,1.7,2.6,3.3,2.6h5l0,0.3c0.1,1.3,0.4,2.5,0.9,3.6
	C14.9,67.3,14.9,67.4,14.9,67.5z M31.8,46.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6c0,0.9-0.7,1.6-1.6,1.6
	C32.5,48.2,31.8,47.5,31.8,46.6z M28.5,48.2c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6
	C30,47.5,29.3,48.2,28.5,48.2z`}/>
        <path d={`M86.6,0.5c1.1,0,2.1,0.8,2.3,1.8L93.8,22c0.1,0.3,0.1,0.6,0.1,0.9L89,65.7
	c-0.1,1.2-1.2,2.1-2.4,2.1H62.2c-1.2,0-2.3-0.9-2.4-2.1L55,22.8c0-0.3,0-0.6,0.1-0.9l4.9-19.6c0.3-1.1,1.2-1.8,2.3-1.8H86.6z
	 M84.7,5.3H64.1l-4.3,17.4L64.4,63h20.1L89,22.7L84.7,5.3z`}/>
    </svg>;
};
