import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import FlexOptionPage, { FlexOptionPageData } from '../../pages/FlexOptionPage';
import { getOfferPageData, updateFlexOptionPageData } from '../../services/api';
import { addTrackingData } from '../../tracking/tracker';
import { mapBasketFields, mapBasketFieldsFormResponse, writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, StateName } from '../StateMachineTypes';

export const flexOptionPage: StateDefinition<FlexOptionPageData> = {
    name: StateName.FLEX_OPTION_PAGE,

    validInboundStates: [
        ...PERSONAL_INFORMATION_PAGES,
        ...CHECKOUT_PAGES,
        StateName.BURIAL_PACKAGE_PAGE,
        StateName.FEE_DYNAMIC_PAGE,
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT
                && data.userInput.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.FEE_DYNAMIC_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.BURIAL_PACKAGE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BURIAL_PACKAGE,
            newState: StateName.BURIAL_PACKAGE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'flexOption');

        return {
            userInput: {
                ...mapBasketFieldsFormResponse(response),
                calculationMode: response.calculationMode,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        }
            ;
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        const response = await updateFlexOptionPageData(inputData.businessId, {
            flexOption: inputData.userInput.flexOption
        });
        addTrackingData({selectedVariant: response.varianten.find(v => v.selected)});
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <FlexOptionPage
            storeState={{
                calculationMode: inputData.userInput.calculationMode,
                ...mapBasketFields(inputData.userInput),
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
