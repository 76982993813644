import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import InsuranceFeePage, { InsuranceFeePageData } from '../../pages/InsuranceFeePage';
import { getOfferPageData, updateInsuranceFeePageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, FEE_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, StateName } from '../StateMachineTypes';

export const insuranceFeePage: StateDefinition<InsuranceFeePageData> = {
    name: StateName.INSURANCE_FEE_PAGE,

    validInboundStates: [
        ...CHECKOUT_PAGES,
        ...PERSONAL_INFORMATION_PAGES,
        ...FEE_PAGES,
        StateName.PAYMENT_PERIOD_PAGE,
        StateName.INSURANCE_START_PAGE,
        StateName.INSURANCE_SUM_PAGE,
        StateName.INSURANCE_FEE_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURANCE_START_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.PAYMENT_PERIOD_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.INSURANCE_SUM,
            newState: StateName.INSURANCE_SUM_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.SWITCH_PAYMENT_PERIOD,
            newState: StateName.INSURANCE_FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceFee');

        return {
            userInput: {
                insuranceFee: response.insuranceFee || response.valueRanges!.insuranceFee.defaultValue,
                paymentMethod: response.paymentMethod,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updateInsuranceFeePageData(inputData.businessId, {
            paymentMethod: inputData.userInput.paymentMethod,
            insuranceFee: inputData.userInput.insuranceFee
        });
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuranceFeePage
            storeState={{
                insuranceFee: inputData.userInput.insuranceFee,
                paymentMethod: inputData.userInput.paymentMethod,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
