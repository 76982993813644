import { TariffBasisIcon, TariffComfortIcon, TariffPremiumIcon } from '@eg/elements/components/Icons';
import { Beitragsvariante } from 'stg-common';
import { IconProps } from '../IconTypes';

interface VariantIconProps extends IconProps{
    variant: Beitragsvariante;
}

export const VariantIcon = (props: VariantIconProps) => {
    return (<>
        {props.variant === Beitragsvariante.BASIS && <TariffBasisIcon height={props.height} width={props.width} />}
        {props.variant === Beitragsvariante.KOMFORT && <TariffComfortIcon height={props.height} width={props.width} />}
        {props.variant === Beitragsvariante.PREMIUM && <TariffPremiumIcon height={props.height} width={props.width} />}
    </>);
};
