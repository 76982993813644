import React from 'react';
import { Nationality, Person } from 'stg-common';
import { GwgOptions } from '../../components/GwgQuestions/GwgQuestions';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import AbstractPersonPage from '../AbstractPersonPage';

export interface RequestOfferInsuredPersonPageData extends GwgOptions, StoreStateUpdater<RequestOfferInsuredPersonPageData> {
    divergingInsuredPerson: boolean;
    insuredPerson: Person;
    nationalities: Nationality[];
    policyHolderId: string;
    showMeldung: boolean;
}

interface RequestOfferInsuredPersonPageProps extends PagePropsWithValues<RequestOfferInsuredPersonPageData> {
    businessId: string;
}

interface RequestOfferInsuredPersonPageState {
    isLoading: boolean;
}

class RequestOfferInsuredPersonPage extends React.Component<RequestOfferInsuredPersonPageProps, RequestOfferInsuredPersonPageState> {

    constructor(props: RequestOfferInsuredPersonPageProps) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    public render() {
        return (<AbstractPersonPage
            {...this.props}
            storeState={{
                ...this.props.storeState.insuredPerson,
                personId: this.props.storeState.insuredPerson.personId!,
                divergingInsuredPerson: this.props.storeState.divergingInsuredPerson,
                politicallyExposedPerson: this.props.storeState.politicallyExposedPerson,
                custodian: this.props.storeState.custodian,
                vklm: this.props.storeState.vklm,
                accountThirdParty: this.props.storeState.accountThirdParty,
                nationalities: this.props.storeState.nationalities,
                showMeldung: this.props.storeState.showMeldung,
                update: this.props.storeState.update
            }}
            headline="Versicherte Person"
            componentPrefix="request-offer-insured-person"
            showDetailedMode={!this.props.storeState.divergingInsuredPerson}
            disableBirthday={true}
            isGwgRequired={false}
            trackPersonData={!this.props.storeState.divergingInsuredPerson}
            isRequestOffer={true}
            differentUserPersonId={this.props.storeState.divergingInsuredPerson ? this.props.storeState.policyHolderId : undefined}
            isNationalityRequired={true}
        />);
    }
}

export default RequestOfferInsuredPersonPage;
