import './FooterMakler.css';

export const FooterMakler = () => (<div className="footer__banner_isMakler">
<div className="esc_container esc_container--xxl esc_box--around">
    <ul className="footer__banner-links">
        <li className="footer__banner-links__item">
            <a className="footer__banner-links__link" href=" https://www.ergo.de/de/Service/Datenschutz" target="_self">Datenschutz</a>
        </li>
        <li className="footer__banner-links__item">
            <a className="footer__banner-links__link" href="https://www.ergo.de/de/Service/Anbieter" target="_self">Impressum</a>
        </li>
    </ul>
</div>
</div>);
