import { ControlWithHint } from '@eg/elements/ControlWithHint';
import { DateInput } from '@eg/elements/DateInput';
import { Field, FieldProps, Form as FormRaw, Formik, FormikProps, FormikValues } from 'formik';
import { useEffect } from 'react';
import { IsoDateString, mapDateInputToIsoDateString, mapIsoDateStringToDateInput } from 'stg-common';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { getIsMakler } from '../helpers/modeConfig';
import { scrollToTop } from '../helpers/scrolling';
import { getErrorMessage } from '../helpers/validationHelpers';
import { NavigationAction } from '../routing/StateMachineTypes';
import { DataIdBirthdatePage } from '../types/DataId';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createBirthdatePageSchema } from '../validation/BirthdatePage';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

export interface BirthdatePageData extends StoreStateUpdater<BirthdatePageData> {
    birthdate: IsoDateString;
}

const BirthdatePage = (props: PagePropsWithValues<BirthdatePageData>) => {
    useEffect(() => {
        scrollToTop();
    }, []);
    const dateLength = 10;
    const btnDisabled = (form: FormikProps<{}>) => {
        return props.storeState.birthdate === null || props.storeState.birthdate.length < dateLength
            || !!(getErrorMessage(form, { name: 'birthdate' }));
    };
    return (
        <Formik
            initialValues={props.storeState}
            onSubmit={values => {
                const validatedValues = {
                    ...props.storeState,
                    ...createBirthdatePageSchema(props.valueRanges).cast(values)
                };
                props.storeState.update(validatedValues);
                props.handleAction(NavigationAction.NEXT);
            }}
            validationSchema={createBirthdatePageSchema(props.valueRanges)}
        >
            {form => (
                <Form noValidate>
                    <Headline tooltip={
                        <>Der Versicherungsnehmer ist der Vertragspartner. Der Versicherungsnehmer kann, muss aber nicht selbst versichert sein.
                            Die versicherte Person kann z. B. auch der Lebenspartner sein. Im Todesfall der versicherten Person
                            wird die vereinbarte Summe ausgezahlt.</>
                    }>
                        Geben Sie Ihr Geburtsdatum ein
                    </Headline>
                    <div style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div style={{ maxWidth: '300px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Field
                                name="birthdateField"
                                render={({ field }: FieldProps<FormikValues>) => {
                                    const { setFieldValue, setFieldTouched } = form;
                                    return <>
                                        <DateInput
                                            id={field.name}
                                            data-component-id={DataIdBirthdatePage.INPUT_BIRTHDATE}
                                            onBlur={() => {
                                                setFieldTouched(field.name);
                                                setFieldTouched('birthdate');
                                            }}
                                            onChange={value => {
                                                setFieldValue(field.name, value);
                                                setFieldValue('birthdate', mapDateInputToIsoDateString(value));
                                                props.storeState.update({
                                                    birthdate: mapDateInputToIsoDateString(value)
                                                });
                                            }}
                                            value={field.value ? field.value : mapIsoDateStringToDateInput(props.storeState.birthdate)}
                                            autoTab={true}
                                        />
                                    </>;
                                }}
                            />
                        </div>
                        <ControlWithHint error={getErrorMessage(form, { name: 'birthdate' })}><></></ControlWithHint>
                        <div style={{ maxWidth: '500px', fontSize: '0.75em', color: '#737373', paddingTop: '1.75em', paddingBottom: '8em', margin: '0 auto' }}>
                            Die versicherte Person muss zwischen 40 und 85 Jahre alt sein.
                            Das Alter berechnet sich so: Jahr des gewünschten Versicherungsbeginns
                            (z. B. {new Date().getFullYear()}) - Geburtsjahr.
                        </div>
                        <Footer
                            disableNextButton={btnDisabled(form)}
                            disableOfferNavigation={true}
                            handleAction={props.handleAction}
                            hideBackBtn={!getIsMakler()}
                            onNextClick={getIsMakler() ? () => props.handleAction(NavigationAction.NEXT) : undefined}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default BirthdatePage;
