import Radio from '@eg/elements/Radio';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { Field, FieldProps, FormikValues } from 'formik';
import { getErrorMessage } from '../../helpers/validationHelpers';
import { UpdateGwgOptionsCallback } from './GwgQuestions';

export interface YesNoRadioOptions {
  name: string;
  onChange: UpdateGwgOptionsCallback;
  defaultValue?: boolean;
}

const YesNoRadio = ({ name, onChange, defaultValue }: YesNoRadioOptions) => (
  <Field
    name={name}
    data-component-id={`${name}Field`}
    render={({ field, form }: FieldProps<FormikValues>) => {
      const handleChange = (value: boolean) => {
        form.setFieldValue(field.name, value);
        onChange({ [name]: value });
      };

      return (
        <RadioGroupRow
          label=""
          id={field.name}
          name={field.name}
          error={getErrorMessage(form, field)}
          value={field.value}
          defaultValue={defaultValue}
        >
          <Radio value={true}
            label="ja"
            onChange={() => handleChange(true)}
            data-component-id={`${name}-yes-radio`} />
          <Radio
            value={false}
            label="nein"
            onChange={() => handleChange(false)}
            data-component-id={`${name}-no-radio`} />
        </RadioGroupRow>
      );
    }}
  />
);

export default YesNoRadio;
