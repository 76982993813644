/* tslint:disable:no-magic-numbers */
import React from 'react';
import { Button } from '@eg/elements/Button';
import { ComparisonTable } from '@eg/elements/ComparisonTable';
import {
  CheckColorIcon,
  CheckIllustratedIcon,
  CloseBorderIcon,
} from '@eg/elements/components/Icons';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { Modal } from '@eg/elements/Modal';
import { Price } from '@eg/elements/Price';
import { Radio } from '@eg/elements/Radio';
import { RadioTab } from '@eg/elements/RadioTab';
import { RadioTabs } from '@eg/elements/RadioTabs';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { addYears, format } from 'date-fns';
import { Field, FieldProps, Form as FormRaw, Formik, FormikValues } from 'formik';
import { Beitragsvariante, CalculationMode, IsoDateString, Message, toReadableWord, Variante } from 'stg-common';
import { InsuranceFeeSlider } from '../components/calculationComponents/InsuranceFeeSlider';
import { InsuranceSumSlider } from '../components/calculationComponents/InsuranceSumSlider';
import { PaymentMethodSelection } from '../components/calculationComponents/PaymentMethodSelection';
import { PaymentPeriodSlider } from '../components/calculationComponents/PaymentPeriodSlider';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import DropDown, {
  DropdownOption,
  DropdownProps,
} from '../components/Dropdown';
import ErrorMessage from '../components/ErrorMessage';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { VariantIcon } from '../components/icons';
import '../components/SelectableCard.css';
import {
  PAYMENT_METHOD_SINGLE_FEE,
  STEP_RANGE_SUM_INSURED,
} from '../constants';
import { getTiedAgentPnrNumber } from '../helpers/aemHelper';
import formatter, {
  EURO_WITH_CENT,
  EURO_WITHOUT_CENT,
} from '../helpers/currencyFormatter';
import { errorMessageFunction } from '../helpers/errorMessagesFunction';
import '../helpers/InlineTooltip.css';
import { getIsMakler, getIsTiedAgent, getOeNumber } from '../helpers/modeConfig';
import {
  isAgeAtPaymentPeriodEndOverEightyfour,
  mapPaymentMethodToAdjective,
  mapPaymentMethodToText,
} from '../helpers/paymentHelper';
import { scrollToTop } from '../helpers/scrolling';
import { GREY_OUT } from '../helpers/styles';
import { NavigationAction, StateName } from '../routing/StateMachineTypes';
import {
  getOfferPageData,
  recalculateVariants,
  updateInsuranceFeeVariant,
} from '../services/api';
import {
  trackElementClicked,
  trackElementClickImmediate,
  trackPageTransition,
} from '../tracking/tracker';
import {
  TrackingElement,
  trackingElementVariant,
  trackingElementVariantLA,
  trackingElementVariantSelection,
} from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import {
  createFeePageSchemaWithoutSum,
  createFeePageSchemaWithSum,
} from '../validation/FeePage';
import './css/FeePage.css';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

interface CreateArrayOptions {
  defaultValue: number | undefined;
  min: number;
  max: number;
  increment: number | undefined;
}

// FIXME: This file has 1500 lines of code, is urgent that be split in more files
type Mapper = (item: string | number) => DropdownOption;

const createDropdownOptionsFromArray = (
  options: CreateArrayOptions,
  mapper: Mapper
) => {
  const { min, max, increment } = options;
  const inc = increment || max - min;
  const steps = (max - min) / inc;
  if (!!steps) {
    return Array.from(Array(steps + 1), (step, index) =>
      mapper(min + inc * index)
    );
  }
  return [];
};

export interface FeePageInputData {
  birthdate: IsoDateString;
  insuranceBegin: IsoDateString;
  paymentPeriod: number;
  paymentMethod: string;
  insuranceSum?: number;
  insuranceFee?: number;
  mustRecalculate?: boolean;
}

export interface FeePageData
  extends StoreStateUpdater<FeePageData>,
  FeePageInputData {
  businessId: string;
  calculationMode: CalculationMode;
  showCalculationDialog: boolean;
  variante?: Beitragsvariante;
  variants: Variante[];
}

interface FeePageProps extends PagePropsWithValues<FeePageData> { }

interface FeeState {
  showDialog: boolean;
  showCalculationDialog: boolean;
  priceLoading: boolean;
  variants: Variante[];
  errorMessages?: Message[];
  selectedVariantIndex: number;
  isLoading: boolean;
}

interface TariffingInformation {
  basis: TariffOption;
  comfort: TariffOption;
  premium: TariffOption;
}

interface TariffOption {
  available: boolean;
  selectable: boolean;
  insuranceSum: number;
  buildupTime?: number;
  price?: number;
  variableBonusEarning?: number;
  variant: Beitragsvariante;
  insuranceSumDeathByAccident: number;
}

interface Leistungsbeschreibung {
  label: string;
  leistungsEinschlussBasis: Leistungseinschluss;
  leistungsEinschlussComfort: Leistungseinschluss;
  leistungsEinschlussPremium: Leistungseinschluss;
  tooltip: string;
  overrideBasis?: JSX.Element;
  overrideComfort?: JSX.Element;
  overridePremium?: JSX.Element;
}

const ICON_WIDTH_BODY = 24;
const ICON_WIDTH_HEADER = 48;
const DETAILS_KEYWORD_STYLING: React.CSSProperties = {
  fontWeight: 'bold'
};
const DETAILS_KEYWORD_PRICE_STYLING: React.CSSProperties = {
  fontWeight: 'bold',
  fontSize: '25px'
};

enum Leistungseinschluss {
  NICHT_VERFUEGBAR,
  WAEHLBAR,
  NACHTRAGLICH_WAHLBAR,
  EINGESCHLOSSEN
}
class FeePage extends React.Component<FeePageProps, FeeState> {
  private static renderLeistungseinschluss(
    leistungsEinschluss: Leistungseinschluss
  ) {
    if (leistungsEinschluss === Leistungseinschluss.EINGESCHLOSSEN) {
      return (
        <td>
          <CheckIllustratedIcon
            height={ICON_WIDTH_BODY}
            width={ICON_WIDTH_BODY}
          />
        </td>
      );
    } else if (leistungsEinschluss === Leistungseinschluss.NICHT_VERFUEGBAR) {
      return (
        <td>
          <CloseBorderIcon height={ICON_WIDTH_BODY} width={ICON_WIDTH_BODY} />
        </td>
      );
    } else if (leistungsEinschluss === Leistungseinschluss.WAEHLBAR) {
      return (
        <td>
          <div>wählbar</div>
        </td>
      );
    } else if (
      leistungsEinschluss === Leistungseinschluss.NACHTRAGLICH_WAHLBAR
    ) {
      return (
        <td>
          <div>nachträglich wählbar</div>
        </td>
      );
    }
    return <></>;
  }

  private static formatTariffDisplayName(tariffOption: TariffOption) {
    const variant = tariffOption.variant;
    return (variant && variant[0].toUpperCase() + variant.slice(1)) || '';
  }

  private getDropdownData(): DropdownProps[] {
    const paymentMethod: DropdownProps = {
      id: 'payment-method',
      label: 'Zahlweise',
      onChange: newValue => {
        this.props.storeState.update(
          { paymentMethod: newValue, showCalculationDialog: false },
          () => {
            this.props.handleAction(NavigationAction.SWITCH_PAYMENT_PERIOD);
          }
        );
      },
      options:
        this.props.valueRanges.paymentMethod.possibleValues.map<DropdownOption>(option => ({
          label: option.text,
          value: option.key
        })
        ),
      value: this.props.storeState.paymentMethod
    };

    const insuranceSum =
      this.props.valueRanges.insuranceSum.increment &&
        this.props.valueRanges.insuranceSum.increment > STEP_RANGE_SUM_INSURED
        ? this.props.valueRanges.insuranceSum
        : {
          ...this.props.valueRanges.insuranceSum,
          increment: STEP_RANGE_SUM_INSURED
        };

    const insuredAmount: DropdownProps = {
      id: 'insured-amount',
      children: (
        <TooltipIcon>
          <span
            style={{
              display: 'block',
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: 8
            }}
          >
            Todesfallleistung
          </span>
          Diese Summe steht Ihren Hinterbliebenen nach der Aufbauzeit garantiert
          zur Verfügung. Die tatsächliche Gesamtleistung inkl. der
          Überschussbeteiligung kann höher ausfallen.
          <br />
          <br />
          Hinweis: Für eine Leistung über 15.000 € wählen Sie bitte den
          Premium-Tarif.
        </TooltipIcon>
      ),
      label: 'Todesfallleistung',
      onChange: newValue => {
        this.props.storeState.update({ insuranceSum: Number(newValue) }, () => {
          this.props.handleAction(NavigationAction.SWITCH_PAYMENT_PERIOD);
        });
      },
      options: createDropdownOptionsFromArray(insuranceSum, item => ({
        label: formatter(EURO_WITHOUT_CENT).format(item as number),
        value: `${item}`
      })),
      value: ''
    };

    const paymentPeriodOptions = createDropdownOptionsFromArray(
      this.props.valueRanges.paymentPeriod, item => ({
        label: `${item} Jahre`,
        value: `${item}`
      })
    );

    if (!paymentPeriodOptions.length) {
      return [paymentMethod, insuredAmount];
    }

    const paymentPeriod: DropdownProps = {
      id: 'payment-period',
      children: (
        <TooltipIcon>
          <span
            style={{
              display: 'block',
              fontSize: '14px',
              fontWeight: 'bold',
              marginBottom: 8
            }}
          >
            Beitragszahlungsdauer
          </span>
          Sie bestimmen, wie lange Sie Beiträge zahlen. Danach läuft der
          Versicherungsschutz lebenslang beitragsfrei weiter.
          <br />
          <br />
          Bitte beachten Sie: Je kürzer die Beitragszahlungsdauer, desto höher
          ist der zu zahlende Beitrag in dieser Zeit.
        </TooltipIcon>
      ),
      label: 'Beitragszahlungsdauer',
      onChange: newValue => {
        this.props.storeState.update(
          { paymentPeriod: Number(newValue) },
          () => {
            this.props.handleAction(NavigationAction.SWITCH_PAYMENT_PERIOD);
          }
        );
      },
      options: paymentPeriodOptions,
      value: `${this.props.storeState.paymentPeriod}`
    };

    return [paymentMethod, insuredAmount, paymentPeriod];
  }

  constructor(props: Readonly<FeePageProps>) {
    super(props);
    this.state = {
      showDialog: false,
      showCalculationDialog: this.props.storeState.showCalculationDialog,
      priceLoading: false,
      variants: this.props.storeState.variants,
      errorMessages: this.props.storeState.messages,
      selectedVariantIndex: this.findSelectedVariantIndex(
        this.props.storeState.variants
      ),
      isLoading: false
    };
  }
  public findSelectedVariantIndex(variants: Variante[]): number {
    let index = 1;
    if (variants.length !== 3) {
      throw new Error('Expect exactly 3 tariff variants.');
    }

    variants.forEach((variant, i) => {
      if (variant.selected) {
        index = i;
        return true;
      }
      return false;
    });
    return index;
  }
  public canProceed() {
    if (!this.state.variants[this.state.selectedVariantIndex].selectable) {
      return false;
    }
    if (this.state.priceLoading) {
      return false;
    }
    return true;
  }
  public componentDidMount() {
    scrollToTop();
    trackPageTransition(
      this.findVariantTrackingStateName(
        this.state.variants[this.state.selectedVariantIndex]
      ),
      getIsMakler(),
      getIsTiedAgent(),
      getOeNumber(),
      getTiedAgentPnrNumber
    );
  }
  public selectVariant(index: string) {
    this.setState(
      { selectedVariantIndex: +index, isLoading: true },
      async () => {
        await updateInsuranceFeeVariant(this.props.storeState.businessId, {
          variante: this.state.variants[index].variante,
          paymentMethod: this.props.storeState.paymentMethod
        });
        this.setState({ isLoading: false });
        this.trackVariantChange(+index);
      }
    );
  }
  public trackVariantChange(index: number) {
    const item = this.state.variants[index];
    try {
      trackElementClickImmediate(
        trackingElementVariantSelection[item.variante],
        this.findVariantTrackingStateName(item),
        true
      );
    } catch (e) {
      console.warn('Tracking Variant Change failed.', (e as Error).message);
    }
  }
  public trackCriteriaChange(variants: Variante[]) {
    const item = variants[this.state.selectedVariantIndex];
    try {
      trackElementClickImmediate(
        TrackingElement.Empty,
        this.findVariantTrackingStateName(item),
        true
      );
    } catch (e) {
      console.warn('Tracking Criteria Change failed.', (e as Error).message);
    }
  }
  public findVariantTrackingStateName(item: Variante) {
    return item.selectable ? StateName.FEE_PAGE : StateName.FEE_PAGE_INVALID;
  }

  public render() {
    const { basis, comfort, premium } = this.getTariffingInformation();
    const errorMessages = errorMessageFunction(this.state.errorMessages, this.state.selectedVariantIndex);
    const haveErrorMesages = errorMessages.length > 0;
    return (
      <>
        {this.state.isLoading && <LoadingSpinner show={true} />}
        {this.renderAllInsuranceDetailsModal()}
        {this.renderChangeCalculationValuesModal()}
        <div
          style={{
            maxWidth: '1000px',
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          {this.renderTariffDetails()}
          <RadioTabs
            currentValue={this.state.selectedVariantIndex + ''}
            onSelect={this.selectVariant.bind(this)}
            name="variant"
          >
            {this.renderVariant(basis, 0)}
            {this.renderVariant(comfort, 1)}
            {this.renderVariant(premium, 2)}
          </RadioTabs>
          <div className="FeePage--options">
            <Formik
              initialValues={this.props.storeState}
              onSubmit={this.onSubmitWithTrack}
            >
              {form => (
                <Form>
                  {this.getDropdownData().map(dropdown => (
                    <Field
                      key={dropdown.id}
                      render={({ field }: FieldProps<FormikValues>) => {
                        let value = dropdown.value;
                        let onChange = dropdown.onChange;

                        if (dropdown.id === 'insured-amount') {
                          value = field.value.insuranceSum;
                          onChange = newValue => {
                            form.setFieldValue('insuranceSum', newValue);
                            dropdown.onChange(newValue);
                          };
                        }

                        return (
                          <DropDown
                            {...dropdown}
                            value={value}
                            onChange={onChange}
                            display="inline"
                          />
                        );
                      }}
                    ></Field>
                  ))}
                </Form>
              )}
            </Formik>
          </div>
          {haveErrorMesages && <ErrorMessage messages={errorMessages} />}
          {!haveErrorMesages && <div style={{ marginTop: '30px', marginBottom: '-15px' }}>
            <Button
              width="auto"
              type="submit"
              variant="primary"
              size="large"
              onClick={async () => {
                await this.proceed(
                  this.state.variants[this.state.selectedVariantIndex].variante
                );
              }}
              disabled={!this.canProceed()}
              data-component-id={`open-modal-dialog`}
            >
              <div style={{ fontSize: '12px' }}>weiter zum Online-Antrag</div>
            </Button>
          </div>}
          <Footer
            disableOfferNavigation={true}
            errorStatus={haveErrorMesages}
            handleAction={this.props.handleAction}
            hideNextButton={true}
          />
          <DisclaimerInfoBox />
        </div>
      </>
    );
  }

  private readonly onSubmitWithTrack = async () => {
    trackElementClickImmediate(TrackingElement.Button_NeuBerechnen);
    await this.recalculate();
    this.setState({ showCalculationDialog: false });
  };

  private setAction(): void {
    this.props.handleAction(NavigationAction.SWITCH_CALCULATION_MODE);
  }

  private renderChangeCalculationValuesModal(): JSX.Element {
    const calculationMode = this.props.storeState.calculationMode;

    return (
      <Modal
        open={this.state.showCalculationDialog}
        onDismiss={async () => {
          await this.resetCalculationValues();
          trackElementClickImmediate(TrackingElement.Button_SchliessenX);
          this.setState({ showCalculationDialog: false });
        }}
        dismissible={!this.props.storeState.mustRecalculate}
        useEscKeyToDismiss={true}
      >
        <b>Ihre ausgewählten Angaben ändern:</b>
        <Formik
          initialValues={this.props.storeState}
          onSubmit={this.onSubmitWithTrack}
          validationSchema={this.getValidationSchema(
            this.props.storeState.calculationMode,
            this.props.storeState.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE
          )}
        >
          {form => (
            <Form>
              <div className="esc_grid" style={{ marginTop: '1em' }}>
                <div
                  className="esc_grid__wrapper"
                  style={{ textAlign: 'center' }}
                >
                  <div className="esc_col esc_col-12 esc_col-m-6">
                    <Radio
                      value={CalculationMode.INSURANCE_FEE}
                      name="calculation-mode"
                      checked={
                        calculationMode === CalculationMode.INSURANCE_FEE
                      }
                      data-component-id="calculation-mode-fee"
                      onClick={() => {
                        this.props.storeState.update(
                          {
                            calculationMode: CalculationMode.INSURANCE_FEE,
                            showCalculationDialog: true
                          },
                          this.setAction
                        );
                      }}
                      label="Beitrag"
                    />
                  </div>
                  <div className="esc_col esc_col-12 esc_col-m-6">
                    <Radio
                      value={CalculationMode.INSURANCE_SUM}
                      name="calculation-mode"
                      checked={
                        calculationMode === CalculationMode.INSURANCE_SUM
                      }
                      data-component-id="calculation-mode-sum"
                      onClick={() => {
                        this.props.storeState.update(
                          {
                            calculationMode: CalculationMode.INSURANCE_SUM,
                            showCalculationDialog: true
                          },
                          this.setAction
                        );
                      }}
                      label="Versicherungsumme"
                    />
                  </div>
                </div>
              </div>

              {calculationMode === CalculationMode.INSURANCE_FEE && (
                <div style={{ marginBottom: '7px', marginTop: '20px' }}>
                  <b>Beitrag:</b>
                  <InsuranceFeeSlider
                    form={form}
                    valueRange={this.props.valueRanges.insuranceFee}
                    updateState={this.props.storeState.update}
                  />
                </div>
              )}

              {calculationMode === CalculationMode.INSURANCE_SUM && (
                <div style={{ marginBottom: '7px', marginTop: '20px' }}>
                  <b>Versicherungssumme:</b>
                  <InsuranceSumSlider
                    form={form}
                    valueRange={this.props.valueRanges.insuranceSum}
                    updateState={this.props.storeState.update}
                  />
                </div>
              )}

              {PaymentMethodSelection(this.props)}

              {!this.isEinmalBeitrag() && (
                <div style={{ marginBottom: '7px', marginTop: '35px' }}>
                  <b>Beitragszahlungsdauer:</b>
                  <PaymentPeriodSlider
                    isFeePage={true}
                    displayWarningText={isAgeAtPaymentPeriodEndOverEightyfour(
                      form,
                      this.props.storeState.birthdate,
                      this.props.storeState.insuranceBegin
                    )}
                    form={form}
                    valueRange={this.props.valueRanges}
                    updateState={this.props.storeState.update}
                  />
                </div>
              )}

              <div
                id="calculationModalNavigation"
                style={{ marginTop: '20px' }}
              >
                {!this.props.storeState.mustRecalculate && (
                  <Button
                    type="button"
                    variant="text-link"
                    size="large"
                    data-component-id="cancel-recalculation-button"
                    onClick={async () => {
                      await this.resetCalculationValues();
                      trackElementClickImmediate(TrackingElement.Button_Abbrechen);
                      this.setState({ showCalculationDialog: false });
                    }}
                  >
                    abbrechen
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  data-component-id="submit-recalculation-button"
                  style={{ float: 'right' }}
                  size="large"
                >
                  neu berechnen
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    );
  }

  public getValidationSchema(
    calculationMode: CalculationMode,
    includePaymentPeriod: boolean
  ) {
    const valueRange = this.props.valueRanges;
    switch (calculationMode) {
      case CalculationMode.INSURANCE_SUM:
        return createFeePageSchemaWithSum(valueRange, includePaymentPeriod);
      case CalculationMode.INSURANCE_FEE:
      default:
        return createFeePageSchemaWithoutSum(valueRange, includePaymentPeriod);
    }
  }

  private async recalculate() {
    const paymentPeriod = this.props.storeState.paymentPeriod;
    const inputData = {
      paymentMethod: this.props.storeState.paymentMethod
    } as FeePageInputData;

    if (
      this.props.storeState.calculationMode === CalculationMode.INSURANCE_FEE
    ) {
      inputData.insuranceFee = this.props.storeState.insuranceFee;
    }
    if (
      this.props.storeState.calculationMode === CalculationMode.INSURANCE_SUM
    ) {
      inputData.insuranceSum = this.props.storeState.insuranceSum;
    }
    if (!this.isEinmalBeitrag() && paymentPeriod) {
      inputData.paymentPeriod = paymentPeriod;
    }

    this.setState({ priceLoading: true }, async () => {
      const response = await recalculateVariants(
        this.props.storeState.businessId,
        inputData
      );
      this.trackCriteriaChange(response.varianten);
      this.setState({
        variants: response.varianten,
        priceLoading: false
      });
      this.props.storeState.update({
        ...inputData,
        messages: response.messages,
        mustRecalculate: false
      });
    });
  }

  private async resetCalculationValues() {
    const response = await getOfferPageData(
      this.props.storeState.businessId,
      'insuranceModel'
    );
    const resetedValues = {} as FeePageInputData;
    if (response.calculationMode === CalculationMode.INSURANCE_FEE) {
      resetedValues.insuranceFee = response.insuranceFee;
    }
    if (response.calculationMode === CalculationMode.INSURANCE_SUM) {
      resetedValues.insuranceSum = response.insuranceSum;
    }
    if (response.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE) {
      resetedValues.paymentPeriod = response.paymentPeriod as number;
    }
    this.props.storeState.update(resetedValues);
  }

  private isEinmalBeitrag(): boolean {
    return this.props.storeState.paymentMethod === PAYMENT_METHOD_SINGLE_FEE;
  }

  private renderAllInsuranceDetailsModal() {
    const { basis, comfort, premium } = this.getTariffingInformation();

    const basisInsuranceSum = this.getTariffOption(Beitragsvariante.BASIS);
    const comfortInsuranceSum = this.getTariffOption(Beitragsvariante.KOMFORT);
    const premiumInsuranceSum = this.getTariffOption(Beitragsvariante.PREMIUM);
    const formattedBasisInsuranceSum = basisInsuranceSum.insuranceSum
      ? formatter(0).format(basisInsuranceSum.insuranceSum)
      : '-,--';
    const formattedComfortInsuranceSum = comfortInsuranceSum.insuranceSum
      ? formatter(0).format(comfortInsuranceSum.insuranceSum)
      : '-,--';
    const formattedPremiumInsuranceSum = premiumInsuranceSum.insuranceSum
      ? formatter(0).format(premiumInsuranceSum.insuranceSum)
      : '-,--';
    return (
      <Modal
        open={this.state.showDialog}
        id="leistungsbausteineModal"
        onDismiss={() => {
          trackElementClickImmediate(TrackingElement.Button_SchliessenX);
          this.setState({ showDialog: false });
        }}
        extendedWidth={true}
        dismissible
        useEscKeyToDismiss={true}
      >
        <h2>Alle Leistungen im Überblick</h2>
        <ComparisonTable>
          <thead>
            <tr>
              <th />
              {this.renderModalHeader(basis)}
              {this.renderModalHeader(comfort)}
              {this.renderModalHeader(premium)}
            </tr>
            <tr>
              <th scope="row">
                Ihr{' '}
                {mapPaymentMethodToAdjective(
                  this.props.storeState.paymentMethod
                )}
                er Beitrag
              </th>
              {this.renderModalSelection(basis)}
              {this.renderModalSelection(comfort)}
              {this.renderModalSelection(premium)}
            </tr>
          </thead>
          <tbody>
            {
              // TODO: refactor me and make me functional
            }
            <tr>
              <th scope="row">
                Versicherungsleistung
                <TooltipIcon>
                  Diese Summe wird im Todesfall nach der Aufbauzeit garantiert
                  ausgezahlt.
                </TooltipIcon>
              </th>
              {basis.available && (
                <td>
                  <div>{formattedBasisInsuranceSum}</div>
                </td>
              )}
              {comfort.available && (
                <td>
                  <div>{formattedComfortInsuranceSum}</div>
                </td>
              )}
              {premium.available && (
                <td>
                  <div>{formattedPremiumInsuranceSum}</div>
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                Beitragszahlungsdauer
                <TooltipIcon>
                  Beitragszahlungsdauer: mindestens 5 Jahre; maximal 50 Jahre in
                  der Basisvariante, bei Komfort und Premium 45 Jahre. Davon
                  abgesehen ist sie auf ein Alter von 90 Jahren (Basis) bzw. 85
                  Jahren (Komfort und Premium) begrenzt.
                </TooltipIcon>
              </th>
              {basis.available && (
                <td>
                  <div>{this.props.storeState.paymentPeriod} Jahre</div>
                </td>
              )}
              {comfort.available && (
                <td>
                  <div>{this.props.storeState.paymentPeriod} Jahre</div>
                </td>
              )}
              {premium.available && (
                <td>
                  <div>{this.props.storeState.paymentPeriod} Jahre</div>
                </td>
              )}
            </tr>
            <tr>
              <th scope="row">
                Aufbauzeit
                <TooltipIcon>
                  Während der Aufbauzeit steht bei Unfalltod die volle
                  Versicherungssumme zur Verfügung. Sonst erst nach dieser Zeit.
                </TooltipIcon>
              </th>
              {basis.available && (
                <td>
                  <div>36 Monate</div>
                </td>
              )}
              {comfort.available && (
                <td>
                  <div>18 Monate</div>
                </td>
              )}
              {premium.available && (
                <td>
                  <div>18 Monate</div>
                </td>
              )}
            </tr>
            {this.renderLeistungsBeschreibung({
              label: 'Doppelte Leistung bei Unfalltod',
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussBasis: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Sollten Sie aufgrund eines Unfalls versterben, zahlen wir die doppelte Versicherungssumme.'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Leistung bei Pflegebedürftigkeit',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: this.isEinmalBeitrag()
                ? Leistungseinschluss.NICHT_VERFUEGBAR
                : Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              tooltip: `Sollten Sie während der Beitragszahlungsdauer pflegebedürftig werden, erhalten Sie die noch ausstehenden Bruttobeiträge als Einmalleistung. Aus dieser Summe können Sie die zukünftigen Beiträge zahlen. 
                        Der Vertrag wird nicht beitragsfrei gestellt.`
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Vorgezogene Leistung bei schwerer Krankheit',
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              tooltip:
                'Wenn Sie an einer unheilbaren Krankheit leiden, steht die vereinbarte Todesfallsumme schon zu Lebenszeiten zur Verfügung.'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Flex-Option',
              leistungsEinschlussBasis:
                Leistungseinschluss.NACHTRAGLICH_WAHLBAR,
              leistungsEinschlussComfort:
                Leistungseinschluss.NACHTRAGLICH_WAHLBAR,
              leistungsEinschlussPremium:
                Leistungseinschluss.NACHTRAGLICH_WAHLBAR,
              tooltip: `Die Beiträge wurden bis zum vereinbarten Ende der Beitragszahlungspflicht gezahlt. Wenn Sie nun die Versicherung kündigen,
                         erhalten Sie den Rückkaufswert ohne Abzüge. Die Versicherung endet dann. Die Flex-Option können Sie nach dem Online-Abschluss hinzuwählen.`
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Beitragsdynamik',
              leistungsEinschlussBasis: this.isEinmalBeitrag()
                ? Leistungseinschluss.NICHT_VERFUEGBAR
                : Leistungseinschluss.WAEHLBAR,
              leistungsEinschlussComfort: this.isEinmalBeitrag()
                ? Leistungseinschluss.NICHT_VERFUEGBAR
                : Leistungseinschluss.WAEHLBAR,
              leistungsEinschlussPremium: this.isEinmalBeitrag()
                ? Leistungseinschluss.NICHT_VERFUEGBAR
                : Leistungseinschluss.WAEHLBAR,
              tooltip: `Der Beitrag steigt jährlich um 5 %. Dadurch erhöht sich die Versicherungssumme zur Auszahlung.
                        So verhindern Sie einen Wertverlust durch steigende Lebenshaltungskosten.`
            })}
            <tr data-role="subhead">
              {/* tslint:disable-next-line:no-magic-numbers */}
              <th scope="rowgroup" colSpan={4}>
                Zusatzleistungen zu Lebzeiten
              </th>
            </tr>
            {this.renderLeistungsBeschreibung({
              label: 'Vorsorge-Ordner und Beratungstelefon',
              leistungsEinschlussBasis: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip: `Der Ordner unterstützt Sie dabei, wichtige Dinge für Ihre Angehörigen bereits jetzt vorzubereiten. In 9 übersichtlichen 
                        Kapiteln finden Sie Informationen, Checklisten und Muster-Formulare: Wer soll benachrichtigt werden, wie stellen Sie sich Ihre 
                        Beisetzung vor, wo befinden sich wichtige Unterlagen und wie haben Sie Ihren Nachlass geregelt. So geben Sie Ihren Angehörigen 
                        eine große Hilfe in einer schweren Zeit. Damit können Sie alles nach Ihren Wünschen regeln und wichtige Dokumente wie die 
                        Versicherungsurkunde, Vollmachten und Verfügungen an geeigneter Stelle ablegen.`
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Kostenlose Rechtsberatung',
              leistungsEinschlussComfort: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussBasis: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Vertragsanwälte beraten rund um die Themen Testament und Erbrecht'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Juristisch geprüfte Dokumente',
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussBasis: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Vorlagen für beispielsweise eine Patientenverfügung und eine Vorsorgevollmacht sind online abrufbar.'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Best Doctors',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip: `Bei Zweifeln am ärztlichen Befund oder der Behandlung kann über das weltweite Experten-Netzwerk von 
                        Best Doctors eine medizinische Zweitmeinung eingeholt werden. Beliebig oft und nicht nur bei schwerer Krankheit.`
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Telefonische Pflegeberatung',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Sie oder Ihre Angehörigen erhalten eine kostenfreie telefonische Pflegeberatung.'
            })}
            <tr data-role="subhead">
              {/* tslint:disable-next-line:no-magic-numbers */}
              <th scope="rowgroup" colSpan={4} className="InlineTooltip">
                Zusatzleistungen im Todesfall
                <TooltipIcon>
                  Wichtig: Bei allen im Folgenden genannten
                  Vermittlungsleistungen müssen die Angehörigen die vermittelte
                  Leistung selbst bezahlen. Nur die Vermittlung der Leistungen
                  ist für die Angehörigen kostenlos
                </TooltipIcon>
              </th>
            </tr>
            {this.renderLeistungsBeschreibung({
              label: 'Bestattungspakete',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.WAEHLBAR,
              tooltip:
                'Die Bestattung zu Lebzeiten selbst gestalten. 3 Pakete stehen zur Wahl: Klassisch (5.000 €), Traditionell (7.500 €) oder Exklusiv (10.000 €). Ein Bestattungspaket können Sie nach dem Online-Abschluss der Premium-Variante hinzuwählen.',
              overridePremium: (
                <td>
                  <div className="ee_comparison-table__highlight">
                    wählbar
                    <br />
                    <small style={{ color: '#000000' }}>
                      bis zu 20% günstiger
                    </small>
                  </div>
                </td>
              )
            })}
            {this.renderLeistungsBeschreibung({
              label:
                'Kostenlose Vermittlung von psychologischer Betreuung für Angehörige',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              tooltip:
                'Vermittlung von Experten damit die Angehörigen besser mit dem Verlust klarkommen.'
            })}
            {this.renderLeistungsBeschreibung({
              label:
                'Kostenlose Vermittlung eines Spezialisten für die Rückholung bei Tod im Ausland',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Sollten Sie im Ausland versterben, unterstützen wir Ihre Angehörigen bei der Rückholung nach Deutschland. Unsere Partner sind WDS.care und dessen Partnerunternehmen Laco GmbH, ein weltweiter Dienstleister für die Überführung Verstorbener.'
            })}
            {this.renderLeistungsBeschreibung({
              label:
                'Kostenlose Vermittlung haushaltsnaher Dienstleistungen, ggf. Haushaltsauflösung',
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              tooltip:
                'Vermittlung von Experten für Hilfe bei der Regelung des Haushalts.'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Kostenlose Vermittlung einer Haustierbetreuung',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Vermittlung von Experten damit das Haustier auch weiterhin gut versorgt wird.'
            })}
            {this.renderLeistungsBeschreibung({
              label: 'Digitaler Nachlass-Verwalter',
              leistungsEinschlussBasis: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussComfort: Leistungseinschluss.NICHT_VERFUEGBAR,
              leistungsEinschlussPremium: Leistungseinschluss.EINGESCHLOSSEN,
              tooltip:
                'Account-Verwaltung für persönliche Daten, Fotos, soziale Netzwerke und Cloud-Dienste'
            })}
          </tbody>
        </ComparisonTable>
      </Modal>
    );
  }

  private renderLeistungsBeschreibung(
    leistungsBeschreibung: Leistungsbeschreibung
  ) {
    const { basis, comfort, premium } = this.getTariffingInformation();

    return (
      <tr>
        <th scope="row">
          {leistungsBeschreibung.label}
          {leistungsBeschreibung.tooltip && (
            <TooltipIcon>{leistungsBeschreibung.tooltip}</TooltipIcon>
          )}
        </th>
        {basis.available &&
          (leistungsBeschreibung.overrideBasis ||
            FeePage.renderLeistungseinschluss(
              leistungsBeschreibung.leistungsEinschlussBasis
            ))}
        {/* tslint:disable-next-line:max-line-length */}
        {comfort.available &&
          (leistungsBeschreibung.overrideComfort ||
            FeePage.renderLeistungseinschluss(
              leistungsBeschreibung.leistungsEinschlussComfort
            ))}
        {/* tslint:disable-next-line:max-line-length */}
        {premium.available &&
          (leistungsBeschreibung.overridePremium ||
            FeePage.renderLeistungseinschluss(
              leistungsBeschreibung.leistungsEinschlussPremium
            ))}
      </tr>
    );
  }

  private renderModalHeader(tariffOption: TariffOption) {
    if (!tariffOption.available) {
      return <></>;
    }
    return <th>{this.renderTariffIcon(tariffOption)}</th>;
  }

  private renderTariffIcon(tariffOption: TariffOption) {
    const tariffName = this.props.valueRanges.tariffType.possibleValues.find(element => element.key.toLocaleLowerCase() === tariffOption.variant
    );

    return (
      <div style={{ marginBottom: '2em' }}>
        <div>
          <VariantIcon
            variant={tariffOption.variant}
            height={ICON_WIDTH_HEADER}
            width={ICON_WIDTH_HEADER}
            style={!tariffOption.selectable ? { fill: 'lightgray' } : {}}
          />
        </div>
        <strong>
          {tariffName ? tariffName.text : toReadableWord(tariffOption.variant)}
        </strong>
      </div>
    );
  }

  private renderModalSelection(tariffOption: TariffOption) {
    if (!tariffOption.available) {
      return <></>;
    }

    return (
      <td>
        <span style={{ fontSize: '1.2em' }}>
          <Price
            value={tariffOption.price!}
            unstyled={this.isEinmalBeitrag()}
          />
        </span>
        <br />
        <Button
          width="auto"
          variant="primary"
          data-component-id={`select-${tariffOption.variant}-modal-Button`}
          disabled={!tariffOption.selectable}
          onClick={() => this.selectVarianteDialog(tariffOption.variant)}
          size="normal"
        >
          auswählen
        </Button>
      </td>
    );
  }

  private getTariffingInformation(): TariffingInformation {
    return {
      basis: this.getTariffOption(Beitragsvariante.BASIS),
      comfort: this.getTariffOption(Beitragsvariante.KOMFORT),
      premium: this.getTariffOption(Beitragsvariante.PREMIUM)
    };
  }

  private getTariffOption(variant: Beitragsvariante): TariffOption {
    const double = 2;
    const beitragsvariante: Variante | undefined = this.state.variants.find(element => element.variante === variant
    );
    const selectable = beitragsvariante ? beitragsvariante.selectable : false;
    const variableBonusEarning = beitragsvariante
      ? beitragsvariante.variablerGewinnanteil
      : undefined;
    const buildupTime = beitragsvariante
      ? beitragsvariante.aufbauzeit
      : undefined;
    let insuranceSum = beitragsvariante
      ? beitragsvariante.versicherungssumme
      : undefined;
    let price = beitragsvariante
      ? beitragsvariante.bruttobeitragNachZahlweise
      : undefined;
    insuranceSum = insuranceSum ? insuranceSum : 0;

    const insuranceSumDeathByAccident =
      variant === Beitragsvariante.BASIS ? insuranceSum : double * insuranceSum;
    if (this.state.priceLoading) {
      price = undefined;
    }

    return {
      available: beitragsvariante !== undefined,
      insuranceSum,
      price,
      selectable,
      variant,
      insuranceSumDeathByAccident,
      variableBonusEarning,
      buildupTime
    };
  }
  private renderTariffDetails() {
    return (
      <>
        <Headline>Wählen Sie Ihren passenden Schutz</Headline>
        <div className="esc_grid" style={{ marginBottom: '0.8em' }}>
          <div
            className="esc_grid__wrapper"
            style={{
              textAlign: 'center',
              lineHeight: '30px',
              justifyContent: 'center'
            }}
          >
            <div
              className="esc_col esc_col-12 esc_col-m-10"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div>
                {this.props.storeState.calculationMode ===
                  CalculationMode.INSURANCE_SUM &&
                  'Garantierte Versicherungssumme:'}
                {this.props.storeState.calculationMode ===
                  CalculationMode.INSURANCE_FEE && 'Ihr Beitrag:'}
              </div>
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                {this.props.storeState.calculationMode ===
                  CalculationMode.INSURANCE_SUM && (
                    <>
                      <span
                        style={DETAILS_KEYWORD_PRICE_STYLING}
                        data-component-id="insurance-sum"
                      >
                        {formatter(EURO_WITHOUT_CENT).format(
                          this.props.storeState.insuranceSum!
                        )}
                      </span>
                    </>
                  )}
                {this.props.storeState.calculationMode ===
                  CalculationMode.INSURANCE_FEE && (
                    <>
                      <span
                        style={DETAILS_KEYWORD_PRICE_STYLING}
                        data-component-id="insurance-fee"
                      >
                        {formatter(EURO_WITHOUT_CENT).format(
                          this.props.storeState.insuranceFee!
                        )}
                      </span>
                    </>
                  )}
              </div>
              <div className="feepage-paymentmethod">
                {' '}
                bei {this.getPaymentMethodText()}{' '}
                {!this.isEinmalBeitrag() && (
                  <>
                    Beitragszahlung und einer Beitragszahlungsdauer von{' '}
                    <span
                      style={DETAILS_KEYWORD_STYLING}
                      data-component-id="payment-period"
                    >
                      {this.props.storeState.paymentPeriod}&nbsp;Jahren{' '}
                    </span>
                  </>
                )}
                {this.isEinmalBeitrag() && <> Zahlung</>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  private getPaymentMethodText(): JSX.Element {
    if (this.isEinmalBeitrag()) {
      return (
        <span
          data-component-id="payment-einmalig"
          style={DETAILS_KEYWORD_STYLING}
        >
          einmaliger
        </span>
      );
    } else {
      const paymentMethod =
        this.props.valueRanges.paymentMethod.possibleValues.find(method => method.key === this.props.storeState.paymentMethod
        );
      if (paymentMethod) {
        return (
          <span
            data-component-id="payment-method"
            style={DETAILS_KEYWORD_STYLING}
          >
            {paymentMethod.text}er
          </span>
        );
      }
    }
    return <></>;
  }
  private formatPrice(price: number | undefined, valid = true) {
    if (price === undefined || !valid) {
      return '-,--';
    }
    return formatter(EURO_WITH_CENT).format(price);
  }
  private renderVariant(tariffOption: TariffOption, index: number) {
    if (!tariffOption.available) {
      return <></>;
    }

    const buildupTime = tariffOption.buildupTime
      ? tariffOption.buildupTime
      : '--';
    let totalSum = tariffOption.insuranceSum;
    if (tariffOption.variableBonusEarning) {
      totalSum += tariffOption.variableBonusEarning;
    }
    const formattedInsuranceSum = this.formatPrice(tariffOption.insuranceSum);
    const formattedTotalSum = this.formatPrice(
      totalSum,
      tariffOption.selectable
    );
    const formattedIInsuranceSumDeathByAccident = this.formatPrice(
      tariffOption.insuranceSumDeathByAccident
    );
    const paymentEndDate = addYears(
      new Date(this.props.storeState.insuranceBegin),
      this.props.storeState.paymentPeriod
    );
    return (
      <RadioTab
        value={index + ''}
        title={FeePage.formatTariffDisplayName(tariffOption)}
        contentClassName="feepage-variant"
      >
        <div
          className={
            'feepage-content' +
            (!tariffOption.selectable ? ' feepage-disableed' : '')
          }
        >
          <div className="feepage-price">
            {this.state.priceLoading && (
              <LoadingSpinner viewport="relative" show={true} />
            )}
            Sie zahlen &nbsp; &nbsp;
            <Price
              value={tariffOption.price!}
              style={tariffOption.selectable ? {} : GREY_OUT}
            />
            &nbsp; &nbsp;{' '}
            {mapPaymentMethodToText(this.props.storeState.paymentMethod)}
          </div>
          {!tariffOption.selectable && (
            <div className="feepage-notselectable">
              <b>
                Leider ist diese Konstellation mit Ihren Angaben nicht wählbar.
              </b>
            </div>
          )}
          <table className="feepage-checklist">
            <tbody>
              <tr>
                <td>
                  <CheckColorIcon />
                </td>
                <td>
                  Garantierte Todesfallleistung: <b>{formattedInsuranceSum}</b>
                </td>
                <td>
                  <TooltipIcon
                    onToggledOpen={isOpened => {
                      if (isOpened) {
                        trackElementClicked(
                          TrackingElement.InfoIcon_Todesfallleistung,
                          false
                        );
                      }
                    }}
                  >
                    Diese Summe erhalten Ihre Hinterbliebenen garantiert. Die
                    tatsächliche Auszahlung kann höher sein, siehe
                    Gesamtleistung inkl. Überschussbeteiligung.
                  </TooltipIcon>
                </td>
              </tr>
              <tr>
                <td>
                  <CheckColorIcon />
                </td>
                <td>
                  Gesamtleistung inkl. Überschussbeteiligung:{' '}
                  <b>{formattedTotalSum}</b>
                </td>
                <td>
                  <TooltipIcon
                    onToggledOpen={isOpened => {
                      if (isOpened) {
                        trackElementClicked(
                          TrackingElement.InfoIcon_Gesamtleistung,
                          false
                        );
                      }
                    }}
                  >
                    Die darin enthaltenen Überschussanteile sind nicht
                    garantiert. Vielmehr sind sie ein unverbindliches Beispiel,
                    kalkuliert für eine Auszahlung im Alter von 90 Jahren. Sie
                    gelten nur, wenn die aktuell erklärten Überschussanteilsätze
                    unverändert bleiben.
                  </TooltipIcon>
                </td>
              </tr>
              <tr>
                <td>
                  <CheckColorIcon />
                </td>
                <td>
                  Aufbauzeit ab Vertragsbeginn: <b>{buildupTime} Monate</b>
                </td>
                <td>
                  <TooltipIcon
                    onToggledOpen={isOpened => {
                      if (isOpened) {
                        trackElementClicked(
                          TrackingElement.InfoIcon_Wartezeit,
                          false
                        );
                      }
                    }}
                  >
                    Die Angehörigen erhalten die vereinbarte Todesfallleistung,
                    wenn die versicherte Person nach dieser Aufbauzeit stirbt.
                    Die Aufbauzeit beginnt mit dem Vertragsbeginn. Bei Tod
                    während der Aufbauzeit bleiben die gezahlten Beiträge
                    erhalten. Bei Unfalltod entfällt die Aufbauzeit.
                  </TooltipIcon>
                </td>
              </tr>
              {tariffOption.variant === Beitragsvariante.PREMIUM && (
                <tr>
                  <td>
                    <CheckColorIcon />
                  </td>
                  <td>
                    Leistung bei Unfalltod (ohne Aufbauzeit):{' '}
                    <b>{formattedIInsuranceSumDeathByAccident}</b>
                  </td>
                  <td></td>
                </tr>
              )}
              {tariffOption.variant === Beitragsvariante.PREMIUM && (
                <tr>
                  <td>
                    <CheckColorIcon />
                  </td>
                  <td>
                    Kapitalleistung bei Pflegebedürftigkeit:{' '}
                    <b>Aufbauzeit 18 Monate</b>
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
          <p style={{ color: '#737373', marginBottom: '30px' }}>
            <b>
              Sie zahlen Beiträge bis {format(paymentEndDate, 'dd.MM.yyyy')}.
              Danach sind Sie lebenslang versichert.
            </b>
          </p>
          {tariffOption.selectable && (
            <Button
              width="auto"
              variant="text-link"
              disabled={this.state.priceLoading}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                trackElementClickImmediate(
                  TrackingElement.Link_VarLeistungenAnzeigen
                );
                this.setState({ showDialog: true });
              }}
              data-component-id={`open-modal-dialog`}
            >
              <div style={{ fontSize: '16px' }}>
                <b>Alle Leistungen anzeigen</b>
              </div>
            </Button>
          )}
          {!tariffOption.selectable && (
            <>
              <div className={'feepage-tip'}>
                <strong>Tipp:</strong>
                <p>
                  Entscheiden Sie sich für die vorbelegte oder eine kürzere
                  Zahlungsdauer. Dann können Sie zwischen allen 3 Varianten
                  wählen: Grundschutz, Komfort oder Premium. Oder lassen Sie
                  sich persönlich beraten.
                </p>
              </div>
            </>
          )}
        </div>
      </RadioTab>
    );
  }

  private readonly proceed = async (variante: Beitragsvariante) => {
    this.props.storeState.update({ variante }, () => {
      trackElementClicked(trackingElementVariant[variante]);
      this.props.handleAction(NavigationAction.NEXT);
    });
  };

  private readonly selectVarianteDialog = async (
    variante: Beitragsvariante
  ) => {
    this.props.storeState.update({ variante }, () => {
      trackElementClicked(trackingElementVariantLA[variante]);
      this.props.handleAction(NavigationAction.NEXT);
    });
  };
}

export default FeePage;
