import React from 'react';
import { Button } from '@eg/elements/Button';
import { Modal } from '@eg/elements/components/Modal';
import { getIsMakler, getIsTiedAgent, getOeNumber } from '../helpers/modeConfig';
import { AgentContactMethods } from './AgentContactMethods';
import './ErrorModal.css';

export interface ErrorModalProps {
  businessId: string;
  open: boolean;
  showContactFormBtn: boolean;
  onClick: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  businessId,
  open,
  showContactFormBtn,
  onClick
}) => {
  const isMakler = getIsMakler();
  const isTiedAgent = getIsTiedAgent();
  return (
    <Modal open={open} data-component-id="error-modal">
      <b data-component-id="error-modal-header">
        Ups, da ist wohl etwas schief gegangen!
      </b>
      <br />
      Ihre Aktion konnte nicht ausgeführt werden. Bitte stellen Sie sicher,
      dass eine Internetverbindung besteht. Versuchen Sie es dann noch
      einmal oder nutzen Sie unseren telefonischen Kontakt.
      <br />
      <br />
      {(isMakler || isTiedAgent) && (
        <div data-component-id={isMakler ? 'error-modal-makler' : 'error-modal-tied-agent'}>
          <AgentContactMethods />
          <br />
        </div>
      )}
      <Button
        onClick={onClick}
        data-component-id="error-modal-retry-button"
        size="large"
      >
        Erneut versuchen
      </Button>
      {isTiedAgent && showContactFormBtn && (
          <Button className="error-modal-secondary-button" variant="primary">
            <a
              className="error-modal-anchor"
              data-component-id="error-modal-anchor-button"
              href={`https://www.ergo.de/de/Resources/Forms/Kontakt/Kontakt?adh_oenr=${getOeNumber()}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              Kontaktformular
            </a>
          </Button>
        )}
      <div className="error-modal-business-id">{businessId}</div>
    </Modal>
  );
};
