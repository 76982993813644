import React from 'react';
import { Button } from '@eg/elements/Button';
import { CheckIllustratedIcon } from '@eg/elements/components/Icons';
import { Form as FormRaw, Formik } from 'formik';
import { InsuranceFeeSlider } from '../components/calculationComponents/InsuranceFeeSlider';
import { PaymentMethodSelection } from '../components/calculationComponents/PaymentMethodSelection';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { PAYMENT_METHOD_SINGLE_FEE } from '../constants';
import { mapPaymentMethodToAdjective } from '../helpers/paymentHelper';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createInsuranceFeePageSchema } from '../validation/InsuranceFeePage';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

export interface InsuranceFeePageData extends StoreStateUpdater<InsuranceFeePageData> {
    insuranceFee: number;
    paymentMethod: string;
}

interface InsuranceFeePageProps extends PagePropsWithValues<InsuranceFeePageData> {
}

interface InsuranceFeeState {
    paymentMethod: string;
}

class InsuranceFeePage extends React.Component<InsuranceFeePageProps, InsuranceFeeState> {

    constructor(props: Readonly<InsuranceFeePageProps>) {
        super(props);
        this.state = {
            paymentMethod: this.props.storeState.paymentMethod
        };
    }

    public componentDidMount() {
        scrollToTop();
    }

    private validateBeforeSubmit(values: InsuranceFeePageData): InsuranceFeePageData {
        return {
            ...this.props.storeState,
            ...createInsuranceFeePageSchema(this.props.valueRanges).cast(values)
        } as InsuranceFeePageData;
    }

    private getLastParagraph(): string {
        return this.props.storeState.paymentMethod === PAYMENT_METHOD_SINGLE_FEE ? 'Bei einer Einmalzahlung entfällt die Aufbauzeit.' : 'Die volle Versicherungssumme ist gleich nach der Aufbauzeit verfügbar. Die Aufbauzeit läuft ab dem Vertragsbeginn.';
    }

    public render() {
        const ICON_WIDTH = 32;
        return (<>
                <Headline>
                    Wie viel möchten Sie für die Absicherung zahlen?
                </Headline>

                <Formik
                    initialValues={this.props.storeState}
                    onSubmit={values => {
                        const validatedValues = this.validateBeforeSubmit(values);
                        this.props.storeState.update(validatedValues);
                        this.props.handleAction(NavigationAction.NEXT);
                    }}
                    validationSchema={createInsuranceFeePageSchema(this.props.valueRanges)}
                >
                    {form => (
                        <Form>
                            <div style={{maxWidth: '400px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', padding: '10px'}}>
                                <InsuranceFeeSlider
                                    form={form}
                                    valueRange={this.props.valueRanges.insuranceFee}
                                    updateState={this.props.storeState.update}/>
                            </div>
                            {PaymentMethodSelection(this.props)}
                            <Footer handleAction={this.props.handleAction} onNextClick={() => {
                                return;
                            }} disableOfferNavigation={true}/>
                        </Form>
                    )}
                </Formik>
                <div style={{textAlign: 'center', marginBottom: '2em'}}>
                    <Button variant="text-link"
                            style={{margin: '2em auto 2em auto', display: 'flex', fontWeight: 'bold'}}
                            onClick={() => {
                                trackElementClicked(TrackingElement.Link_VersSumFestlegen);
                                this.props.handleAction(NavigationAction.INSURANCE_SUM);
                            }}
                            data-component-id="insurance-sum-button">
                        Ich möchte die Absicherung über die Versicherungssumme berechnen.
                    </Button>
                </div>
                <div className="esc_grid" style={{maxWidth: '500px', margin: '0 auto'}}>
                    <div className="esc_grid__wrapper" style={{textAlign: 'left'}}>
                        <div className="esc_col esc_col-2 esc_col-m-1">
                            <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                        </div>
                        <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                            Aus dem {mapPaymentMethodToAdjective(this.props.storeState.paymentMethod)}en Beitrag wird die Summe berechnet,
                            die die Angehörigen später erhalten.
                        </div>
                        <div className="esc_col esc_col-2 esc_col-m-1">
                            <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                        </div>
                        <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                            Nur bei Premium: Bei einer schweren Krankheit bekommt
                            die versicherte Person das Geld auf Wunsch direkt.
                        </div>
                        <div className="esc_col esc_col-2 esc_col-m-1">
                            <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/>
                        </div>
                        <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                        {this.getLastParagraph()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default InsuranceFeePage;
