import React from 'react';
import { CheckIllustratedIcon } from '@eg/elements/components/Icons';
import { Form as FormRaw, Formik } from 'formik';
import { InsuranceSumSlider } from '../components/calculationComponents/InsuranceSumSlider';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createInsuranceSumPageSchema } from '../validation/InsuranceSumPage';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

export interface InsuranceSumPageData extends StoreStateUpdater<InsuranceSumPageData> {
    insuranceFee: number;
    insuranceSum: number;
    paymentMethod: string;
    paymentPeriod: number;
}

function InsuranceSumPage(props: PagePropsWithValues<InsuranceSumPageData>) {
    React.useEffect(() => {
        scrollToTop();
    }, []);

    const ICON_WIDTH = 32;
    return (<>
            <Headline tooltip={<>
                <b>Tipp:</b> Mit diesen Kosten müssen Sie ungefähr rechnen:
                <ul>
                    <li>Erdbestattung: 8.000 €</li>
                    <li>Feuerbestattung: 6.000 €</li>
                    <li>Seebestattung: 4.000 €</li>
                    <li>Waldbestattung: 4.000 €</li>
                </ul>
            </>}>
                Wie viel Geld soll für die Beerdigung verfügbar sein?
            </Headline>
            <Formik
                initialValues={{
                    insuranceSum: props.storeState.insuranceSum
                }}
                onSubmit={values => {
                    const validatedValues = {
                        ...props.storeState,
                        ...createInsuranceSumPageSchema(props.valueRanges).cast(values)
                    };
                    props.storeState.update(validatedValues);
                    props.handleAction(NavigationAction.NEXT);
                }}
                validationSchema={createInsuranceSumPageSchema(props.valueRanges)}>
                {form => (
                    <Form>
                        <div style={{maxWidth: '400px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto', padding: '10px'}}>
                            <InsuranceSumSlider
                                form={form}
                                valueRange={props.valueRanges.insuranceSum}/>
                        </div>
                        <br/>

                        <Footer handleAction={props.handleAction} disableOfferNavigation={true}/>
                        <br/>
                    </Form>
                )}
            </Formik>
            <div className="esc_grid" style={{maxWidth: '500px', margin: '0 auto'}}>
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-2 esc_col-m-1">
                        <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/></div>
                    <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                        Diese Summe steht für die Bestattung zur Verfügung. Ihre Hinterbliebenen werden so entlastet.
                    </div>
                    <div className="esc_col esc_col-2 esc_col-m-1">
                        <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/></div>
                    <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                        Nur bei Premium: Bei einer schweren Krankheit bekommt die versicherte Person das Geld auf Wunsch direkt.
                    </div>
                    <div className="esc_col esc_col-2 esc_col-m-1">
                        <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} style={{margin: 'auto'}}/></div>
                    <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                        Nur bei Premium: Versicherungssummen auch über 15.000 € möglich.
                    </div>
                </div>
            </div>
        </>
    );
}

export default InsuranceSumPage;
