import React from 'react';
import { CalculationMode, IsoDateString, mapToGermanDate, parseDate } from 'stg-common';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import RadioButton from '../components/RadioButton/RadioButton';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { isOlderThanInYear, isYoungerThanInYear } from '../validation/date';
import './InsuranceBeginPage.css';

export interface InsuranceBeginPageData extends StoreStateUpdater<InsuranceBeginPageData> {
    birthdate: IsoDateString;
    businessId: string;
    calculationMode: CalculationMode;
    insuranceBegin: IsoDateString;
}

interface InsuranceBeginPageProps extends PagePropsWithValues<InsuranceBeginPageData> {
}

class InsuranceBeginPage extends React.Component<InsuranceBeginPageProps> {

    public componentDidMount() {
        scrollToTop();
    }

    public render() {
        return (<>
            <Headline>
                Wann soll die Versicherung beginnen?
            </Headline>
            <div className="insurancebegin-date-selection">
                {this.renderInsuranceSelection()}
            </div>
            <Footer
                disableOfferNavigation={true}
                handleAction={this.props.handleAction}
                onNextClick={() => { this.props.handleAction(NavigationAction.NEXT); }}
            />
        </>);
    }

    private renderInsuranceSelection(): JSX.Element {
        const insuranceSelection: JSX.Element[] = [];
        let previousElementNotSelectable: boolean | undefined;
        let restrictionMessage: string | undefined;

        this.props.valueRanges.insuranceStart.possibleValues.forEach((insuranceBegin: IsoDateString, index: number) => {
            const isNotSelectable = !!this.getRestrictionMessage(insuranceBegin);
            restrictionMessage = restrictionMessage || this.getRestrictionMessage(insuranceBegin);
            if (previousElementNotSelectable !== undefined) {
                if (previousElementNotSelectable !== isNotSelectable) {
                    insuranceSelection.push(React.createElement('div', {
                        'data-component-id': 'restrictionMessage',
                        className: 'insurancebegin-restriction-message'
                    }, restrictionMessage));
                } else {
                    insuranceSelection.push(React.createElement('div', {className: 'insurancebegin-restriction-spacer'}));
                }
            }
            const checked = this.props.storeState.insuranceBegin === insuranceBegin;
            insuranceSelection.push(
                <RadioButton
                    key={index}
                    label={mapToGermanDate(insuranceBegin) as string}
                    checked={checked}
                    value={mapToGermanDate(insuranceBegin) as string}
                    disabled={isNotSelectable}
                    onClick={
                        async () => {
                            if (!isNotSelectable) {
                                this.props.storeState.update({insuranceBegin});
                            }
                        }
                    }
                    componentId={`insuranceBegin-${index}`}
                />
                );

            previousElementNotSelectable = isNotSelectable;
        });
        return React.createElement('div', {style: {maxWidt: '80%'}}, ...insuranceSelection);
    }

    private getRestrictionMessage(insuranceStart: IsoDateString): string | undefined {
        const insuranceBegin = parseDate(insuranceStart);
        if (insuranceBegin) {
            const LAST_DAY_OF_DECEMBER = 31;
            const MONTH_OF_DECEMBER = 11;
            insuranceBegin.setDate(LAST_DAY_OF_DECEMBER);
            insuranceBegin.setMonth(MONTH_OF_DECEMBER);
            const birthday = this.props.storeState.birthdate;
            const ageAtEntry = this.props.valueRanges.ageAtEntry;

            const isTooYoung = !isOlderThanInYear(insuranceBegin, birthday, ageAtEntry.min);
            if (isTooYoung) {
                return `Das Mindestalter zu Ende des ersten Versicherungsjahres ist ${ageAtEntry.min} Jahre.`;
            }

            const FIRST_DAY_OF_JANUARY = 1;
            const MONTH_OF_JANUARY = 0;
            insuranceBegin.setDate(FIRST_DAY_OF_JANUARY);
            insuranceBegin.setMonth(MONTH_OF_JANUARY);
            const isTooOld = !isYoungerThanInYear(insuranceBegin, birthday, ageAtEntry.max + 1);
            if (isTooOld) {
                return `Das Höchstalter zu Beginn des ersten Versicherungsjahres ist ${ageAtEntry.max} Jahre.`;
            }
        }
        return undefined;
    }
}

export default InsuranceBeginPage;
