import FeeDynamicPage, { FeeDynamicPageData } from '../../pages/FeeDynamicPage';
import { getOfferPageData, updateDivergingInsuredPersonPageData, updateFeeDynamicPageData } from '../../services/api';
import { mapBasketFields, mapBasketFieldsFormResponse, writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, StateName } from '../StateMachineTypes';

export const feeDynamicPage: StateDefinition<FeeDynamicPageData> = {
    name: StateName.FEE_DYNAMIC_PAGE,

    validInboundStates: [
        ...PERSONAL_INFORMATION_PAGES,
        ...CHECKOUT_PAGES,
        StateName.FEE_PAGE,
        StateName.FLEX_OPTION_PAGE,
        StateName.INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BURIAL_PACKAGE,
            newState: StateName.BURIAL_PACKAGE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FLEX_OPTION,
            newState: StateName.FLEX_OPTION_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'feeDynamic');

        return {
            userInput: {
                ...mapBasketFieldsFormResponse(response),
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        await updateFeeDynamicPageData(inputData.businessId, {
            feeDynamic: inputData.userInput.feeDynamic
        });
        return {payload: await updateDivergingInsuredPersonPageData(inputData.businessId, {
            divergingInsuredPerson: false
        })};
    },
    render: (inputData, handleAction, updateApp) => {
        return <FeeDynamicPage
            storeState={{
                ...mapBasketFields(inputData.userInput),
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
