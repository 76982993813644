import { Beitragsvariante } from 'stg-common';

export enum TrackingElement {
    Empty = '',
    Link_Zurueck = 'Link_Zurueck',
    Button_Weiter = 'Button_Weiter',
    // Beitrags-Seite
    Link_BeitragFestlegen = 'Link_BeitragFestlegen',
    // Versicherungssummen-Seite
    Link_VersSumFestlegen = 'Link_VersSumFestlegen',
    // Feepage
    Button_Grundschutz = 'RButton_Grundschutz',
    Button_Komfort = 'RButton_Komfort',
    Button_Premium = 'RButton_Premium',
    InfoIcon_Gesamtleistung = 'InfoIcon_Gesamtleistung',
    InfoIcon_Todesfallleistung = 'InfoIcon_Todesfallleistung',
    InfoIcon_Wartezeit = 'InfoIcon_Wartezeit',
    Button_TarifdatenAendern = 'Button_TarifdatenAendern',
    Button_NeuBerechnen = 'Button_NeuBerechnen',
    Button_BasisAuswaehlen = 'Button_BasisAuswaehlen',
    Button_KomfortAuswaehlen = 'Button_KomfortAuswaehlen',
    Button_PremiumAuswaehlen = 'Button_PremiumAuswaehlen',
    Button_BasisAuswaehlenLA = 'Button_BasisAuswaehlenLA',
    Button_KomfortAuswaehlenLA = 'Button_KomfortAuswaehlenLA',
    Button_PremiumAuswaehlenLA = 'Button_PremiumAuswaehlenLA',
    Link_VarLeistungenAnzeigen = 'Link_VarLeistungenAnzeigen',
    Button_SchliessenX = 'Button_SchliessenX',
    Button_Schliessen = 'Button_Schliessen',
    Button_Abbrechen = 'Button_Abbrechen',
    Link_IDDBeratung = 'Link_IDDBeratung',
    // Basket
    Icon_TarifvarianteAendern = 'Icon_TarifvarianteAendern',
    Icon_BestattungAendern = 'Icon_BestattungAendern',
    Icon_FlexOptionAendern = 'Icon_FlexOptionAendern',
    Icon_DynamikAendern = 'Icon_DynamikAendern',
    Link_MehrDetails = 'Link_MehrDetails',
    Link_WenigerDetails = 'Link_WenigerDetails',
    // Bestattungspaket-Seite
    Link_BestattungLaAuswaehlen = 'Link_BestattungLaAuswaehlen',
    Link_BestattKlassAuswaehlen = 'Link_BestattKlassAuswaehlen',
    Link_BestattTradAuswaehlen = 'Link_BestattTradAuswaehlen\t',
    Link_BestattExklAuswaehlen = 'Link_BestattExklAuswaehlen',
    Link_WeiterOhneBestatt = 'Link_WeiterOhneBestatt',
    // Footer
    Link_AngebotAnfordern = 'Link_AngebotAnfordern',
    // Flexoption-Seite
    Link_FlexAuswaehlen = 'Link_FlexAuswaehlen',
    Link_WeiterOhneFlex = 'Link_WeiterOhneFlex',
    // Beitragsdynamik-Seite
    Link_DynamikAuswaehlen = 'Link_DynamikAuswaehlen',
    Link_WeiterOhneDynamik = 'Link_WeiterOhneDynamik',
    // Bezugsrechts-Seite
    Checkbox_BezugsrechtAendern = 'Checkbox_BezugsrechtAendern',
    Button_BezugNeuePersonAnlegen = 'Button_BezugNeuePersonAnlegen',
    Button_BezugPersonSpeichern = 'Button_BezugPersonSpeichern',
    Button_BezugPersonLoeschen = 'Button_BezugPersonLoeschen',
    Button_BezugPersonBearbeiten = 'Button_BezugPersonBearbeiten',
    Link_DatenVersicherungsnehmer = 'Link_DatenVersicherungsnehmer',
    Link_AnschriftUebernahme = 'Link_AnschriftUebernahme',
    Link_BezugBearbeitenAbbrechen = 'Link_BezugBearbeitenAbbrechen',
    Link_BezugsRGleichAufteilen = 'Link_BezugsRGleichAufteilen',
    // Summarypage
    Link_PersonendatenAendern = 'Link_PersonendatenAendern',
    Link_BezugsrechtAendern = 'Link_BezugsrechtAendern',
    Link_StartdatenAendern = 'Link_StartdatenAendern',
    Link_BankverbindungAendern = 'Link_BankverbindungAendern',
    // IDD
    Checkbox_Beratungsverzicht = 'Checkbox_Beratungsverzicht',
    Button_IDDDownload = 'Button_IDDDownload',
    Button_OnlineAbschliessen = 'Button_OnlineAbschliessen',
    Button_AntragAnfordern = 'Button_AntragAnfordern',
    // Step-Navigation
    OTRNavi_Tarifdaten = 'OTRNavi_Tarifdaten',
    OTRNavi_Beitrag = 'OTRNavi_Beitrag',
    OTRNavi_Persoenliches = 'OTRNavi_Persoenliches',
    // Requestoffer
    Button_AngebotPerPost = 'Button_AngebotPerPost',
    Button_AngebotPerEmail = 'Button_AngebotPerEmail',
    Button_AngebotPDFHerunterladen = 'Button_AngebotPDFHerunterladen',
    // Errormodal
    Button_ErneutVersuchenFehlerMeldung = 'Button_ErneutVersuchenFehlerMeldung'
}

export const trackingElementVariant = {
    [Beitragsvariante.BASIS]: TrackingElement.Button_BasisAuswaehlen,
    [Beitragsvariante.KOMFORT]: TrackingElement.Button_KomfortAuswaehlen,
    [Beitragsvariante.PREMIUM]: TrackingElement.Button_PremiumAuswaehlen
};

export const trackingElementVariantLA = {
    [Beitragsvariante.BASIS]: TrackingElement.Button_BasisAuswaehlenLA,
    [Beitragsvariante.KOMFORT]: TrackingElement.Button_KomfortAuswaehlenLA,
    [Beitragsvariante.PREMIUM]: TrackingElement.Button_PremiumAuswaehlenLA
};

export const trackingElementVariantSelection = {
    [Beitragsvariante.BASIS]: TrackingElement.Button_Grundschutz,
    [Beitragsvariante.KOMFORT]: TrackingElement.Button_Komfort,
    [Beitragsvariante.PREMIUM]: TrackingElement.Button_Premium
};

export enum TrackingErrorType {
    Sonstige = 'Sonstige',
    Boni = 'Boni'
}
