import { FC, useEffect } from 'react';
import { NextButton } from '../components/Footer/NextButton';
import { Headline } from '../components/Headline';
import { getAgentEmail, getAgentPhone } from '../helpers/modeConfig';
import { scrollToTop } from '../helpers/scrolling';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import './css/IntroductionPage.css';

interface IntroductionPageProps {
  handleAction: HandleActionCallback;
}

const IntroductionPage: FC<IntroductionPageProps> = ({handleAction}) => {
  useEffect(() => {
      scrollToTop();
  }, []);

  return (
    <>
      <Headline>Sie wünschen eine Beratung?</Headline>
      <div>
        Seit Februar 2018 gilt die reformierte EU-Richtlinie für den Versicherungsbetrieb.
        Seitdem muss auch im Direktvertrieb eine Beratung und deren Dokumentation erfolgen,
        bevor ein Vertrag abgeschlossen wird.
      </div>
      <h4>
        Kontaktieren Sie für eine Beratung Ihren Ansprechpartner:<br/>
        <ul>
           <li>telefonisch: {getAgentPhone()}</li>
           <li>per E-Mail: {getAgentEmail()}</li>
        </ul>
      </h4>

      <div className="introduction_center">
        <NextButton onNextClick={() => handleAction(NavigationAction.NEXT)} />
      </div>
    </>
  );
};

export default IntroductionPage;
