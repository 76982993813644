import React from 'react';
import { Card } from '@eg/elements/Card';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { Radio } from '@eg/elements/Radio';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import '../helpers/NoLabelRadioGroupRow.css';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

const MYSELF = 'MYSELF';
const DIFFERENT_PERSON = 'DIFFERENT_PERSON';

export interface DivergingInsuredPersonPageData extends StoreStateUpdater<DivergingInsuredPersonPageData> {
    divergingInsuredPerson: boolean;
    paymentMethod: string;
}

interface DivergingInsuredPersonPageProps extends PagePropsWithValues<DivergingInsuredPersonPageData> {
    businessId: string;
    isOfferPage?: boolean;
    onError: (e: Error) => void;
}

interface DivergingInsuredPersonPageState {
    insuredPerson: string;
    isLoading: boolean;
}

class DivergingInsuredPersonPage extends React.Component<DivergingInsuredPersonPageProps, DivergingInsuredPersonPageState> {

    constructor(props: Readonly<DivergingInsuredPersonPageProps>) {
        super(props);
        this.state = {
            isLoading: false,
            insuredPerson: props.storeState.divergingInsuredPerson ? DIFFERENT_PERSON : MYSELF
        };
    }

    public async componentDidMount() {
        scrollToTop();
    }

    public render() {
        return (
            <div className="diverging-insured-person-page" data-component-id="DivergingInsuredPersonPage" style={{textAlign: 'center'}}>
                <LoadingSpinner show={this.state.isLoading}/>
                <Headline>Wer soll versichert werden?</Headline>
                <RadioGroupRow className="NoLabelRadioGroupRow"
                               style={{
                                   display: 'flex',
                                   justifyContent: 'center'
                               }}
                               label=""
                               name="insuredPerson"
                               value={this.state.insuredPerson}
                               defaultValue={this.state.insuredPerson}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   this.setState({insuredPerson: event.target.value});
                                   this.props.storeState.update({divergingInsuredPerson: event.target.value === DIFFERENT_PERSON});
                               }}>
                    <Radio value={MYSELF} label="Ich" data-component-id="me-radio"/>
                    <Radio value={DIFFERENT_PERSON} label="Jemand anders" data-component-id="someone-else-radio"/>
                </RadioGroupRow>
                <br/>
                {this.props.storeState.divergingInsuredPerson && !this.props.isOfferPage &&
                <Card accentColor="#D2054D" label="Achtung!">
                    Soll eine andere Person versichert werden, erstellen wir Ihnen gern ein individuelles Angebot. Sie
                    können die Versicherung nicht stellvertretend abschließen.
                </Card>
                }
                <br/>
                <Footer
                    disableOfferNavigation={this.props.isOfferPage}
                    hideNextButton={this.props.storeState.divergingInsuredPerson && !this.props.isOfferPage}
                    handleAction={this.props.handleAction}
                    onNextClick={() => this.props.handleAction(NavigationAction.NEXT)}
                />
                <DisclaimerInfoBox/>
            </div>
        );
    }

}

export default DivergingInsuredPersonPage;
