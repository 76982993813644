import { FC } from 'react';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

interface BackButtonProps {
    handleAction: HandleActionCallback;
}

export const BackButton: FC<BackButtonProps> = ({
    handleAction
}): JSX.Element => (
    <div className="esc_text-link"
        style={{
            display: 'block', margin: '1.25em auto 0 auto',
            textAlign: 'center',
            fontSize: '0.85em',
            color: '#737373',
            fontWeight: 'bold',
            cursor: 'pointer'
        }}
        data-component-id={'back-button'}
        onClick={() => {
            trackElementClicked(TrackingElement.Link_Zurueck);
            handleAction(NavigationAction.BACK);
        }}
    >
        Zurück
    </div>
);
