import { BurialPackageIdentifier, InsuranceSettingKey } from './ApiRequests';
import { IsoDateString } from './IsoDateString';
import { Beitragsvariante } from './Variante';

export const MAX_ENTITLED_PERSONS = 3;
export const MAX_SIZE_ENTITELMENT_PERCENTAGE = 100;

export interface ValueRanges {
    ageAtEntry: NumericRange;
    tariffType: ValueList<TextValue>;
    insuranceDuration: NumericRange;
    insuranceSum: NumericRange;
    paymentPeriod: NumericRange;
    insuranceFee: NumericRange;
    insuranceStart: ValueList<IsoDateString>;
    insuranceSettings: InsuranceSettingsOption[];
    paymentMethod: ValueList<TextValue>;
    relationships: ValueList<TextValue>;
    burialPackages: BurialPackage[];
}

export interface ValueList<T> {
    defaultValue: T | undefined;
    possibleValues: T[];
}

export interface TextValue {
    key: string;
    text: string;
}

export interface NumericRange {
    defaultValue: number | undefined;
    min: number;
    max: number;
    increment: number | undefined;
}

export interface InsuranceSettingsOption {
    key: InsuranceSettingKey;
    description: string;
    variantSettings: InsuranceSettingsOptionVariantSetting[];
}

export interface InsuranceSettingsOptionVariantSetting {
    variant: Beitragsvariante;
    available: boolean;
    included: boolean;
}

export interface BurialPackage {
    identifier: BurialPackageIdentifier;
    label: string;
    requiredSumInsured: number;
}
