import BirthdatePage, { BirthdatePageData } from '../../pages/BirthdatePage';
import { getOfferPageData, updateBirthdatePageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, FEE_PAGES, NavigationAction, PERSONAL_INFORMATION_PAGES, REQUEST_OFFER_PAGES, StateName } from '../StateMachineTypes';

export const birthdatePage: StateDefinition<BirthdatePageData> = {
    name: StateName.BIRTHDATE_PAGE,
    validInboundStates: [
        ...CHECKOUT_PAGES,
        ...PERSONAL_INFORMATION_PAGES,
        ...FEE_PAGES,
        ...REQUEST_OFFER_PAGES,
        StateName.INTRODUCTION_PAGE,
        StateName.INSURANCE_START_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_START_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK,
            newState: StateName.INTRODUCTION_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'birthdate');

        return {
            userInput: {
                birthdate: response.birthdate,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updateBirthdatePageData(inputData.businessId, inputData.userInput);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <BirthdatePage
            storeState={{
                birthdate: inputData.userInput.birthdate,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};
