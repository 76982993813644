import Radio from '@eg/elements/Radio';

import './RadioButton.css';

interface RadioButtonProps {
  label: string;
  checked: boolean;
  value: string;
  onClick: () => void;
  componentId: string;
  disabled: boolean;
}

const RadioButton = ({
  label,
  checked,
  value,
  onClick,
  componentId,
  disabled
}: RadioButtonProps) => {
  return (
    <button className={ `radio-button radio-button-${checked}`} onClick={onClick} data-component-id={componentId} data-disabled={disabled} disabled={disabled}>
      <div className="radio-button-inline">
        <Radio value={value}
          checked={checked}
          label={label}
          disabled={disabled}
        />
      </div>
    </button>
  );
};

export default RadioButton;
