import Button from '@eg/elements/Button';
import { ArrowRightIcon } from '@eg/elements/components/Icons';
import { FC } from 'react';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

interface OfferPageButtonsProps {
    showLoadingSpinner?: boolean;
    offerCallback?: (action: NavigationAction) => void;
}

export const OfferPageButtons: FC<OfferPageButtonsProps> = ({
    showLoadingSpinner,
    offerCallback
}): JSX.Element => {
    const onClickPerPost = () => {
        trackElementClicked(TrackingElement.Button_AngebotPerPost);
        if (offerCallback) {
            offerCallback(NavigationAction.OFFER_WRITTEN_POSTAL);
        }
    };

    const onClickPerEmail = () => {
        trackElementClicked(TrackingElement.Button_AngebotPerEmail);
        if (offerCallback) {
            offerCallback(NavigationAction.OFFER_WRITTEN_EMAIL);
        }
    };

    return <>
        <Button
            data-component-id={'offer-email-button'}
            iconLeft={ArrowRightIcon}
            loading={showLoadingSpinner}
            onClick={onClickPerEmail}
            style={{display: 'inline-flex', margin: '1.25em auto 0 auto', fontWeight: 'bold'}}
            type="button"
            variant="text-link"
            width="auto"
        >
            Angebot per E-Mail
        </Button>
        <br/>
        <Button
            data-component-id={'offer-mail-button'}
            iconLeft={ArrowRightIcon}
            loading={showLoadingSpinner}
            onClick={onClickPerPost}
            style={{display: 'inline-flex', margin: '1.25em auto 0 auto', fontWeight: 'bold'}}
            type="button"
            variant="text-link"
            width="auto"
        >
            Angebot per Post
        </Button>
        <br/>
    </>;
};
