import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import DivergingInsuredPersonPage, { DivergingInsuredPersonPageData } from '../../pages/DivergingInsuredPersonPage';
import { getOfferPageData, updateDivergingInsuredPersonPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { CHECKOUT_PAGES, NavigationAction, StateName } from '../StateMachineTypes';

export const divergingInsuredPersonPage: StateDefinition<DivergingInsuredPersonPageData> = {
    name: StateName.DIVERGING_INSURED_PERSON_PAGE,
    validInboundStates: [
        ...CHECKOUT_PAGES,
        StateName.FEE_DYNAMIC_PAGE,
        StateName.POLICY_HOLDER_PAGE,
        StateName.INSURED_PERSON_PAGE,
        StateName.BURIAL_PACKAGE_PAGE,
        StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
    ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT && data.userInput.divergingInsuredPerson,
            newState: StateName.POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.NEXT && !data.userInput.divergingInsuredPerson,
            newState: StateName.INSURED_PERSON_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.BURIAL_PACKAGE_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                && data.userInput.paymentMethod !== PAYMENT_METHOD_SINGLE_FEE,
            newState: StateName.FEE_DYNAMIC_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER && data.userInput.divergingInsuredPerson,
            newState: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER && !data.userInput.divergingInsuredPerson,
            newState: StateName.REQUEST_OFFER_INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'divergingInsuredPerson');

        return {
            userInput: {
                paymentMethod: response.paymentMethod,
                divergingInsuredPerson: response.divergingInsuredPerson,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {payload: await updateDivergingInsuredPersonPageData(inputData.businessId, {
            divergingInsuredPerson: inputData.userInput.divergingInsuredPerson
        })};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <DivergingInsuredPersonPage
            storeState={{
                paymentMethod: inputData.userInput.paymentMethod,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                update: (values, callback) => updateApp(values, callback)
            }}
            onError={onError}
            businessId={inputData.businessId}
            handleAction={handleAction}
            valueRanges={inputData.valueRanges}
        />;
    }
};
