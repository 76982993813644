import Button from '@eg/elements/Button';
import Modal from '@eg/elements/Modal';

const SessionTimeoutModal = () => {
    return <Modal open={true} data-component-id="session-timeout-modal">
        <b data-component-id="session-timeout-modal-header">Leider ist Ihre Session abgelaufen</b>
        <br/><br/>
        Aus Sicherheitsgründen und zum Schutz Ihrer persönlichen Daten haben wir Ihre Sitzung beendet.
        Bitte beginnen Sie den Tarifrechner neu.
        <br/><br/><br/>
        <Button
            style={{float: 'right'}}
            variant="primary"
            data-component-id="session-timeout-modal-reload-button"
            onClick={() => {
                sessionStorage.clear();
                window.location.reload();
            }
        }>
            Tarifrechner neu beginnen
        </Button>
    </Modal>;
};

export default SessionTimeoutModal;
