import { Beitragsvariante, Variante } from 'stg-common/types/Variante';
import formatter, { EURO_WITHOUT_CENT } from '../helpers/currencyFormatter';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import SelectableCard from './SelectableCard';

export interface BurialPackageProps {
    burialPackageType: string;
    ceremony: string;
    arrangement: string;
    casketType: string;
    cost: number;
    minimumInsuranceSum: number;
    dataComponentId: string;
    variant?: Variante;
    icon: JSX.Element;
    onClick: HandleActionCallback;
}

const BurialPackage = (props: BurialPackageProps): JSX.Element => {
    const notSelectableReason = getSelectionDisabledReason(props.minimumInsuranceSum, props.variant);
    return (
        <SelectableCard heading={`${props.burialPackageType} Abschied`}
                        nextText={notSelectableReason || 'Paket auswählen und weiter'}
                        icon={props.icon}
                        disabled={!!notSelectableReason}
                        onClick={() => props.onClick(NavigationAction.NEXT)}
                        dataComponentId={props.dataComponentId}>
                <div style={{marginBottom: '.5em'}}>{props.ceremony}</div>
                <div style={{marginBottom: '.5em'}}>{props.arrangement}</div>
                <div style={{marginBottom: '.5em'}}>{props.casketType} Sarg oder Urne</div>
                <div style={{marginBottom: '.5em'}}>{formatter(EURO_WITHOUT_CENT).format(props.cost)} für weitere Kosten wie z. B. Grabgebühren</div>
        </SelectableCard>);
};

const getSelectionDisabledReason = (minimumInsuranceSum: number, variant?: Variante) => {
    if (!variant || variant.variante !== Beitragsvariante.PREMIUM) {
        return 'Nur für Versicherungsschutz Premium';
    } else if (!variant.versicherungssumme || variant.versicherungssumme < minimumInsuranceSum) {
        return `Nur für Versicherungssummen über ${formatter(EURO_WITHOUT_CENT).format(minimumInsuranceSum)}`;
    }
    return undefined;
};

export default BurialPackage;
