import IntroductionPage from '../../pages/IntroductionPage';
import { StoreStateUpdater } from '../../types/PageProps';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export interface IntroductionPageData extends StoreStateUpdater<IntroductionPageData> {}

export const introductionPage: StateDefinition<IntroductionPageData> = {
    name: StateName.INTRODUCTION_PAGE,
    validInboundStates: [ StateName.BIRTHDATE_PAGE ],
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => ({}),
    onExit: async (transitionDetails, inputData) => ({}),
    render: (inputData, handleAction, updateApp) => <IntroductionPage handleAction={handleAction} />
};
