export interface Variante {
  bruttobeitragNachZahlweise?: number;
  nettobeitragNachZahlweise?: number;
  bruttobeitragMitFlexOption?: number;
  nettobeitragMitFlexOption?: number;
  variante: Beitragsvariante;
  variablerGewinnanteil?: number;
  aufbauzeit?: number;
  selected: boolean;
  selectable: boolean;
  versicherungssumme?: number;
}

export enum Beitragsvariante {
  BASIS = 'grundschutz',
  KOMFORT = 'komfort',
  PREMIUM = 'premium'
}

export interface AdditionalInsuranceSettings {
  beitragsdynamik?: boolean;
}
