import ControlWithHint from '@eg/elements/ControlWithHint';
import Slider from '@eg/elements/Slider';
import { Field, FieldProps, FormikProps, FormikValues } from 'formik';
import { NumericRange } from 'stg-common';
import { STEP_RANGE_SUM_INSURED } from '../../constants';
import formatter, { EURO_WITHOUT_CENT, getFormattedValueWithoutEuroSign } from '../../helpers/currencyFormatter';
import { emptyFunction } from '../../helpers/emptyFunction';
import { filterNumberInputField, getErrorMessage } from '../../helpers/validationHelpers';
import CurrencySliderInput from './CurrencySliderInput';

interface InsuranceSumSliderProps {
    // tslint:disable-next-line:no-any
    form: FormikProps<any>;
    valueRange: NumericRange;
    // tslint:disable-next-line:no-any
    updateState?: any;
}

export const InsuranceSumSlider = (props: InsuranceSumSliderProps): JSX.Element => {
    const update = props.updateState || emptyFunction;

    if (!props.form.touched.insuranceSum) {
        props.form.setFieldTouched('insuranceSum');
    }

    return (<Field
        name="insuranceSum"
        render={({field}: FieldProps<FormikValues>) => {
            const {setFieldValue} = props.form;
            return (
                <div className="esc_grid">
                    <div className="esc_grid__wrapper" style={{justifyContent: 'center'}}>
                        <div className="esc_col esc_col-12 esc_col-s-8">
                            <Slider
                                id="insurance-sum-slider"
                                data-component-id="insurance-sum-slider"
                                min={props.valueRange.min}
                                max={props.valueRange.max}
                                step={props.valueRange.increment && props.valueRange.increment > STEP_RANGE_SUM_INSURED
                                    ? props.valueRange.increment
                                    : STEP_RANGE_SUM_INSURED
                                }
                                value={field.value}
                                subTitle={`${formatter(EURO_WITHOUT_CENT).format(props.valueRange.min)} bis
                                         ${formatter(EURO_WITHOUT_CENT).format(props.valueRange.max)}`}
                                onChange={value => {
                                    setFieldValue(field.name, value);
                                    update({insuranceSum: Number(value)});
                                }}
                            />
                        </div>
                        <div className="esc_col esc_col-s-3 esc_col-8" >
                            <CurrencySliderInput
                                id={field.name}
                                name={field.name}
                                data-component-id="DataIdSumInsuredPage-Input-SumInsured"
                                aria-hidden={true}
                                alignTextRight={true}
                                value={getFormattedValueWithoutEuroSign(field.value)}
                                min={props.valueRange.min}
                                max={props.valueRange.max}
                                step={props.valueRange.increment}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    let insuranceSum;
                                    if (event.target.value) {
                                        insuranceSum = Number(event.target.value.replace('.', ''));
                                        insuranceSum = isNaN(insuranceSum) ? props.valueRange.min : insuranceSum;
                                    }
                                    setFieldValue(field.name, insuranceSum);
                                    update({insuranceSum});
                                }}
                                onKeyDown={filterNumberInputField}
                            />
                        </div>
                        <div className="esc_col esc_col-s-1 esc_col-4"
                             style={{
                                 lineHeight: '49px',
                                 display: 'block',
                                 textAlign: 'left',
                                 fontSize: '1.7em',
                                 fontWeight: 'bold'
                             }}>
                            €
                        </div>
                        <ControlWithHint error={getErrorMessage(props.form, {name: 'insuranceSum'})}><></>
                        </ControlWithHint>
                    </div>
                </div>
            );
        }}/>);
};
