import { AemQuellsystem } from 'stg-common';

export const getRootDiv = () => document.getElementById('stg-root') as Element;

declare global {
  interface Window {
    params: {
      trackingProduktgruppe: string;
      trackingKuerzel: string;
      trackingBrokerModus: string;
      trackingNameBeratung: string;
      trackingNameBasisdaten: string;
      trackingNameBasisdatenVersBeginn: string;
      trackingNameBasisdatenVersSumFestlegen: string;
      trackingNameBasisdatenBeitragFestlegen: string;
      trackingNameBasisdatenBeitragsLZ: string;
      trackingNameBerechnungsergebnis: string;
      trackingNameBerechnungsergebnisBestattungAuswahl: string;
      trackingNameBerechnungsergebnisFlexOption: string;
      trackingNameBerechnungsergebnisBeitragsDynamik: string;
      trackingNamePersonendatenVpAuswahl: string;
      trackingNamePersonendatenVN: string;
      trackingNamePersonendaten: string;
      trackingNamePersonendatenBezugsrechtAuswahl: string;
      trackingNamePersonendatenBankdaten: string;
      trackingNamePruefenUndSenden: string;
      trackingNamePruefenUndSendenIDD: string;
      trackingNameAbschlussFeedback: string;
      trackingNamePersonendatenVpAuswahlAngebot: string;
      trackingNamePersonendatenVNAngebot: string;
      trackingNamePruefenUndSendenAngebot: string;
      trackingNamePruefenUndSendenSchweigepflicht: string;
      trackingNameAngebotFeedback: string;
      trackingNameAbschlussPruefen: string;
    };
  }
}

const getAemParam = (param: string): string => {
  if (!window.params) { return ''; }
  const appKey =
    Object.keys(window.params).find(k => k.startsWith('stg-otr')) || '';
  return (window.params[appKey] && window.params[appKey][param]) as string;
};

export const getProduktgruppe =
  getAemParam('trackingProduktgruppe') || 'Sterbegeld';
export const getKuerzel =
  getAemParam('trackingKuerzel') || 'sterbe';
export const getBrokerModus =
  getAemParam('trackingBrokerModus') || '';
export const getBeratung =
  getAemParam('trackingNameBeratung') || 'Beratung';
export const getBasisdaten =
  getAemParam('trackingNameBasisdaten') || 'Basisdaten';
export const getBasisdatenVersBeginn =
  getAemParam('trackingNameBasisdatenVersBeginn') || 'BasisdatenVersBeginn';
export const getBasisdatenVersSumFestlegen =
  getAemParam('trackingNameBasisdatenVersSumFestlegen') || 'BasisdatenVersSumFestlegen';
export const getBasisdatenBeitragFestlegen =
  getAemParam('trackingNameBasisdatenBeitragFestlegen') || 'BasisdatenBeitragFestlegen';
export const getBasisdatenBeitragsLZ =
  getAemParam('trackingNameBasisdatenBeitragsLZ') || 'BasisdatenBeitragsLZ';
export const getBerechnungsergebnis =
  getAemParam('trackingNameBerechnungsergebnis') || 'Berechnungsergebnis';
export const getBerechnungsergebnisBestattungAuswahl =
  getAemParam('trackingNameBerechnungsergebnisBestattungAuswahl') || 'BerechnungsergebnisBestattungAuswahl';
export const getBerechnungsergebnisFlexOption =
  getAemParam('trackingNameBerechnungsergebnisFlexOption') || 'BerechnungsergebnisFlexOption';
export const getBerechnungsergebnisBeitragsDynamik =
  getAemParam('trackingNameBerechnungsergebnisBeitragsDynamik') || 'BerechnungsergebnisBeitragsDynamik';
export const getPersonendatenVpAuswahl =
  getAemParam('trackingNamePersonendatenVpAuswahl') || 'PersonendatenVpAuswahl';
export const getPersonendatenVN =
  getAemParam('trackingNamePersonendatenVN') || 'PersonendatenVN';
export const getPersonendaten =
  getAemParam('trackingNamePersonendaten') || 'Personendaten';
export const getPersonendatenBezugsrechtAuswahl =
  getAemParam('trackingNamePersonendatenBezugsrechtAuswahl') || 'PersonendatenBezugsrechtAuswahl';
export const getPersonendatenBankdaten =
  getAemParam('trackingNamePersonendatenBankdaten') || 'PersonendatenBankdaten';
export const getPruefenUndSenden =
  getAemParam('trackingNamePruefenUndSenden') || 'PruefenUndSenden';
export const getPruefenUndSendenSchweigepflicht =
  getAemParam('trackingNamePruefenUndSendenSchweigepflicht') || 'PruefenUndSendenSchweigepflicht';
export const getPruefenUndSendenIDD =
  getAemParam('trackingNamePruefenUndSendenIDD') || 'PruefenUndSendenIDD';
export const getAbschlussFeedback =
  getAemParam('trackingNameAbschlussFeedback') || 'AbschlussFeedback';
export const getAbschlussPruefen =
  getAemParam('trackingNameAbschlussPruefen') || 'AbschlussPruefen';
export const getPersonendatenVpAuswahlAngebot =
  getAemParam('trackingNamePersonendatenVpAuswahlAngebot') || 'PersonendatenVpAuswahlAngebot';
export const getPersonendatenVNAngebot =
  getAemParam('trackingNamePersonendatenVNAngebot') || 'PersonendatenVNAngebot';
export const getPruefenUndSendenAngebot =
  getAemParam('trackingNamePruefenUndSendenAngebot') || 'PruefenUndSendenAngebot';
export const getAngebotFeedback =
  getAemParam('trackingNameAngebotFeedback') || 'AngebotFeedback';
export const getTiedAgentOeNumber = getAemParam('OENummer'); // this number is requied on manifest
export const getTiedAgentPnrNumber = getAemParam('PNRNumber') || '';
export const getTelephone = getAemParam('telephone');
export const quellsystem = getAemParam('quellsystem') || AemQuellsystem.ERGO_INTERNET;
export const isFullofferEnabled = (getAemParam('Vollangebot') !== '') ? getAemParam('Vollangebot') === 'true' : true;
