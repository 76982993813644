import React from 'react';
import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { AlertIcon, CloseIcon, SaveCheckIcon } from '@eg/elements/components/Icons';
import { InputRow } from '@eg/elements/InputRow';
import { SelectRow } from '@eg/elements/SelectRow';
import { Field, FieldProps, Form as FormRaw, Formik, FormikProps, FormikValues } from 'formik';
import { Adresse, mapDateInputToIsoDateString, Person, ValueRanges } from 'stg-common';
import { getErrorMessage } from '../../helpers/validationHelpers';
import { EntitledPersonViewModel, NEW_ENTITLED_PERSON_FORM_REGISTRATION_KEY } from '../../pages/EntitledPersonsPage';
import { trackElementClickImmediate } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';
import { createEntitledPersonSchema } from '../../validation/Entitlement';
import PersonalData from '../PersonalData';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.
export interface EditEntitlementProps {
    cancelCallback: () => void;
    entitledPerson: EntitledPersonViewModel;
    headline: string;
    registerEntitlementFormCallback: (pdsId: string, formikForm: FormikProps<FormikValues>) => void;
    saveCallback: (newEntitledPerson: EntitledPersonViewModel) => Promise<void>;
    showCancelButton: boolean;
    showUsePolicyHolderData: boolean;
    submitFinishedCallback: () => void;
    updateCallback: (newEntitledPerson: Partial<EntitledPersonViewModel>) => void;
    valueRanges: ValueRanges;
    addressFromInsuredPerson?: Adresse;
    policyHolderPerson?: Person;
}

export interface EditEntitlementState {
    saveLoading: boolean;
    errorMessage?: string;
}

class EditEntitlement extends React.Component<EditEntitlementProps, EditEntitlementState> {

    constructor(props: EditEntitlementProps) {
        super(props);
        this.state = {
            saveLoading: false
        };
    }

    public render() {
        return (
            <Formik
                initialValues={this.props.entitledPerson}
                validationSchema={createEntitledPersonSchema()}
                onSubmit={async values => {
                    this.setState({saveLoading: true});
                    const neueBezugsPerson = {
                        ...values,
                        birthdate: values.birthdateField ? mapDateInputToIsoDateString(values.birthdateField) : values.birthdate,
                        entitlementPercentage: values.entitlementPercentage
                    };
                    try {
                        await this.props.saveCallback(neueBezugsPerson);
                        this.props.submitFinishedCallback();
                        this.setState({
                            saveLoading: false,
                            errorMessage: undefined
                        });
                    } catch (e) {
                        this.props.submitFinishedCallback();
                        this.setState({
                            saveLoading: false,
                            errorMessage: 'Fehler beim Speichern der bezugsberechtigten Person'
                        });
                    }
                }}
            >
                {(formikForm: FormikProps<EntitledPersonViewModel>) => {
                    const registrationKey = this.props.entitledPerson.personId ?
                        this.props.entitledPerson.personId :
                        NEW_ENTITLED_PERSON_FORM_REGISTRATION_KEY;
                    this.props.registerEntitlementFormCallback(registrationKey, formikForm);
                    return (
                        <Card alignLabel="left">
                            <Form data-component-id="edit-entitled-person-form" noValidate>
                                <div>
                                    <h3>{this.props.headline}</h3>
                                    <br/>

                                    <PersonalData
                                        form={formikForm}
                                        inputData={this.props.entitledPerson}
                                        updateCallback={this.props.updateCallback}
                                        detailedMode={false}
                                        disableBirthdate={false}
                                        disableInfoBoxEudsgvo={true}
                                        showCountry={true}
                                        showUsePolicyHolderData={this.props.showUsePolicyHolderData}
                                        showCopyInsuredPersonAddress={!this.props.showUsePolicyHolderData}
                                        insuredPersonAddress={this.props.addressFromInsuredPerson}
                                        policyHolderPerson={this.props.policyHolderPerson}
                                    />

                                </div>
                                <div>
                                    <Field
                                        name="relationship"
                                        render={({field, form}: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                                            const {setFieldValue, setFieldTouched} = form;
                                            return (
                                                <SelectRow
                                                    id={field.name}
                                                    name={field.name}
                                                    disabled={this.state.saveLoading}
                                                    label="Verhältnis zum Versicherungsnehmer"
                                                    onBlur={() => setFieldTouched(field.name)}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setFieldValue(field.name, event.target.value);
                                                        if (event.target.value !== 'SONSTIGES_VERHAELTNIS') {
                                                            const relationshipText = this.props.valueRanges.relationships.possibleValues.find(
                                                                relationship => relationship.key === event.target.value
                                                            );
                                                            setFieldValue(
                                                                'relationshipFreeText',
                                                                relationshipText ? relationshipText.text : event.target.value
                                                            );
                                                        } else {
                                                            setFieldValue('relationshipFreeText', '');
                                                            setFieldTouched('relationshipFreeText', false);
                                                        }
                                                    }}
                                                    error={getErrorMessage(form, field)}
                                                    value={field.value}
                                                >
                                                    <option value="">Bitte wählen...</option>
                                                    {this.props.valueRanges.relationships.possibleValues.map(relationship => {
                                                        return <option key={relationship.key}
                                                                       value={relationship.key}>{relationship.text}</option>;
                                                    })}
                                                </SelectRow>
                                            );
                                        }}
                                    />
                                    {formikForm.values.relationship === 'SONSTIGES_VERHAELTNIS' && <Field
                                        name="relationshipFreeText"
                                        render={({field, form}: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                                            const {setFieldValue, setFieldTouched} = form;
                                            return (
                                                <InputRow id={field.name}
                                                          name={field.name}
                                                          disabled={this.state.saveLoading}
                                                          label="Bitte angeben:"
                                                          onBlur={() => {
                                                              setFieldTouched(field.name);
                                                          }}
                                                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                              setFieldValue(field.name, event.target.value);
                                                          }}
                                                          value={field.value ? field.value : ''}
                                                          error={getErrorMessage(form, field)}/>
                                            );
                                        }}
                                    />
                                    }
                                </div>
                                {this.state.errorMessage && <div style={{color: '#8e0038', fontSize: '13px'}}>
                                    <AlertIcon
                                        // tslint:disable-next-line: no-magic-numbers
                                        width={16} height={16}/> {this.state.errorMessage}
                                </div>}
                                <div className="esc_grid">
                                    <div className="esc_grid__wrapper">
                                        <div className="esc_col esc_col-6">
                                            {this.props.showCancelButton && <Button
                                                variant="text-link"
                                                type="button"
                                                iconLeft={CloseIcon}
                                                data-component-id="edit-entitled-person-cancel"
                                                onClick={() => {
                                                    trackElementClickImmediate(TrackingElement.Link_BezugBearbeitenAbbrechen);
                                                    this.props.cancelCallback();
                                                }}>
                                                abbrechen
                                            </Button>
                                            }
                                        </div>
                                        <div className="esc_col esc_col-6" style={{textAlign: 'right'}}>
                                            <Button variant="primary" loading={this.state.saveLoading}
                                                    data-component-id="edit-entitled-person-save"
                                                    type="submit"
                                                    iconLeft={SaveCheckIcon}
                                                    onClick={() => {
                                                        trackElementClickImmediate(TrackingElement.Button_BezugPersonSpeichern);
                                                        formikForm.submitForm();
                                                    }}
                                                    size="large"
                                            >Person speichern</Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    );
                }}
            </Formik>
        );
    }
}

export default EditEntitlement;
