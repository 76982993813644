import { Button } from '@eg/elements/Button';
import { OrdersIcon } from '@eg/elements/components/Icons';
import { FC } from 'react';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

interface NavigationToOfferButtonProps {
    showLoadingSpinner?: boolean;
    handleAction: HandleActionCallback;
    offerCallback?: (action: NavigationAction) => void;
}

export const NavigationToOfferButton: FC<NavigationToOfferButtonProps> = ({
    showLoadingSpinner,
    handleAction,
    offerCallback
}): JSX.Element => {
    const onClick = () => {
        trackElementClicked(TrackingElement.Link_AngebotAnfordern);
        if (offerCallback) {
            offerCallback(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);
        } else {
            handleAction(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);
        }
    };

    return <>
        <Button
            data-component-id="button_angebot"
            iconLeft={OrdersIcon}
            loading={showLoadingSpinner}
            onClick={onClick}
            style={{display: 'inline-flex', margin: '1.25em auto 0 auto', fontWeight: 'bold'}}
            type="button"
            variant="text-link"
        >
            Angebot anfordern
        </Button>
        <br/>
    </>;
};
