import React from 'react';
import { CalculationMode } from 'stg-common';
import { BasketStoreItems, default as Basket } from '../components/Basket';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import SelectableCard from '../components/SelectableCard';
import { feePerPaymentMethod } from '../helpers/paymentHelper';
import { scrollToTop } from '../helpers/scrolling';
import { extractSelectedVariant } from '../helpers/variants';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface FlexOptionPageData extends StoreStateUpdater<FlexOptionPageData>, BasketStoreItems {
    calculationMode: CalculationMode;
}

function FlexOptionPage(props: PagePropsWithValues<FlexOptionPageData>) {

    React.useEffect(() => {
        scrollToTop();
    }, []);

    const variant = extractSelectedVariant(props.storeState.variants);

    return (<>
        <Basket basketStore={props.storeState}
                valueRanges={props.valueRanges}
                handleAction={props.handleAction}
                hideFeeDynamicEdit={true}
                hideFlexOptionEdit={true}
                hideBurialOptionEdit={false}
                hideFeeDynamic={true}
                hideFlexOption={true}
        />
        <Headline subLine={'Diese Option besteht nach der Beitragszahlungsdauer.'}>
            Bleiben Sie flexibel!
        </Headline>
        <SelectableCard heading="Flex-Option"
                        nextText="auswählen und weiter"
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_FlexAuswaehlen);
                            props.storeState.update({flexOption: true}, () => props.handleAction(NavigationAction.NEXT));
                        }}
                        dataComponentId="select-flex-option-button">
            <div>
                Die Beiträge wurden bis zum vereinbarten Ende der Beitragszahlungspflicht gezahlt.
                Wenn Sie nun die Versicherung kündigen, erhalten Sie den Rückkaufswert ohne Abzüge. Die Versicherung endet dann.
            </div>

            {props.storeState.calculationMode === CalculationMode.INSURANCE_FEE &&
            <div data-component-id="flexoption-insurancesum-hint" style={{marginTop: '1em'}}>
                <b>Achtung:</b> Durch die Kosten für die Flex-Option reduziert sich die Versicherungssumme. Dafür steigt der monatliche Beitrag nicht.
            </div>
            }

            <div style={{marginTop: '1em'}}>
                Der neue Beitrag mit Flex-Option wäre: &nbsp;
                <span style={{color: '#8e0038', fontWeight: 'bold'}} data-component-id="flex-option-price">
                    {feePerPaymentMethod(variant ? variant.nettobeitragMitFlexOption : 0, props.storeState.paymentMethod)}
                </span>
            </div>
        </SelectableCard>
        <SelectableCard heading="Ich möchte diese Option nicht."
                        nextText="weiter"
                        onClick={() => {
                            trackElementClicked(TrackingElement.Link_WeiterOhneFlex);
                            props.storeState.update({flexOption: false}, () => props.handleAction(NavigationAction.NEXT));
                        }}
                        dataComponentId="no-flex-option-button">
            <div>
                Der Beitrag ohne Flex-Option wäre: &nbsp;
                <span style={{color: '#8e0038', fontWeight: 'bold'}} data-component-id="no-flex-option-price">
                    {feePerPaymentMethod(variant ? variant.nettobeitragNachZahlweise : 0, props.storeState.paymentMethod)}
                </span>
            </div>
        </SelectableCard>

        <Footer handleAction={props.handleAction} hideNextButton={true}/>
        <DisclaimerInfoBox/>
    </>);
}

export default FlexOptionPage;
