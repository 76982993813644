import React from 'react';
import { FormikContext } from 'formik';

interface ScrollToErrorProps {
    formik: FormikContext<{}>;
}

class ScrollToError extends React.Component<ScrollToErrorProps> {

    public componentDidUpdate(prevProps: ScrollToErrorProps) {
        const {isSubmitting, isValidating} = prevProps.formik;
        const keys = getDeepKeys(prevProps.formik.errors);

        if (keys.length > 0 && isSubmitting && !isValidating) {
            const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement;
            if (errorElement) {
                errorElement.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
            }
        }
    }

    public render() {
        // tslint:disable-next-line
        return null;
    }
}

const scrollToErrorByQuery = (query: string) => {
    const errorElement = document.querySelector(query) as HTMLElement;
    if (errorElement) {
        errorElement.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }
};

const getDeepKeys = (object: object): string[] => {
    let keys: string[] = [];
    for (const key in object) {
        if (typeof object[key] === 'object') {
            const subkeys = getDeepKeys(object[key]);
            keys = keys.concat(subkeys.map(subkey => `${key}.${subkey}`));
        } else {
            keys.push(key);
        }
    }
    return keys;
};

export { ScrollToError, scrollToErrorByQuery };
