import { IconProps } from './IconTypes';

export const FeuerbestattungIcon = (props: IconProps): JSX.Element => {
  return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
          d={`M4.74134633,18.2264486 L10.8851395,18.2264486 C13.043031,14.0927989 14.1176471,11.1493419 14.1176471,9.47875434 
                                        C14.1176471,8.56119481 13.9089308,7.51077254 13.4884954,6.33054088 L2.1818193,6.33054088 C1.72629373,7.58132133 
                                        1.5,8.6329408 1.5,9.47875434 C1.5,10.960419 2.58022135,13.9124827 4.74134633,18.2264486 Z M3.06672354,18.2264486 
                                        C1.02243655,14.0662934 -1.08357767e-13,11.181463 -1.08357767e-13,9.47875434 C-1.08357767e-13,7.64212131 
                                        0.819304596,5.21412858 2.44021024,2.15473689 C2.5702303,1.90932945 2.82521825,1.75586038 3.10294118,1.75586038 
                                        L5.45588235,1.75586038 L5.45588235,1.17647059 C5.45588235,0.526723824 5.98260618,-1.87952424e-13 
                                        6.63235294,-1.87849736e-13 L8.98529412,-1.87849736e-13 C9.63504088,-1.88191137e-13 10.1617647,0.526723824 
                                        10.1617647,1.17647059 L10.1617647,1.75586038 L12.5147059,1.75586038 C12.7846532,1.75586038 13.0337744,1.90093111 
                                        13.1670019,2.13571183 C14.7940149,5.00292302 15.6176471,7.44374025 15.6176471,9.47875434 C15.6176471,11.3546135 
                                        14.6003181,14.2471279 12.5730077,18.2264486 L12.6929929,18.2264486 C13.1072064,18.2264486 13.4429929,18.5622351 
                                        13.4429929,18.9764486 C13.4429929,19.3906622 13.1072064,19.7264486 12.6929929,19.7264486 L2.70040413,19.7264486 
                                        C2.28619057,19.7264486 1.95040413,19.3906622 1.95040413,18.9764486 C1.95040413,18.5622351 2.28619057,18.2264486 
                                        2.70040413,18.2264486 L3.06672354,18.2264486 Z M2.66274376,5.13054088 L13.0077353,5.13054088 C12.744032,4.53417592 
                                        12.4330441,3.90917574 12.0744567,3.25586038 L3.55716307,3.25586038 C3.21647133,3.91672208 2.918409,4.54179074 
                                        2.66274376,5.13054088 Z`}
          transform="translate(4 2)"/>
  </svg>);
};
