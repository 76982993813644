import Button from '@eg/elements/Button';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { PagePropsWithValues } from '../../types/PageProps';

// tslint:disable-next-line:no-any
export const PaymentMethodSelection = (props: PagePropsWithValues<any>): JSX.Element => {

    return (<div style={{textAlign: 'center', marginTop: '2em'}}>
        <b>Zahlweise:</b>
        <div style={{marginTop: '25px', textAlign: 'center'}}>
            {props.valueRanges.paymentMethod.possibleValues.map(method => {
                const variante = method.key === props.storeState.paymentMethod ? 'primary' : 'tertiary';

                return <Button
                    key={method.key}
                    type="button"
                    data-component-id={`paymentmethod-${method.key}-button`}
                    variant={variante}
                    style={{margin: ' 0 5px 15px 5px', minWidth: '10em'}}
                    width="auto"
                    onClick={() => {
                        props.storeState.update({paymentMethod: method.key, showCalculationDialog: true}, () => {
                            props.handleAction(NavigationAction.SWITCH_PAYMENT_PERIOD);
                        });
                    }}
                >
                    {method.text}
                </Button>;
            })}
        </div>
    </div>);
};
