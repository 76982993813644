import React from 'react';
import { Nationality, Person } from 'stg-common';
import { GwgOptions } from '../../components/GwgQuestions/GwgQuestions';
import { PagePropsWithValues, StoreStateUpdater } from '../../types/PageProps';
import AbstractPersonPage from '../AbstractPersonPage';

export interface RequestOfferPolicyHolderPageData extends GwgOptions, StoreStateUpdater<RequestOfferPolicyHolderPageData> {
    divergingInsuredPerson: boolean;
    policyHolder: Person;
    nationalities: Nationality[];
    showMeldung: boolean;
}

interface RequestOfferPolicyHolderPageProps extends PagePropsWithValues<RequestOfferPolicyHolderPageData> {
    businessId: string;
}

class RequestOfferPolicyHolderPage extends React.Component<RequestOfferPolicyHolderPageProps> {

    public render() {
        return <AbstractPersonPage
            {...this.props}
            storeState={{
                ...this.props.storeState.policyHolder,
                personId: this.props.storeState.policyHolder.personId!,
                divergingInsuredPerson: this.props.storeState.divergingInsuredPerson,
                politicallyExposedPerson: this.props.storeState.politicallyExposedPerson,
                custodian: this.props.storeState.custodian,
                vklm: this.props.storeState.vklm,
                accountThirdParty: this.props.storeState.accountThirdParty,
                nationalities: this.props.storeState.nationalities,
                showMeldung: this.props.storeState.showMeldung,
                update: this.props.storeState.update
            }}
            headline="Versicherungsnehmer"
            componentPrefix="policy-holder"
            showDetailedMode={true}
            disableBirthday={false}
            isGwgRequired={false}
            trackPersonData={true}
            disableOfferNavigation={true}
        />;
    }
}

export default RequestOfferPolicyHolderPage;
