import React from 'react';
import { LoadingSpinner } from '@eg/elements/LoadingSpinner';
import { Message, ValueRanges } from 'stg-common';
import { Schema } from 'yup';
import { PageWrapper } from '../components/PageWrapper';
import SessionTimeoutModal from '../components/SessionTimeoutModal';
import { getTiedAgentPnrNumber } from '../helpers/aemHelper';
import { getIsMakler, getIsTiedAgent, getOeNumber } from '../helpers/modeConfig';
import { SESSION_STORAGE_CURRENT_STATE_KEY, Storage } from '../helpers/Storage';
import { trackPageTransition } from '../tracking/tracker';
import { resolveNewStateName } from './routeHelper';
import { NavigationAction, StateName } from './StateMachineTypes';

export type UpdateFunction<T> = (userInput: Partial<T>, callback?: () => void) => void;
export type OnEnterCallback<T> = (transitionDetails: TransitionDetails, inputData: TransitionInput<T>) => Promise<Partial<TransitionOutput<T>>>;
export type OnExitCallback<T> = (transitionDetails: TransitionDetails, inputData: TransitionInput<T>)
// tslint:disable-next-line:no-any
=> Promise<Optional<{ payload?: any; skipTransition?: boolean }>> | undefined;
export type HandleActionCallback = (action: NavigationAction, interceptedAction?: NavigationAction) => void;
export type ValidationSchemaCreator = (valueRanges: ValueRanges) => Schema<{}>;

export interface StateDefinition<T> {
  name: StateName;
  createValidationSchema?: ValidationSchemaCreator;
  /**
   * The States from which this State can be reached.
   */
  validInboundStates: StateName[];
  /**
   * The allowed transitions away from this state into another one.
   */
  transitions: Array<Transition<T>>;
  onEnter?: OnEnterCallback<T>;
  onExit?: OnExitCallback<T>;
  render?: (inputData: StateData<T>, handleAction: HandleActionCallback, updateApp: UpdateFunction<T>, onError: (e: Error) => void) => React.ReactElement;
  disableOnEnterTracking?: boolean;
}

export interface StateData<T> extends TransitionInput<T> {
  valueRanges: ValueRanges;
}

export interface Transition<T> {
  test: (action: NavigationAction, inputData: StateData<T>) => boolean;
  resolveNewState?: (action: NavigationAction, inputData: StateData<T>) => StateName;
  newState: StateName;
}

export interface TransitionInput<T> {
  businessId: string;
  userInput: T;
}

export interface TransitionOutput<T> {
  userInput: Partial<T>;
  valueRanges: ValueRanges;
}

export interface TransitionDetails {
  sourceStateName?: StateName;
  targetStateName?: StateName;
  action?: NavigationAction;
  // tslint:disable-next-line:no-any
  payload?: any;
}

export interface StateMachineProps {
  stateDefinitions: Array<StateDefinition<{}>>;
  inputData: {
    businessId: string;
    messages?: Message[];
  };
}

export interface StateMachineInternalState {
  currentStateName: StateName;
  transitionCompleted: boolean;
  // tslint:disable-next-line:no-any
  userInput: any;
  valueRanges: ValueRanges;
  updateCalling: boolean;
  interceptedAction?: NavigationAction;
  lastFailedAction?: NavigationAction;
  sessionTerminated: boolean;
}

export class StateMachine extends React.Component<StateMachineProps, StateMachineInternalState> {
  public constructor(props: StateMachineProps) {
    super(props);

    const currentStateFromSessionStorage: string | undefined = Storage.readItem(SESSION_STORAGE_CURRENT_STATE_KEY);
    const keyName: string | undefined = Object.keys(StateName).find(element => StateName[element] === currentStateFromSessionStorage);

    const maklerPage = getIsMakler() ? StateName.INTRODUCTION_PAGE : StateName.BIRTHDATE_PAGE;
    const currentStateName: StateName = keyName ? StateName[keyName] : maklerPage;

    window.onpopstate = this.handleBrowserBack;

    // Initial page push is needed.
    window.history.pushState({}, 'Sterbegeldversicherung');

    this.state = {
      currentStateName,
      transitionCompleted: false,
      userInput: {},
      valueRanges: {} as ValueRanges,
      updateCalling: false,
      interceptedAction: undefined,
      lastFailedAction: undefined,
      sessionTerminated: false
    };
  }

  public handleBrowserBack = (event: PopStateEvent) => {
    this.handleAction(NavigationAction.BROWSER_BACK);
  };

  public async componentDidMount() {
    const state = this.props.stateDefinitions.find(s => s.name === this.state.currentStateName);
    if (state === undefined) {
      return;
    }
    await this.handleOnEnter(state, {
      action: NavigationAction.START,
      sourceStateName: undefined,
      targetStateName: this.state.currentStateName
    });
  }

  public render() {
    const currentStateName: string = this.state.currentStateName;
    const currentState: StateDefinition<{}> | undefined = this.props.stateDefinitions.find(
      state => state.name === currentStateName
    );

    if (currentState) {
      if (!this.state.transitionCompleted) {
        return <LoadingSpinner show={true} />;
      }
      if (this.state.sessionTerminated) {
        return <SessionTimeoutModal />;
      }

      if (currentState.render) {
        return <PageWrapper
          currentState={currentState}
          messages={this.state.userInput.messages}
          inputData={{
            businessId: this.props.inputData.businessId,
            userInput: this.state.userInput,
            valueRanges: this.state.valueRanges
          }}
          openErrorModal={!!this.state.lastFailedAction}
          handleAction={this.handleAction}
          // tslint:disable-next-line:no-any
          updateApp={(userInput: any, callback?: () => void) => {
            const updatedUserInput = {
              ...this.state.userInput,
              ...currentState.createValidationSchema ?
                currentState.createValidationSchema(this.state.valueRanges).cast(userInput) :
                userInput
            };
            this.setState({
              userInput: updatedUserInput
            }, callback);
          }}
          updateCalling={this.state.updateCalling}
        />;
      }
    }
    throw new Error(
      `Neither a valid render or component property was found for state '${currentStateName}'`
    );
  }

  private async update(action: NavigationAction) {
    const { stateDefinitions, inputData } = this.props;
    const currentStateName: StateName = this.state.currentStateName;
    const currentState = stateDefinitions.find(state => state.name === currentStateName);
    if (currentState && action) {
      const newStateName = resolveNewStateName(currentState, action, {
        ...inputData,
        userInput: this.state.userInput
      });
      if (newStateName) {
        await this.transition(currentStateName, newStateName, action);
        return;
      }
    }
  }

  private async transition(oldStateName: StateName, newStateName: StateName, action: NavigationAction) {
    const isMakler = getIsMakler();
    const agentOeNr = getOeNumber();
    const isTiedAgent = getIsTiedAgent();
    const nextState: StateDefinition<{}> | undefined = this.props.stateDefinitions.find(
      state => state.name === newStateName
    );
    if (!nextState) {
      const validStates: string = this.props.stateDefinitions.map(state => state.name).join(', ');
      throw new Error(
        `Tried to transition from state '${oldStateName}' to '${newStateName}'. Valid states are: [${validStates}]`
      );
    } else {
      const validTransition = nextState.validInboundStates.filter(validEntryState => validEntryState === oldStateName).length > 0;
      if (!validTransition) {
        const validStates: string = nextState.validInboundStates.join(', ');
        throw new Error(
          `Tried forbidden transition from state '${oldStateName}' to '${newStateName}'. Valid entry states are: [${validStates}]`
        );
      }
    }
    this.setState({
      updateCalling: true
    });

    const transitionDetails: TransitionDetails = {
      action,
      sourceStateName: oldStateName,
      targetStateName: newStateName
    };
    // tslint:disable-next-line:no-any
    let payload: any;
    const currentState: StateDefinition<{}> | undefined = this.props.stateDefinitions.find(state => state.name === oldStateName);
    try {
      if (currentState) {
        if (currentState.onExit) {
          try {
            const onExitReturn = await currentState.onExit(transitionDetails, {
              ...this.props.inputData,
              userInput: this.state.userInput
            });
            if (onExitReturn) {
              payload = onExitReturn.payload;
              if (onExitReturn.skipTransition) {
                this.setState({
                  updateCalling: false,
                  userInput: {
                    ...this.state.userInput,
                    ...payload
                  }
                });
                return;
              }
            }
            if (!nextState.disableOnEnterTracking) {
              trackPageTransition(
                transitionDetails.targetStateName as StateName,
                isMakler,
                isTiedAgent,
                agentOeNr,
                getTiedAgentPnrNumber
              );
            }
          } catch (e) {
            this.setState({
              lastFailedAction: action,
              updateCalling: false,
              sessionTerminated: (e as Error).message.includes('401')
            });
            return;
          }
        }
      }

      Storage.writeItem(SESSION_STORAGE_CURRENT_STATE_KEY, newStateName);
      window.history.pushState({ action, oldStateName }, 'Sterbegeldversicherung');
      this.setState({
        currentStateName: newStateName,
        transitionCompleted: false,
        lastFailedAction: undefined
      }, async () => {
        await this.handleOnEnter(nextState, { ...transitionDetails, payload });
      });
    } catch (e) {
      Storage.writeItem(SESSION_STORAGE_CURRENT_STATE_KEY, newStateName);
      this.setState({
        currentStateName: oldStateName,
        updateCalling: false,
        transitionCompleted: true,
        sessionTerminated: (e as Error).message.includes('401')
      });
    }
  }

  private readonly handleAction: HandleActionCallback = async (action: NavigationAction) => {
    if (action === NavigationAction.REPEAT_CALL) {
      if (this.state.lastFailedAction) {
        await this.update(this.state.lastFailedAction);
      }
    } else {
      if (this.state.interceptedAction) {
        this.setState({
          interceptedAction: undefined
        });
        await this.update(this.state.interceptedAction);
      } else {
        await this.update(action);
      }
    }
  };

  private async handleOnEnter(state: StateDefinition<{}>, transitionDetails: TransitionDetails) {
    if (state === undefined || !state.onEnter) {
      this.setState({
        transitionCompleted: true,
        updateCalling: false
      });

      return;
    }

    try {
      const output = await state.onEnter(transitionDetails, {
        ...this.props.inputData,
        userInput: this.state.userInput
      });

      this.setState({
        transitionCompleted: true,
        userInput: {
          ...this.state.userInput,
          ...output.userInput
        },
        valueRanges: {
          ...this.state.valueRanges,
          ...output.valueRanges
        },
        updateCalling: false
      });

    } catch (e) {
      this.setState({
        lastFailedAction: transitionDetails.action,
        transitionCompleted: true,
        updateCalling: false,
        sessionTerminated: (e as Error).message.includes('401')
      });
      return;
    }
  }
}
