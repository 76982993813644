import { isRequestOffer } from '../../components/GwgQuestions/GwgQuestions';
import { PAYMENT_METHOD_SINGLE_FEE } from '../../constants';
import InsuredPersonPage, { InsuredPersonPageData } from '../../pages/InsuredPersonPage';
import { getOfferPageData, getPerson, updateInsuredPersonPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuredPersonPage: StateDefinition<InsuredPersonPageData> = {
    name: StateName.INSURED_PERSON_PAGE,
    validInboundStates: [
        StateName.POLICY_HOLDER_PAGE,
        StateName.ENTITLED_PERSONS_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE,
        StateName.BANKING_DETAILS_PAGE,
        StateName.FEE_DYNAMIC_PAGE,
        StateName.FEE_PAGE,
        StateName.INSURANCE_SUMMARY_PAGE,
        StateName.CONFIDENTIALITY_RELEASE_PAGE,
        StateName.INSURANCE_DOCUMENTS_PAGE
    ],
    transitions: [
        {
            test: (action, data) => (action === NavigationAction.NEXT && !isRequestOffer(data.userInput)),
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.NEXT && !!isRequestOffer(data.userInput)),
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) && data.userInput.divergingInsuredPerson,
            newState: StateName.POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK) && !data.userInput.divergingInsuredPerson,
            newState: StateName.FEE_DYNAMIC_PAGE
        },
        {
            test: (action, data) => {
                return (action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK)
                    && data.userInput.paymentMethod === PAYMENT_METHOD_SINGLE_FEE;
            },
            newState: StateName.FEE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuredPerson').then(offerPageData => {
            if (offerPageData.insuredPersonId) {
                return getPerson(inputData.businessId, offerPageData.insuredPersonId).then(person => ({ ...offerPageData, insuredPerson: person }));
            }
            return offerPageData;
        });

        return {
            userInput: {
                ...response,
                showMeldung: false
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {payload: await updateInsuredPersonPageData(inputData.businessId, {
                insuredPerson: inputData.userInput.insuredPerson,
                politicallyExposedPerson: inputData.userInput.politicallyExposedPerson,
                vklm: inputData.userInput.vklm,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty
            })};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <InsuredPersonPage
            storeState={{
                insuredPerson: inputData.userInput.insuredPerson,
                nationalities: inputData.userInput.nationalities,
                divergingInsuredPerson: inputData.userInput.divergingInsuredPerson,
                custodian: inputData.userInput.custodian,
                accountThirdParty: inputData.userInput.accountThirdParty,
                messages: inputData.userInput.messages,
                vklm: inputData.userInput.vklm,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            businessId={inputData.businessId}
            handleAction={handleAction}
            valueRanges={inputData.valueRanges}
        />;
    }
};
