import * as React from 'react';
import { isFullofferEnabled } from '../../helpers/aemHelper';
import { getIsErgoInternet } from '../../helpers/modeConfig';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { BackButton } from './BackButton';
import { NavigationToOfferButton } from './NavigationToOfferButton';
import { NextButton } from './NextButton';
import { OfferPageButtons } from './OfferPageButtons';

export interface FooterProps {
  disableNextButton?: boolean;
  disableOfferNavigation?: boolean;
  enableRequestOfferButtons?: boolean;
  errorStatus?: boolean;
  hideBackBtn?: boolean;
  hideNextButton?: boolean;
  nextButtonText?: string;
  showLoadingSpinner?: boolean;
  handleAction: HandleActionCallback;
  offerCallback?: (action: NavigationAction) => void;
  onNextClick?: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  disableNextButton,
  disableOfferNavigation,
  enableRequestOfferButtons,
  errorStatus,
  hideBackBtn,
  hideNextButton,
  nextButtonText,
  showLoadingSpinner,
  handleAction,
  offerCallback,
  onNextClick
}) => (
  <div
    style={{
      clear: 'both',
      textAlign: 'center',
      flexDirection: 'column',
      marginBottom: '2em'
    }}
  >
    {!errorStatus && !hideNextButton && (
      <NextButton
        disableNextButton={disableNextButton}
        nextButtonText={nextButtonText}
        showLoadingSpinner={showLoadingSpinner}
        onNextClick={onNextClick}
      />
    )}
    <br />
    {!disableOfferNavigation && getIsErgoInternet() && isFullofferEnabled ? (
      <NavigationToOfferButton
        showLoadingSpinner={showLoadingSpinner}
        handleAction={handleAction}
        offerCallback={offerCallback}
      />
    ) : (
      <></>
    )}
    {enableRequestOfferButtons && (
      <OfferPageButtons
        showLoadingSpinner={showLoadingSpinner}
        offerCallback={offerCallback}
      />
    )}
    {!hideBackBtn && <BackButton handleAction={handleAction} />}
  </div>
);
