export enum StateName {
    INTRODUCTION_PAGE = 'IntroductionPage',
    BIRTHDATE_PAGE = 'BirthdatePage',
    INSURANCE_START_PAGE = 'InsuranceBeginPage',
    INSURANCE_SUM_PAGE = 'InsuranceSumPage',
    INSURANCE_FEE_PAGE = 'InsuranceFeePage',
    PAYMENT_PERIOD_PAGE = 'PaymentPeriodPage',
    FEE_PAGE = 'FeePage',
    FEE_PAGE_INVALID = 'FeePageInvalidTariff',
    BURIAL_PACKAGE_PAGE = 'BurialPackagePage',
    FLEX_OPTION_PAGE = 'FlexOptionPage',
    FEE_DYNAMIC_PAGE = 'FeeDynamicPage',
    DIVERGING_INSURED_PERSON_PAGE = 'DivergingInsuredPersonPage',
    POLICY_HOLDER_PAGE = 'PolicyHolderPage',
    INSURED_PERSON_PAGE = 'InsuredPersonPage',
    ENTITLED_PERSONS_PAGE = 'EntitledPersonsPage',
    BANKING_DETAILS_PAGE = 'BankingDetailsPage',
    INSURANCE_SUMMARY_PAGE = 'InsuranceSummaryPage',
    CONFIDENTIALITY_RELEASE_PAGE = 'ConfidentialityReleasePage',
    INSURANCE_DOCUMENTS_PAGE = 'InsuranceDocumentsPage',
    FEEDBACK_PAGE = 'FeedbackPage',
    REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE = 'RequestOfferDivergingInsuredPersonPage',
    REQUEST_OFFER_POLICY_HOLDER_PAGE = 'RequestOfferPolicyHolderPage',
    REQUEST_OFFER_INSURED_PERSON_PAGE = 'RequestOfferInsuredPersonPage',
    REQUEST_OFFER_CHECKOUT_PAGE = 'RequestOfferCheckoutPage',
    REQUEST_OFFER_FEEDBACK_PAGE = 'RequestOfferFeedbackPage',
    DUMMY3 = 'Dummy3',
    FAKE_PRUEFE_SENDEN_PAGE = 'FakePruefeSendenPage'
}

export const TARIFF_DATA_PAGES = [StateName.BIRTHDATE_PAGE, StateName.INSURANCE_START_PAGE, StateName.INSURANCE_SUM_PAGE, StateName.INSURANCE_FEE_PAGE];
export const FEE_PAGES = [StateName.FEE_PAGE, StateName.BURIAL_PACKAGE_PAGE, StateName.FLEX_OPTION_PAGE, StateName.FEE_DYNAMIC_PAGE];
export const PERSONAL_INFORMATION_PAGES = [StateName.DIVERGING_INSURED_PERSON_PAGE, StateName.POLICY_HOLDER_PAGE, StateName.INSURED_PERSON_PAGE,
    StateName.ENTITLED_PERSONS_PAGE, StateName.BANKING_DETAILS_PAGE];
export const CHECKOUT_PAGES = [StateName.INSURANCE_SUMMARY_PAGE, StateName.CONFIDENTIALITY_RELEASE_PAGE, StateName.INSURANCE_DOCUMENTS_PAGE,
    StateName.FEEDBACK_PAGE, StateName.REQUEST_OFFER_CHECKOUT_PAGE];
export const REQUEST_OFFER_PAGES = [StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE, StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
    StateName.REQUEST_OFFER_INSURED_PERSON_PAGE, StateName.REQUEST_OFFER_FEEDBACK_PAGE];

export enum NavigationAction {
    SWITCH_CALCULATION_MODE = 'switch_calulation_mode',
    SWITCH_PAYMENT_PERIOD = 'switch_payment_period',
    INSURANCE_SUM = 'select_insurance_sum',
    INSURANCE_FEE = 'select_insurance_fee',
    DIRECT_JUMP_FEE_PAGE = 'directjump_fee_page',
    DIRECT_JUMP_BURIAL_PACKAGE = 'directjump_burial_package',
    DIRECT_JUMP_FLEX_OPTION = 'directjump_flex_option',
    DIRECT_JUMP_DYNAMIC_OPTION = 'directjump_dynamic_option',
    DIRECT_JUMP_REQUEST_OFFER = 'directjump_request_offer',
    DIRECT_JUMP_BANKING_DETAILS_PAGE = 'directjump_banking_details_page',
    DIRECT_JUMP_INSURANCE_START_PAGE = 'directjump_insurance_start_page',
    BREADCRUMB_TARIFF_DATA = 'breadcrumb_tariff_data',
    BREADCRUMB_FEE = 'breadcrumb_fee',
    BREADCRUMB_PERSONAL_INFORMATION = 'breadcrumb_personal_information',
    BROWSER_BACK = 'browser_back',
    BROWSER_NEXT = 'browser_next',
    START = 'start',
    NEXT = 'next',
    BACK = 'back',
    REPEAT_CALL = 'repeat_call',
    OFFER_DIRECT_ONLINE = 'offer_direct_online',
    OFFER_WRITTEN_POSTAL = 'offer_written_postal',
    OFFER_WRITTEN_EMAIL = 'offer_written_email'
}
