import React from 'react';
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps, Form as FormRaw, Formik } from 'formik';
import { getCountrySpecificIbanLength, ibanFormatter, mapDateToDateIsoString, mapToGermanDate, sanitizeWhitespaces } from 'stg-common';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { ScrollToError } from '../components/ScrollToError';
import { scrollToTop } from '../helpers/scrolling';
import { getErrorMessage } from '../helpers/validationHelpers';
import { NavigationAction } from '../routing/StateMachineTypes';
import { validateIban } from '../services/api';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createBankingDetailsSchema } from '../validation/BankingDetailsPage';

const Form = FormRaw as any; // FIXME: @eg typing is a little off between versions, so I'm going to completely ignore.

export interface BankingDetailsPageData extends StoreStateUpdater<BankingDetailsPageData> {
    iban: string;
    bic?: string;
    vorname?: string;
    nachname?: string;
}

interface BankingDetailsPageProps extends PagePropsWithValues<BankingDetailsPageData> {
}

interface BankingDetailsPageState {
    isValidating: boolean;
    bankingDetailsValid: boolean;
    showBic: boolean;
}

class BankingDetailsPage extends React.Component<BankingDetailsPageProps, BankingDetailsPageState> {
    private readonly maxBicLength = 11;

    constructor(props: Readonly<BankingDetailsPageProps>) {
        super(props);
        this.state = {
            isValidating: false,
            bankingDetailsValid: false,
            showBic: this.isInternationalIban(props.storeState.iban)
        };
    }

    public componentDidMount() {
        this.validateWithIbanService(() => undefined, this.props.storeState.iban, this.props.storeState.bic);
        scrollToTop();
    }

    private validateBeforeSubmit(values: BankingDetailsPageData): BankingDetailsPageData {
        return {
            ...this.props.storeState,
            ...createBankingDetailsSchema(this.state.bankingDetailsValid).cast(values)
        } as BankingDetailsPageData;
    }

    public render() {
        return <Formik
            initialValues={{
                ...this.props.storeState,
                iban: ibanFormatter(this.props.storeState.iban)
            }}
            onSubmit={(values: BankingDetailsPageData) => {
                const validatedValues = this.validateBeforeSubmit(values);
                this.props.storeState.update(validatedValues);
                this.props.handleAction(NavigationAction.NEXT);
            }}
            validationSchema={createBankingDetailsSchema(this.state.bankingDetailsValid)}
        >
            {form => (
                <Form>
                    <Headline>
                        Bitte geben Sie Ihre Bankverbindung an:
                    </Headline>
                    {this.renderSepaPaymentInfoText()}
                    {this.renderIbanBic()}
                    {this.renderAdditionalInfo()}
                    <ScrollToError formik={form}/>
                    <Footer
                        showLoadingSpinner={this.state.isValidating}
                        handleAction={this.props.handleAction} onNextClick={() => undefined}
                    />
                    <DisclaimerInfoBox/>
                </Form>
            )}
        </Formik>;
    }

    private renderSepaPaymentInfoText(): JSX.Element {
        return (
            <div id="SepaPaymentInfoText" style={{marginBottom: '3em'}}>
                <p>
                    Für einen Online-Abschluss benötigt der Versicherer ein SEPA-Lastschriftmandat.
                </p>
                <h4>SEPA-Lastschriftmandat</h4>
                <p>
                    Ich ermächtige die <b>ERGO Vorsorge Lebensversicherung AG</b> (Gläubiger-ID DE73DUE00000021741)
                    , Zahlungen von meinem Konto mittels Lastschrift einzuziehen.
                </p>
                <p>
                    Zugleich weise ich mein Kreditinstitut an, diese auf mein Konto
                    gezogenen Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug
                    wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren
                    Fälligkeitstermine angekündigt.
                </p>
                <h4>Hinweis</h4>
                <p>
                    Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die
                    Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit
                    meinem Kreditinstitut vereinbarten Bedingungen.
                </p>
            </div>
        );
    }

    private renderIbanBic() {
        return <>
            {this.renderIBANField()}
            {this.state.showBic && this.renderBICField()}
        </>;
    }

    private renderIBANField(): JSX.Element {
        return <Field
            name="iban"
            data-component-id={'ibanField'}
            render={({field, form}: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                const {setFieldValue, setFieldTouched} = form;
                const touchField = () => setFieldTouched(field.name);

                return (
                    <InputRow
                        id={field.name}
                        name={field.name}
                        error={getErrorMessage(form, field)}
                        value={field.value}
                        onBlur={touchField}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const sanitizedIban = sanitizeWhitespaces(event.target.value);
                            setFieldValue(field.name, event.target.value);
                            const bic = this.toggleBic(sanitizedIban);
                            setFieldValue('bic', bic);
                            this.validateWithIbanService(touchField, sanitizedIban, bic);
                            this.props.storeState.update({iban: sanitizedIban, bic});
                        }}
                        label={'IBAN'}
                        placeholder={'IBAN'}
                        data-component-id={field.name}
                        tooltip={'Ihre IBAN finden Sie auf Ihrer Bankkarte oder auf Ihrem Kontoauszug.'}
                        // tslint:disable-next-line:no-magic-numbers
                        maxLength={43}
                    />);
            }}/>;
    }

    private toggleBic(iban?: string) {
        const showBic = this.isInternationalIban(iban);
        if (this.state.showBic !== showBic) {
            this.setState({showBic});
            return undefined;
        }
        return this.props.storeState.bic;
    }

    private renderBICField(): JSX.Element {
        return <Field
            data-component-id={'bicField'}
            name="bic"
            render={({field, form}: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
                const {setFieldValue, setFieldTouched} = form;
                const touchField = () => setFieldTouched(field.name);

                return (<InputRow
                    id={field.name}
                    name={field.name}
                    error={getErrorMessage(form, field)}
                    value={field.value}
                    onBlur={touchField}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(field.name, event.target.value);
                        this.validateWithIbanService(touchField, this.props.storeState.iban, event.target.value);
                        this.props.storeState.update({bic: event.target.value});
                    }}
                    label={'BIC'}
                    placeholder={'BIC'}
                    data-component-id={field.name}
                    tooltip={'Ihre BIC finden Sie auf Ihrer Bankkarte oder auf Ihrem Kontoauszug.'}
                    maxLength={this.maxBicLength}
                />);
            }}/>;
    }

    private isInternationalIban(iban?: string): boolean {
        // tslint:disable-next-line:no-magic-numbers
        if (iban && iban.length >= 2) {
            return !iban.toUpperCase().startsWith('DE');
        }
        return false;
    }

    private validateWithIbanService(touchField: () => void, iban?: string, bic?: string) {
        this.setState({isValidating: true});
        if (iban && this.isIbanCorrectLength(iban) && (!this.isInternationalIban(iban) || this.isBicCorrectLength(bic))) {
            validateIban(iban, bic)
                .then(result => {
                    this.setBankingValidity(result.isValidIban, touchField, result.bic);
                }).catch(() => this.setBankingValidity(false, touchField));
        } else {
            this.setBankingValidity(false, touchField);
        }
    }

    private isIbanCorrectLength(iban: string) {
        const minimalIbanLength = getCountrySpecificIbanLength(iban);
        return iban.length >= minimalIbanLength;
    }

    private isBicCorrectLength(bic?: string) {
        const minBicLength = 8;
        return bic && (bic.length === minBicLength || bic.length === this.maxBicLength);
    }

    private setBankingValidity(valid: boolean, touchField: () => void, bic?: string) {
        this.setState({
            bankingDetailsValid: valid,
            isValidating: false
        }, touchField);
        if (bic) {
            this.props.storeState.update({bic});
        }
    }

    private renderAdditionalInfo(): JSX.Element {
        return <>
            {this.renderDatumForm()}
            {this.renderKontoForm()}
            {this.renderMandatParagraph()}
        </>;
    }

    private renderDatumForm(): JSX.Element {
        const currentDate = mapToGermanDate(mapDateToDateIsoString(new Date()));
        return (
            <FormRow label="Datum">
                <Input value={currentDate}
                       readOnly={true}
                       disabled={true}
                       data-component-id="ibanDatum"/>
            </FormRow>);
    }

    private renderKontoForm(): JSX.Element {
        return (
            <FormRow label="Kontoinhaber">
                <Input value={`${this.props.storeState.vorname} ${this.props.storeState.nachname}`}
                       readOnly={true}
                       disabled={true}
                       data-component-id="ibanKontoinhaber"/>
            </FormRow>);
    }

    private renderMandatParagraph(): JSX.Element {
        return <p> Sollte bereits ein Mandat für die oben genannte Kontoverbindung
            bestehen, bin ich damit einverstanden, dass das von mir bereits erteilte
            SEPA&#8209;Lastschriftmandat auch für den Einzug der Beiträge für diesen
            Versicherungsvertrag genutzt wird.
        </p>;
    }
}

export default BankingDetailsPage;
