import { Addresses, BurialPackageIdentifier, EntitledPerson, PolicyModel } from './ApiRequests';
import { IsoDateString } from './IsoDateString';
import { Nationality } from './Nationality';
import { ValueRanges } from './ValueRanges';
import { Variante } from './Variante';

export enum ResponseCodes {
    SUCCESS = 200,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    PRECONDITION_FAILED = 412,
    VALIDATION_FAILURE = 422,
    ERROR = 500
}

export interface OrderResponse {
    status: number;
    showAntrag?: boolean;
    showMeldung?: boolean;
}

export interface CreateResponse {
    businessId: string;
    valueRanges: ValueRanges;
    debugLogs?: boolean;
    messages?: Message[];
}

export interface Adresse {
    strasse: string;
    hausnummer: string;
    plz: string;
    ort: string;
    land?: string;
}

export enum CalculationMode {
    INSURANCE_FEE = 'insuranceFee',
    INSURANCE_SUM = 'insuranceSum'
}

export enum OfferState {
    NEW,
    REQUEST,
    FINISH
}

export interface Message {
    code: string;
    fields: string[];
    level: string;
    message: string;
}

export interface AngebotResponse {
    abbuchungsTage?: number[];
    accountThirdParty?: boolean;
    adresse?: Adresse;
    anrede?: Addresses;
    bic?: string;
    birthdate?: IsoDateString;
    burialPackage?: BurialPackageIdentifier;
    businessId: string;
    calculationMode?: CalculationMode;
    confidentialityRelease?: boolean;
    custodian?: boolean;
    divergingInsuredPerson?: boolean;
    email?: string;
    entitledPersons?: EntitledPerson[];
    feeDynamic?: boolean;
    flexOption?: boolean;
    geburtsort?: string;
    iban?: string;
    insuranceBegin?: IsoDateString;
    insuranceFee?: number;
    insuranceSum?: number;
    insuredPersonId?: string;
    isEntitledPersonAlsoInsuredPerson?: boolean;
    messages?: Message[];
    nachname?: string;
    nationalities?: Nationality[];
    offerState?: OfferState;
    paymentMethod?: string;
    paymentPeriod?: number;
    policyHolderId?: string;
    policyModel?: PolicyModel;
    politicallyExposedPerson?: boolean;
    rufnummer?: string;
    shouldDownloadPdf?: boolean;
    staatsangehoerigkeit?: string;
    valueRanges?: ValueRanges;
    varianten: Variante[];
    vorname?: string;
    vorwahl?: string;
    wirtschaftlicheigentverantwortlich?: boolean;
}

export interface IbanServiceResponse {
    isValidIban: boolean;
    iban?: string;
    bic?: string;
}

export interface AgencyResponse {
    adsId: string;
    telephone?: AgencyTelephone;
}

export interface AgencyTelephone {
    id: string;
    laenderkennzahl: string;
    rufnummer: string;
    typ: string;
    vorwahl: string;
}
