import InsuranceDocumentsPage, { InsuranceDocumentsPageData } from '../../pages/InsuranceDocumentsPage';
import { getOfferPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuranceDocumentsPage: StateDefinition<InsuranceDocumentsPageData> = {
    name: StateName.INSURANCE_DOCUMENTS_PAGE,
    validInboundStates: [
        StateName.CONFIDENTIALITY_RELEASE_PAGE,
        StateName.REQUEST_OFFER_DIVERGING_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_INSURED_PERSON_PAGE,
        StateName.REQUEST_OFFER_CHECKOUT_PAGE
    ],
    transitions: [
        {
            test: action => action === NavigationAction.NEXT,
            newState: StateName.FEEDBACK_PAGE
        },
        {
            test: action => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.CONFIDENTIALITY_RELEASE_PAGE
        },
        // BreadCrumbs
        {
            test: action => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: action => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: action => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        {
            test: action => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.INSURED_PERSON_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceDocuments');

        return {
            valueRanges: response.valueRanges,
            userInput: {
                policyModel: response.policyModel,
                messages: response.messages,
                offerState: response.offerState,
                showMeldung: false
            }
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <InsuranceDocumentsPage
            storeState={{
                businessId: inputData.businessId,
                policyModel: inputData.userInput.policyModel,
                showAntragModal: inputData.userInput.showAntragModal,
                offerState: inputData.userInput.offerState,
                messages: inputData.userInput.messages,
                showMeldung: inputData.userInput.showMeldung,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
            onError={onError}
        />;
    }
};
