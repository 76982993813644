import React from 'react';
import Button from '@eg/elements/Button';
import { Card } from '@eg/elements/Card';
import { DateInputValue } from '@eg/elements/DateInput';
import { Input } from '@eg/elements/Input';
import { Modal } from '@eg/elements/Modal';
import { Radio } from '@eg/elements/Radio';
import { RadioGroupRow } from '@eg/elements/RadioGroupRow';
import { FormikProps, FormikValues } from 'formik';
import {
    Addresses,
    Adresse,
    BurialPackage,
    BurialPackageIdentifier,
    EntitledPerson,
    mapToGermanDate,
    MAX_ENTITLED_PERSONS,
    MAX_SIZE_ENTITELMENT_PERCENTAGE,
    Nationality,
    Person
} from 'stg-common';
import { AddressValidationResults } from '../../../common/src/types';
import EditEntitlement from '../components/bezugsrecht/EditEntitlement';
import ViewEntitlement from '../components/bezugsrecht/ViewEntitlement';
import DisclaimerInfoBox from '../components/DisclaimerInfoBox';
import { Footer } from '../components/Footer/Footer';
import { Headline } from '../components/Headline';
import { PersonInformations } from '../components/PersonalData';
import { scrollToErrorByQuery } from '../components/ScrollToError';
import SelectableCard from '../components/SelectableCard';
import { scrollToTop } from '../helpers/scrolling';
import { filterNumberInputField } from '../helpers/validationHelpers';
import { NavigationAction } from '../routing/StateMachineTypes';
import { getPerson, updateEntitledPersonsPageData } from '../services/api';
import { trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

const CUSTOM_ENTITLED_PERSON = 'CUSTOM_ENTITLED_PERSON';
const LEGAL_ENTITLED_PERSON = 'LEGAL_ENTITLED_PERSON';
export const NEW_ENTITLED_PERSON_FORM_REGISTRATION_KEY = 'NEW-ENTITLED-PERSON';

export interface EntitledPersonsPageData extends StoreStateUpdater<EntitledPersonsPageData> {
    burialPackage: BurialPackageIdentifier;
    businessId: string;
    divergingInsuredPerson: boolean;
    entitledPersons: EntitledPerson[];
    insuredPersonId: string;
    isEntitledPersonAlsoInsuredPerson: boolean;
    nationalities?: Nationality[];
    policyHolderId: string;
}

export enum EntitledPersonViewState {
    VIEW = 'VIEW',
    EDIT = 'EDIT'
}

export interface EntitledPersonViewModel extends PersonInformations {
    state: EntitledPersonViewState;
    personId: string;
    birthdateField?: DateInputValue;
    entitlementPercentage: number;
    relationship: string;
    relationshipFreeText: string;
    addressValidation?: AddressValidationResults;
    anrede?: Addresses;
    vorname: string;
    nachname: string;
    birthdate: string;
    adresse: Adresse;
}

interface EntitledPersonsPageProps extends PagePropsWithValues<EntitledPersonsPageData> {
}

interface EntitledPersonsPageState {
    customBezugsrecht: string;
    showRemoveEntitledPersonsModal: boolean;
    deselectEntitledPersons: boolean;
    entitledPersons: EntitledPersonViewModel[];
    newEntitledPerson?: EntitledPersonViewModel;
    registeredForms: Map<string, FormikProps<FormikValues>>;
    showGlobalErrorMessages: boolean;
    policyHolder?: Person;
    insuredPersonAdress?: Adresse;
    intendedActionAfterSaving: NavigationAction | undefined;
    isEntitledPersonAlsoInsuredPerson?: boolean;
}

class EntitledPersonsPage extends React.Component<EntitledPersonsPageProps, EntitledPersonsPageState> {

    constructor(props: Readonly<EntitledPersonsPageProps>) {
        super(props);
        this.state = {
            customBezugsrecht: this.props.storeState.entitledPersons && this.props.storeState.entitledPersons.length > 0
                ? CUSTOM_ENTITLED_PERSON
                : LEGAL_ENTITLED_PERSON,
            showRemoveEntitledPersonsModal: false,
            deselectEntitledPersons: false,
            entitledPersons: this.mapEntitledPersonsToViewModel(this.props.storeState.entitledPersons),
            newEntitledPerson: undefined,
            registeredForms: new Map(),
            showGlobalErrorMessages: false,
            intendedActionAfterSaving: undefined,
            isEntitledPersonAlsoInsuredPerson: this.props.storeState.isEntitledPersonAlsoInsuredPerson
        };
        // tslint:disable-next-line:no-floating-promises
        getPerson(this.props.storeState.businessId, this.props.storeState.policyHolderId).then(person => {
            this.setState({policyHolder: person});
        });
        // tslint:disable-next-line:no-floating-promises
        getPerson(this.props.storeState.businessId, this.props.storeState.insuredPersonId).then(person => {
            this.setState({insuredPersonAdress: person.adresse});
        });
    }

    public componentDidMount() {
        scrollToTop();
    }

    public componentDidUpdate(prevProps: Readonly<EntitledPersonsPageProps>, prevState: Readonly<EntitledPersonsPageState>): void {
        scrollToErrorByQuery(`[role="alert"]`);
        if (this.hasErrorInEntitledPerson() && this.state.intendedActionAfterSaving) {
            this.setState({intendedActionAfterSaving: undefined});
        }
    }

    public render() {
        const burialPackage = this.props.valueRanges.burialPackages.find(
            (el: BurialPackage) => el.identifier === this.props.storeState.burialPackage
        );
        return (<>
            <Headline>Wir benötigen noch Angaben zum Bezugsrecht.<br/>
                Wer soll das Geld aus der Versicherung bekommen?</Headline>
            <div style={{textAlign: 'center'}}>
                Möchten Sie sofort das Bezugsrecht vergeben?
            </div>
            <br/>
            {burialPackage && (this.props.storeState.burialPackage !== BurialPackageIdentifier.KEIN) && <>

                <RadioGroupRow label="" name="bezugsrechtFake" value={'selected'} defaultValue={'selected'}>
                    <Radio key={'nop'} value={'nop'} disabled={true} data-component-id="heirs-fake-radio"
                           label="Nein, meine Erben erhalten die Summe"/>
                    <Radio key={'nop2'} value={'nop2'} data-component-id="choose-heirs-by-yourself-fake-radio" disabled={true}
                           label="Ja, ich möchte eine oder mehrere Bezugsberechtigte bestimmen"/>
                    <Radio key={'selected'} value={'selected'} data-component-id="anternia-radio" checked={true}
                           label="Bestattungs-Organisator Anternia GmbH"/>
                </RadioGroupRow>

                <Card label="Bestattungsunternehmen">
                    <div className="esc_col esc_col-12 esc_col-m-12" data-component-id="bestattungsunternehmen-text">
                        Sie haben ein Bestattungspaket gewählt. Damit die gebuchten Leistungen geregelt werden, wird
                        das Bezugsrecht automatisch auf unseren Partner Anternia GmbH übertragen. In diesem Fall kann
                        die Regelung nur mit Zustimmung von Anternia geändert werden. Der Differenzbetrag wird an die
                        hinterbliebenen Begünstigten ausgezahlt.
                    </div>
                </Card>

                <br/>
                <Footer handleAction={this.props.handleAction} onNextClick={() => {
                    this.setState({showGlobalErrorMessages: true});
                    this.props.handleAction(NavigationAction.NEXT);
                }} nextButtonText={'weiter zur Bankverbindung'}/>
                <DisclaimerInfoBox/>
            </>}
            {(!burialPackage || (this.props.storeState.burialPackage === BurialPackageIdentifier.KEIN)) && <>
                <RadioGroupRow label="" name="bezugsrecht" value={this.state.customBezugsrecht}
                               defaultValue={this.state.customBezugsrecht}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   trackElementClickImmediate(TrackingElement.Checkbox_BezugsrechtAendern);
                                   if (event.target.value === CUSTOM_ENTITLED_PERSON && this.state.customBezugsrecht !== CUSTOM_ENTITLED_PERSON) {
                                       this.setState({
                                           customBezugsrecht: event.target.value,
                                           newEntitledPerson: this.state.entitledPersons && this.state.entitledPersons.length === 0
                                               ? this.createEmptyEntitledPerson()
                                               : undefined
                                       });
                                   }
                                   if (event.target.value === LEGAL_ENTITLED_PERSON && this.state.customBezugsrecht !== LEGAL_ENTITLED_PERSON) {
                                       this.setState({
                                           showRemoveEntitledPersonsModal: true
                                       });
                                   }
                               }}>
                    <Radio value={LEGAL_ENTITLED_PERSON} label="Nein, meine Erben erhalten die Summe"
                           data-component-id="heirs-radio"/>
                    <Radio value={CUSTOM_ENTITLED_PERSON} label="Ja, ich möchte eine oder mehrere Bezugsberechtigte bestimmen"
                           data-component-id="choose-by-yourself-radio"/>
                </RadioGroupRow>
                <Modal
                    open={this.state.showRemoveEntitledPersonsModal}
                    dismissible={true}
                    onDismiss={() => this.setState({showRemoveEntitledPersonsModal: false})}
                >
                    Wenn Sie das Bezugsrecht auf die gesetzliche Erbfolge umstellen, werden alle bereits erfassten
                    Bezugspersonen gelöscht.
                    Sind Sie sicher, dass Sie die Bezugsrechte verwerfen wollen?
                    <br/>
                    <br/>
                    <Button variant="text-link" size="large" data-component-id="dismiss-delete-entitled-persons" onClick={() => {
                        this.setState({showRemoveEntitledPersonsModal: false});
                    }}> Abbrechen </Button>
                    <Button variant="primary" loading={this.state.deselectEntitledPersons} style={{float: 'right'}}
                            data-component-id="confirm-delete-entitled-persons"
                            onClick={async () => {
                                this.setState({
                                    deselectEntitledPersons: true
                                });
                                try {
                                    const response = await updateEntitledPersonsPageData(this.props.storeState.businessId, {entitledPersons: []});
                                    this.setState({
                                        entitledPersons: [],
                                        deselectEntitledPersons: false,
                                        showGlobalErrorMessages: false,
                                        showRemoveEntitledPersonsModal: false,
                                        customBezugsrecht: LEGAL_ENTITLED_PERSON,
                                        newEntitledPerson: undefined
                                    });
                                    this.props.storeState.update({
                                        entitledPersons: [],
                                        messages: response.messages
                                    });
                                } catch (e) {
                                    this.setState({
                                        deselectEntitledPersons: false
                                    });
                                }
                            }}
                            size="large">
                        Ja
                    </Button>
                </Modal>
                <br/>
                {this.state.customBezugsrecht === CUSTOM_ENTITLED_PERSON && this.renderPersonEntitlement()}
                <br/>
                {!this.isAllEntitlementPageDataValid() && this.state.showGlobalErrorMessages &&
                <div style={{color: '#e80c26', fontSize: '14px', textAlign: 'center'}}>
                    {this.getErrorMessages().map(element =>
                        <div>{element}</div>
                    )}
                </div>
                }

                <br/>
                <Footer
                    handleAction={this.props.handleAction}
                    hideNextButton={this.hasEntitledPersonInEditState()}
                    nextButtonText={'weiter zur Bankverbindung'}
                    onNextClick={() => {
                        this.setState({showGlobalErrorMessages: true});
                        if (this.isAllEntitlementPageDataValid()) {
                            this.props.handleAction(NavigationAction.NEXT);
                        } else {
                            if (this.hasEntitledPersonInEditState()) {
                                this.setState({intendedActionAfterSaving: NavigationAction.NEXT});
                                this.submitRegisteredForms();
                            }
                        }
                    }}
                    showLoadingSpinner={!!this.state.intendedActionAfterSaving}
                />
                <DisclaimerInfoBox/>
            </>}
        </>);
    }

    public registerEntitlementEditForm = (pdsId: string, formikForm: FormikProps<FormikValues>) => {
        this.state.registeredForms.set(pdsId, formikForm);
    };

    public submitFinishedCallback = () => {
        this.setState({newEntitledPerson: undefined});

        if (this.state.intendedActionAfterSaving && this.isAllEntitlementPageDataValid()) {
            this.props.handleAction(this.state.intendedActionAfterSaving);
        } else {
            this.setState({
                showGlobalErrorMessages: true,
                intendedActionAfterSaving: undefined
            });
        }
    };

    private submitRegisteredForms(): void {
        this.state.registeredForms.forEach(element => {
            element.submitForm();
        });
    }

    private isAllEntitlementPageDataValid(): boolean {
        if (this.state.customBezugsrecht === LEGAL_ENTITLED_PERSON) {
            return true;
        }
        return this.getErrorMessages().length === 0;
    }

    private getErrorMessages(): string[] {
        const errorMessages: string[] = [];
        if (this.state.isEntitledPersonAlsoInsuredPerson) {
            errorMessages.push('Die versicherte Person darf nicht als bezugsberechtigte Person angegeben werden.');
        }
        if (this.state.customBezugsrecht === CUSTOM_ENTITLED_PERSON && this.state.entitledPersons.length === 0) {
            errorMessages.push('Bitte speichern Sie mindestens eine bezugsberechtigte Person.');
        }
        if (this.state.customBezugsrecht === CUSTOM_ENTITLED_PERSON && this.state.entitledPersons.length > MAX_ENTITLED_PERSONS) {
            errorMessages.push(`Es dürfen maximal ${MAX_ENTITLED_PERSONS} bezugsberechtigte Personen angegeben werden.`);
        }
        if (this.state.customBezugsrecht === CUSTOM_ENTITLED_PERSON && this.calculateCurrentPercentageSum() !== MAX_SIZE_ENTITELMENT_PERCENTAGE) {
            errorMessages.push('Die Summe der vergebenen Bezugsrechte entspricht nicht 100%.');
        }
        if (this.state.customBezugsrecht
            && this.hasEntitledPersonInEditState()) {
            errorMessages.push('Bitte speichern Sie die aktuell bearbeitete bezugsberechtigte Person.');
        }
        if (this.state.entitledPersons.find(entitledPerson => {
            return !!(entitledPerson.addressValidation && entitledPerson.addressValidation.isInvalid);
        })) {
            errorMessages.push('Bitte korrigieren Sie die Adresse der zuletzt bearbeiteten, bezugsberechtigten Person.');
        }
        return errorMessages;
    }

    private hasEntitledPersonInEditState(): boolean {
        if (this.isNewEntiteledPersonInEditState()) {
            return true;
        }
        return !!this.state.entitledPersons.find(entitledPerson => entitledPerson.state === EntitledPersonViewState.EDIT);
    }

    private isNewEntiteledPersonInEditState(): boolean {
        const neuePerson = this.state.newEntitledPerson;
        return !!(neuePerson && neuePerson.state === EntitledPersonViewState.EDIT);
    }

    private calculateCurrentPercentageSum() {
        return this.state.entitledPersons.reduce((sum: number, b: EntitledPersonViewModel) => {
            return Number(sum + b.entitlementPercentage);
        }, 0);
    }

    private renderPersonEntitlement() {
        return <div>
            <h3 style={{textAlign: 'center'}}>Bitte geben Sie bis zu max. 3 Personen an:</h3>
            <br/>
            {this.state.entitledPersons.map((entitledPerson, index) => {
                if (entitledPerson.state === EntitledPersonViewState.VIEW) {
                    return (<ViewEntitlement key={entitledPerson.personId}
                                             headline={`${index + 1}. Person`}
                                             entitledPerson={entitledPerson}
                                             nationalities={this.props.storeState.nationalities}
                                             isEditable={this.isOtherPersonInEditState(entitledPerson.personId)}
                                             deleteCallback={async () => {
                                                 await this.deleteEntitledPersonByIndex(index);
                                                 // this.props.tracker.track({values: {NavigationsElement: NavigationElement.Bezugsrecht_Person_Loeschen}});
                                             }}
                                             editCallback={async () => {
                                                 await this.editEntitledPersonByIndex(index);
                                                 // this.props.tracker.track({values: {NavigationsElement: NavigationElement.Bezugsrecht_Person_Bearbeiten}});
                                             }}
                    />);
                } else {
                    return (
                        <EditEntitlement
                            headline={`${index + 1}. Person`}
                            key={entitledPerson.personId}
                            entitledPerson={entitledPerson}
                            addressFromInsuredPerson={this.state.insuredPersonAdress}
                            valueRanges={this.props.valueRanges}
                            updateCallback={(updatedEntitledPerson: Partial<EntitledPersonViewModel>) => {
                                const entitledPersons = [...this.state.entitledPersons];
                                entitledPersons[index] = {
                                    ...entitledPersons[index],
                                    ...updatedEntitledPerson
                                };
                                this.setState({
                                    entitledPersons
                                });
                            }}
                            saveCallback={async changedEntitledPerson => {
                                const entitledPersons = [...this.state.entitledPersons];
                                entitledPersons[index] = changedEntitledPerson;
                                await this.saveEntitledPersonsAndUpdateState(entitledPersons);
                                if (!this.state.isEntitledPersonAlsoInsuredPerson) {
                                    changedEntitledPerson.state = EntitledPersonViewState.VIEW;
                                    const registeredForms = this.state.registeredForms;
                                    registeredForms.delete(changedEntitledPerson.personId);
                                    this.setState({
                                        newEntitledPerson: undefined,
                                        registeredForms
                                    });
                                }
                                // this.props.tracker.track({values: {NavigationsElement: NavigationElement.Bezugsrecht_Person_Bearbeiten_Speichern}});
                            }}
                            showCancelButton={this.state.entitledPersons.length > 0}
                            cancelCallback={() => {
                                const entitledPersons = [...this.state.entitledPersons];
                                entitledPersons[index].state = EntitledPersonViewState.VIEW;
                                const registeredForms = this.state.registeredForms;
                                registeredForms.delete(entitledPerson.personId);
                                this.setState({
                                    entitledPersons,
                                    registeredForms
                                });
                                // this.props.tracker.track({values: {NavigationsElement: NavigationElement.Bezugsrecht_Person_Bearbeiten_Abbrechen}});
                            }}
                            submitFinishedCallback={this.submitFinishedCallback}
                            registerEntitlementFormCallback={this.registerEntitlementEditForm}
                            showUsePolicyHolderData={this.props.storeState.divergingInsuredPerson && this.state.entitledPersons.length === 0}
                        />
                    );
                }
            })}
            {this.state.newEntitledPerson && (
                <EditEntitlement
                    headline={`${this.state.entitledPersons.length + 1}. Person`}
                    entitledPerson={this.state.newEntitledPerson}
                    policyHolderPerson={this.state.policyHolder}
                    addressFromInsuredPerson={this.state.insuredPersonAdress}
                    valueRanges={this.props.valueRanges}
                    updateCallback={(newEntitledPersonFromForm: Partial<EntitledPersonViewModel>) => {
                        this.setState({
                            newEntitledPerson: {
                                ...this.state.newEntitledPerson!,
                                ...newEntitledPersonFromForm
                            }
                        });
                    }}
                    saveCallback={async newEntitledPersonFromForm => {
                        newEntitledPersonFromForm.state = EntitledPersonViewState.VIEW;
                        const entitledPersons = [...this.state.entitledPersons];
                        entitledPersons.push(newEntitledPersonFromForm);
                        await this.saveEntitledPersonsAndUpdateState(entitledPersons);
                        if (!this.state.isEntitledPersonAlsoInsuredPerson) {
                            const registeredForms = this.state.registeredForms;
                            registeredForms.delete(NEW_ENTITLED_PERSON_FORM_REGISTRATION_KEY);
                            this.setState({
                                registeredForms
                            });
                        }
                    }}
                    showCancelButton={this.state.entitledPersons.length > 0}
                    cancelCallback={() => {
                        const registeredForms = this.state.registeredForms;
                        registeredForms.delete(NEW_ENTITLED_PERSON_FORM_REGISTRATION_KEY);
                        this.setState({
                            newEntitledPerson: undefined,
                            registeredForms
                        });
                    }}
                    submitFinishedCallback={this.submitFinishedCallback}
                    registerEntitlementFormCallback={this.registerEntitlementEditForm}
                    showUsePolicyHolderData={this.props.storeState.divergingInsuredPerson && this.state.entitledPersons.length === 0}
                />
            )}
            {(this.state.entitledPersons.length < MAX_ENTITLED_PERSONS
                && !this.hasEntitledPersonInEditState())
            && <SelectableCard
                dataComponentId="add-bezugberechtigte-person-button"
                onClick={() => {
                    trackElementClickImmediate(TrackingElement.Button_BezugNeuePersonAnlegen);
                    if (!this.hasEntitledPersonInEditState()) {
                        this.setState({
                            newEntitledPerson: this.createEmptyEntitledPerson(),
                            showGlobalErrorMessages: false
                        });
                    }
                    // this.props.tracker.track({values: {NavigationsElement: NavigationElement.Bezugsrecht_Neue_Person_Anlegen}});
                }}
                heading={'Neue bezugsberechtigte Person'}
                nextText={'hinzufügen'}
            >
            </SelectableCard>
            }
            {this.state.entitledPersons.length > 0 && <>
                <h3 style={{textAlign: 'center', margin: '4em 0 2.25em 0'}}>Wie hoch soll der Anteil für die einzelnen Personen sein?</h3>
                <div style={{textAlign: 'center', margin: '1em 0'}}>
                    Sie können das Bezugsrecht prozentual auf alle angegebenen Bezugspersonen individuell verteilen.
                    Die Summe der prozentualen Bezugsrechte muss allerdings 100% ergeben.
                </div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        {this.state.entitledPersons.map((entitledPerson, index) => {
                            const NUMBER_OF_COLUMNS = 12;
                            const inputMaxLenth = 3;
                            const inputSize = 3;
                            return <div className={`esc_col esc_col-12 esc_col-m-${NUMBER_OF_COLUMNS / this.state.entitledPersons.length}`}
                                        style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '10px'}}
                                        key={`entitled-person-${index}`}>
                                <div><b>{entitledPerson.vorname} {entitledPerson.nachname}</b></div>
                                <div style={{color: '#737373'}}><small>{mapToGermanDate(entitledPerson.birthdate)}</small></div>
                                <div>
                                    <Input defaultValue={String(entitledPerson.entitlementPercentage)}
                                           disabled={this.state.entitledPersons.length === 1}
                                           adornmentRight="%"
                                           id={`percentageInput${index}`}
                                           type="number"
                                           error={this.calculateCurrentPercentageSum() !== MAX_SIZE_ENTITELMENT_PERCENTAGE}
                                           maxLength={inputMaxLenth}
                                           size={inputSize}
                                           step={1}
                                           min={0}
                                           placeholder="0"
                                           onKeyDown={filterNumberInputField}
                                           value={entitledPerson.entitlementPercentage ? entitledPerson.entitlementPercentage : ''}
                                           max={MAX_SIZE_ENTITELMENT_PERCENTAGE}
                                           onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                               entitledPerson.entitlementPercentage = this.correctInputToMatchPercentageRange(Number(event.target.value));
                                               const entitledPersons = this.state.entitledPersons;
                                               entitledPersons[index] = entitledPerson;
                                               const entitledPersonsToUpdate: EntitledPerson[] = this.mapModelToEntitledPersons(entitledPersons);
                                               this.setState({
                                                   entitledPersons,
                                                   showGlobalErrorMessages: true
                                               });
                                               this.props.storeState.update({
                                                   entitledPersons: entitledPersonsToUpdate
                                               });
                                           }}
                                           style={{width: '100px', margin: '10px auto'}}/>
                                </div>
                            </div>;
                        })}
                    </div>
                </div>
            </>}
        </div>;
    }

    private correctInputToMatchPercentageRange(percent: number): number {
        if (percent < 0) {
            return 0;
        }
        if (percent > MAX_SIZE_ENTITELMENT_PERCENTAGE) {
            return MAX_SIZE_ENTITELMENT_PERCENTAGE;
        }
        return percent;
    }

    private editEntitledPersonByIndex(index: number): Promise<void> {
        if (!this.hasEntitledPersonInEditState()) {
            const entitledPersons = [...this.state.entitledPersons];
            entitledPersons[index].state = EntitledPersonViewState.EDIT;
            this.setState({
                showGlobalErrorMessages: false,
                entitledPersons
            });
        }
        scrollToErrorByQuery(`[data-component-id="edit-entitlement-person"]`);
        return Promise.resolve();
    }

    private async deleteEntitledPersonByIndex(index: number): Promise<void> {
        const entitledPersons = [...this.state.entitledPersons];
        entitledPersons.splice(index, 1);

        await this.saveEntitledPersonsAndUpdateState(entitledPersons);
        if (!this.state.isEntitledPersonAlsoInsuredPerson) {
            if (!this.state.newEntitledPerson && entitledPersons.length === 0) {
                this.setState({
                    showGlobalErrorMessages: false,
                    newEntitledPerson: this.createEmptyEntitledPerson()
                });
            } else {
                this.setState({showGlobalErrorMessages: false});
            }
        }
    }

    private isOtherPersonInEditState(personId: string): boolean {
        if (this.isNewEntiteledPersonInEditState()) {
            return false;
        }
        return !this.state.entitledPersons.find(person =>
            personId !== person.personId && person.state === EntitledPersonViewState.EDIT
        );
    }

    private async saveEntitledPersonsAndUpdateState(entitledPersonList: EntitledPersonViewModel[]) {
        const entitledPersons: EntitledPerson[] = this.mapModelToEntitledPersons(entitledPersonList);
        const entitledSavedPersons = await updateEntitledPersonsPageData(this.props.storeState.businessId, {
            ...this.props.storeState,
            entitledPersons
        });
        if (entitledSavedPersons && entitledSavedPersons.entitledPersons) {
            this.setState({
                isEntitledPersonAlsoInsuredPerson: !!entitledSavedPersons.isEntitledPersonAlsoInsuredPerson,
                showGlobalErrorMessages: !!entitledSavedPersons.isEntitledPersonAlsoInsuredPerson,
                entitledPersons: this.mapEntitledPersonsToViewModel(entitledSavedPersons.entitledPersons)
            });
            this.props.storeState.update({
                entitledPersons: entitledSavedPersons.entitledPersons,
                messages: entitledSavedPersons.messages
            });
        }
    }

    private hasErrorInEntitledPerson() {
        return !!document.querySelector('[role="alert"]');
    }

    private mapModelToEntitledPersons(entitledPersonList: EntitledPersonViewModel[]) {
        if (entitledPersonList.length === 0) {
            return [];
        }
        return entitledPersonList.map((entitledPersonModel: EntitledPersonViewModel) => {
            const percentage = entitledPersonList.length === 1 ? MAX_SIZE_ENTITELMENT_PERCENTAGE : entitledPersonModel.entitlementPercentage;
            return {
                person: {
                    personId: entitledPersonModel.personId,
                    anrede: entitledPersonModel.anrede,
                    vorname: entitledPersonModel.vorname,
                    nachname: entitledPersonModel.nachname,
                    birthdate: entitledPersonModel.birthdate,
                    adresse: entitledPersonModel.adresse ?
                        {
                            strasse: entitledPersonModel.adresse.strasse,
                            hausnummer: entitledPersonModel.adresse.hausnummer,
                            plz: String(entitledPersonModel.adresse.plz),
                            ort: entitledPersonModel.adresse.ort,
                            land: entitledPersonModel.adresse.land
                        } :
                        undefined
                },
                entitlementPercentage: percentage,
                relationship: entitledPersonModel.relationship,
                relationshipFreeText: entitledPersonModel.relationshipFreeText
            } as EntitledPerson;
        });
    }

    private mapEntitledPersonsToViewModel(entitledPersons: EntitledPerson[]) {
        return entitledPersons.map(entitledPerson => {
            return {
                state: EntitledPersonViewState.VIEW,
                personId: entitledPerson.person.personId,
                anrede: entitledPerson.person.anrede,
                vorname: entitledPerson.person.vorname,
                nachname: entitledPerson.person.nachname,
                birthdate: entitledPerson.person.birthdate,
                adresse: {
                    strasse: entitledPerson.person.adresse.strasse,
                    hausnummer: entitledPerson.person.adresse.hausnummer,
                    plz: entitledPerson.person.adresse.plz,
                    ort: entitledPerson.person.adresse.ort,
                    land: entitledPerson.person.adresse.land
                },
                nationalities: [],
                entitlementPercentage: entitledPerson.entitlementPercentage,
                relationship: entitledPerson.relationship,
                relationshipFreeText: entitledPerson.relationshipFreeText,
                addressValidation: entitledPerson.addressValidation
            } as EntitledPersonViewModel;
        });
    }

    private createEmptyEntitledPerson(): EntitledPersonViewModel {
        return {
            state: EntitledPersonViewState.EDIT,
            personId: '',
            anrede: undefined,
            vorname: '',
            nachname: '',
            birthdate: '',
            adresse: {
                strasse: '',
                hausnummer: '',
                plz: '',
                ort: '',
                land: 'Deutschland'
            },
            entitlementPercentage: 0,
            relationship: '',
            relationshipFreeText: '',
            nationalities: []
        };
    }
}

export default EntitledPersonsPage;
